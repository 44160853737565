import React, { useState } from 'react';
import flag1 from '../images/select.png';
import flag2 from '../images/product-launch.png';
import flag3 from '../images/update.png';
import arrow01 from '../images/arrow01.png';




const OSfeatures = () => {

    const [list] = useState([
        { title: 'Select a service type ', images: flag1, arrow: arrow01, desc: 'It is a long established fact that a freader will be distracted by the.' },
        { title: 'Deploy in seconds', images: flag2, arrow: arrow01, desc: 'It is a long established fact that a freader will be distracted by the.' },
        { title: 'Update automatically', images: flag3, arrow: '', desc: 'It is a long established fact that a freader will be distracted by the.' }
    ]);

    return (
        <section className="iq-fancy-box-section" style={{marginBottom:'20px', marginTop:'50px'}}>
            <img src="../images/arrow01.png" className="img-fluid shape-left" alt="Arrow" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center iq-title-box iq-title-default iq-title-box-2 wow fadeInUp" data-wow-duration="0.6s">
                        
                        

<div
  style={{
    backgroundImage: 'linear-gradient(to right, #2b77e8 0%, #2b77e8 50%, white 50%, white 80%, #2b77e8 80%, #2b77e8 100%)',
    height: '3px',
    width: '60px',
    margin: 'auto',
    // marginBottom: '18px',
    borderRadius:"50px",
    top:'0px'
  }}
></div>



                            <h2 className="iq-title" style={{marginTop:"15px"}}>Instant Deploys For All Your Apps</h2>
                            <br/>
                            <span className="iq-subtitle">How It's Work</span>
                            <br/>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    {list.map((item, index) => (
                        <div className={index < list.length - 1 ? 'col-lg-4 mb-lg-0 mb-5' : 'col-lg-4'} key={index}>
                            <div className="iq-process iq-process-step-style-6 text-center wow fadeInUp" data-wow-duration="0.6s">
                                <div className="iq-process-step">
                                    {item.arrow && item.arrow !== '' ? (
                                        <img className="iq-before-img img-fluid" src={item.arrow} alt="arrow-img" />
                                    ) : (
                                        <div></div>
                                    )}
                                    <div className="iq-step-content">
                                        <img className="hover-img img-fluid" width='50' src={item.images} alt="fancybox" />
                                    </div>
                                    <div className="iq-step-text-area">
                                        <h4 className="iq-step-title mt-3 mb-3">{item.title}</h4>
                                        <span className="iq-step-desc">{item.desc}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <br/>
            <br/>
            <br/>
        </section>
     
    );
    
                                    
}

export default OSfeatures;
