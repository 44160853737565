import React, { useContext } from 'react';
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import AnimatedCardGrid from '../components/customComponents/animatedGrid';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import dataimage from '../images/icons/data_4529189.png';
import dataimage2 from '../images/icons/automation_12122355.png';
import banner from '../images/BANNERS/AiREmasteredImage.png'
const DataAI = () => {
    const context = useContext(DataContext);
    const {footer,VMservice } = context.allData
  
    const { handleSearch, handleOffcanvas } = context;
    const services = [
      {
        title: "Data Modernization Solutions",
        image: dataimage,
        description: "Experience seamless migration of legacy systems to a cloud-based architecture with our Data Modernization Solutions. Unlock unparalleled agility and scalability while staying ahead in the digital landscape with our future-proof data ecosystem tailored to meet your business's evolving demands."
      },
      {
        title: "AI Integration Services",
        image: dataimage2,
        description: "Incorporate AI into your business seamlessly with our AI Integration Services. From automating everyday tasks to enabling rapid, prescriptive decision-making, we help you operationalize AI to reliably deliver efficiency, innovation, and agility."
      }
      ];
      
  return (
   <>
  
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="false"  />
 
  
  <BannerSection
        title="Data & AI"
        backgroundColor="#007bff"
        color="#fff"
        height="50vh"
       backgroundImage={banner}
      />
      

<SliderHeroSection backgroundImage='#efefef'  title="Automation & AI" subtitle="Simplify your IT operations" 
  textColor='#242424' isDefault={true}  featureTitle="Trivont Cloud: Your Gateway to Tomorrow's Technology"
  featureSubtitle="Step into the future confidently with Trivont Cloud by your side. No matter where you are in your journey, whether you're collecting extensive data or developing advanced AI models, we're here to support you. Our pioneering solutions enable you to unleash the power of data and AI, driving faster, predictive, and proactive decision-making processes" > 
  </SliderHeroSection>
 
  <MigrationJumbotron title="Experience business success with Trivont Solutions"
  description="Contact us today to discuss your project requirements and get started on building custom software solutions tailored to your business needs." />  
       

   <IconBox items={services}/> 

    



    <Footer data={footer} />
  
   </>
  )
}

export default DataAI