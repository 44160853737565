import React from "react";
import Loading from "./Loading";
import Title from "./Title";
import { Container, Row, Col } from "react-bootstrap";

function MDB01({ data, classText, styleTwo, styleThree, blockHeight }) {
  let publicUrl = process.env.PUBLIC_URL + "/";
  if (!data) {
    return <Loading />;
  }

  return (
    <section className={`${classText} service`}>
      <Container>
        <Row>
          <Col>
            <Title
              subtitle={data.desc}
              title={data.title}
              classText={`${
                styleTwo
                  ? "title-shape title-shape-sky-blue text-center"
                  : styleThree
                  ? "title-shape title-shape-c2 title-shape-style-three text-center"
                  : "title-shape text-center"
              }`}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          {data.items.map((item, index) => {
            let active = index === 1 ? "active" : "";
            return (
              <div key={index} className="col-lg-4 col-md-6">
                <div
                  className={`single-service${
                    styleTwo
                      ? " style--two"
                      : styleThree
                      ? ` style--three ${active}`
                      : ""
                  }`}
                  style={{
                    transition: "transform 0.3s ease-in-out",
                    cursor: "pointer",
                    color: "inherit",
                    border: "1px solid #ddd", // Thin outline
                    borderRadius: "5px", // Optional: Add rounded corners
                    height:blockHeight,
                    padding:'35px'
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "translateY(-15px)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                  }}
                >
                  <div className="service-icon">
                    <img width="50"
                      src={publicUrl + item.img.url}
                      alt={publicUrl + item.img.alt}
                    />
                  </div>
                  <div>
                    <h3>{item.title}</h3>
                    <br />
                    <p>{item.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default MDB01;

MDB01.defaultProps = {
  classText: "pt-120 pb-90",
};
