import React from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import Title from '../Title';
const FAQList = ({ faqData }) => {
  return (
    
    <section style={{marginTop:'50px', marginBottom:'20px'}}>
      
    <Title title="FAQ"/>
    <Container style={{marginBottom:'100px'}}>
      <Accordion defaultActiveKey="0">
        {faqData.map((item, index) => (
          <Card key={index}>
            <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
              {item.title}{' '}
              <span className="accordion-icon">
                <IoIosArrowDown/>
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body>{item.desc}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </Container>
    </section>
    
  );
};

export default FAQList;
