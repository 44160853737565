import React, {useState,useEffect} from 'react';
import './customcomponent.css'
import svg from './CIRCLE.svg';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
const Section2  = ({header,subtitle,content, cImage}) => {
  return (
    <Jumbotron className='customSection2__jumbotron'>
     
        <Row style={{alignItems:'center'}} noGutters className="w-100">
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center text-left'>
            <h1 className='customSection2__h1'>{header}</h1>
            <h2 className='customSection2__h2'>{subtitle}</h2>
            <p className='customSection2__p'>{content}</p>
            <button className='customSection2__button'>Get Started</button>
          </Col>
          <Col style={{
            backgroundImage:'url(./CIRCLE.svg)'
          }}  xs={12} md={6} className='d-flex  justify-content-center align-items-center text-left'>
              
              <div style={{ position: 'relative', width: '100%', height: '100%', marginTop:'100px', marginLeft:'70px' }}>
          <img
            src={svg}
            alt="Your Image"
            fluid
            className=" img-fluid customSection2__image"/>
       
      
          <img
            src={cImage}
            alt="Your Additional Image"
            width='40%'  
            className='customSection3__image'
            style={{ position: 'absolute', top: '53%', left: '36%', transform: 'translate(-50%, -50%)'}}
            />
          </div>
          </Col>

         



        </Row>
      
    </Jumbotron>
  )
}

export default Section2 