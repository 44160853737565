import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import IconBox from '../components/iconbox';

import BannerSection from '../components/customComponents/bannerSection';
import backgrounBanner from '../images/BANNERS/emailServices.png';
import serviceboxOne from '../images/icons/email_15774672.png';
import serviceboxTwo from '../images/icons/list_318898.png';
import serviceboxThree from '../images/icons/email_8516925.png';
import serviceboxFour from '../images/icons/voice.png';
import serviceboxFive from '../images/icons/big.png';
import serviceboxSix from '../images/icons/security.png';
import serviceboxSeven from '../images/icons/secure.png';
import ResponsiveTable from '../components/customComponents/table';
import Price from '../components/Price';
const EmailService = () => {
    const context = useContext(DataContext);
    const {footer, } = context.allData
  
    const { handleSearch, handleOffcanvas } = context;
    
    const services = [
      {
        title: "SMTP Abuse Report Information",
        subtitle: "Monitor and Control Email Complaints",
        image:serviceboxOne,
        description: "Major providers give us access to their abuse reports, enabling us to identify who is complaining about your emails and provide actionable solutions. This protects your domain and IP reputation around the clock. We manage your abuse chain forensically for complete control."
      },
      {
        title: "Blacklist De-listing Automation",
        subtitle: "Automated Blacklist Removal",
        image:serviceboxTwo,
        description: "We automatically request delisting from all major and minor email and IP blacklists. Our extensive list of blacklist providers ensures 99% coverage of the Internet’s blacklists. Every minor list is taken seriously, achieving a near 100% de-listing success rate."
      },
      {
        title: "High Threat Email Blacklist Monitoring",
        image:serviceboxThree,
        subtitle: "Real-time Threat Monitoring",
        description: "We monitor high-threat IP and domain blacklists daily with an active dashboard. This allows us to provide real-time insights into new threats affecting your email deliverability and actionable steps to mitigate them."
      },
      {
        title: "High Threat Domain Blocking",
        image:serviceboxSix,
        subtitle: "Protect Your Domain Reputation",
        description: "We maintain an exclusive list of high-threat domains that require specific whitelisting to send emails. We secure exclusive mailing rights to protected domains where possible, and block others to prevent damage to your domain and IP reputation."
      },
      {
        title: "Voice Support Technical",
        image:serviceboxFour,
        subtitle: "End-to-End Technical Support",
        description: "We offer comprehensive integration and campaign setup support, so you can focus on your email campaigns without worrying about technical issues."
      },
      {
        title: "Enterprise Grade Privacy",
        image:serviceboxSix,
        subtitle: "Absolute Data Privacy",
        description: "Our privacy installations ensure your business operates within a dedicated server free from compromise. We guarantee not to read or monitor your mail or data, ensuring absolute privacy as part of the Trivont agreement."
      },
      {
        title: "Enterprise Server Security Hardening",
        image:serviceboxSeven,
        subtitle: "Unmatched Server Security",
        description: "Our Certified Infrastructure Engineers provide top-tier security for each server. With bank-level encryption, no root access, and two-factor authentication, our segregated security ensures no breach affects other customers."
      },
      {
        title: "Single High Volume Inboxes",
        image:serviceboxFive,
        subtitle: "Reliable High Volume Emailing",
        description: "Trivont supports high volume senders with single email addresses capable of reaching customers at scale. Whether for banks or medical communications, we ensure 99% deliverability from strong inbox addresses like team@ or system@."
      }
    ];
    
      
    
    const data = [
      {
        "feature": "Dedicated IP",
        "Trivont": "Included",
        "Mailgun": "Absent",
        "TwilioSendGrid": "Absent",
        "AWS": "Absent"
      },
      {
        "feature": "IP Blacklist reputation recoverable?",
        "Trivont": "Included",
        "Mailgun": "Won't assist",
        "TwilioSendGrid": "No assistance provided",
        "AWS": "Out of your control"
      },
      {
        "feature": "Marketing emails allowed?",
        "Trivont": "No restrictions",
        "Mailgun": "Arbitrarily prohibited",
        "TwilioSendGrid": "Subject to arbitrary bans",
        "AWS": "Subject to arbitrary bans"
      },
      {
        "feature": "Domain and IP Reputation Warmup and Maintenance included?",
        "Trivont": "Provided",
        "Mailgun": "Not provided",
        "TwilioSendGrid": "Not provided",
        "AWS": "Not provided"
      },
      {
        "feature": "Sending Privacy and Security?",
        "Trivont": "Guaranteed absolute privacy",
        "Mailgun": "Scans your mail",
        "TwilioSendGrid": "Scans your mail",
        "AWS": "Scans your mail"
      },
      {
        "feature": "Copy Spam Danger Report?",
        "Trivont": "Includes this service",
        "Mailgun": "Doesn't include this service",
        "TwilioSendGrid": "Doesn't include this service",
        "AWS": "Doesn't include this service"
      },
      {
        "feature": "Support with integrations?",
        "Trivont": "Offers support",
        "Mailgun": "Doesn't offer support",
        "TwilioSendGrid": "Doesn't offer support",
        "AWS": "Doesn't offer support"
      },
      {
        "feature": "Single Strong Mass Mailer Email Address Warmup included",
        "Trivont": "upto 4K+ emails per day ",
        "Mailgun": " not available",
        "TwilioSendGrid": " not available",
        "AWS": " not available"
      },
      {
        "feature": "IP and Domain analysis?",
        "Trivont": "Includes analysis",
        "Mailgun": "Analysis not provided",
        "TwilioSendGrid": "Analysis not provided",
        "AWS": "Analysis not provided"
      },
      {
        "feature": "Single Strong Mass Mailer Email Address",
        "Trivont": "Provided",
        "Mailgun": "Provided",
        "TwilioSendGrid": "Provided",
        "AWS": "Provided"
      },
      {
        "feature": "Sending costs per Email",
        "Trivont": "Unlimited sending with base packages",
        "Mailgun": "$35 per month for 50,000 emails",
        "TwilioSendGrid": "$89 per month for 50,000 emails",
        "AWS": "$5 per month for 50,000 emails"
      }
    ]

    const ecomServers = [
      {
        title: "Bronze eCom servers",
        price: "$400 Per Month",
        features: [
          "Your own Dedicated IP Server",
          "Unlimited inboxes (Recommended 11)",
          "Unlimited email sending",
          "Unlimited subscribers",
          "1 Single Strong Email warmup for eCom with 1,000 sent email warmups per day",
          "40gb of Storage space",
          "+10 normal inboxes warmed up for free",
          "Your own sender and inbox software per user that works like any mass mailer and web mail",
          "Our complete knowledge base to maintain and build your email reputation as a company",
          "For any eCom Email sending need"
        ],
        button: {
          url: "#",
          text: "Get Started"
        }
      },
      {
        title: "Silver eCom servers",
        price: "$700 Per Month",
        features: [
          "Your own Dedicated IP Server",
          "Unlimited inboxes (Recommended 21)",
          "Unlimited email sending",
          "Unlimited subscribers",
          "1 Single Strong Email warmup for eCom with 2,000 sent email warmups per day",
          "80gb of Storage space",
          "+20 normal inboxes warmed up for free",
          "Your own sender and inbox software per user that works like any mass mailer and web mail",
          "Our complete knowledge base to maintain and build your email reputation as a company",
          "For any Advanced eCom Email sending need"
        ],
        button: {
          url: "#",
          text: "Get Started"
        }
      },
      {
        title: "Gold eCom servers",
        price: "$1200 Per Month",
        features: [
          "Your own Dedicated IP Server",
          "Unlimited inboxes (Recommended 31)",
          "Unlimited email sending",
          "Unlimited subscribers",
          "2 Single Strong Email warmup for eCom with 4,000 sent email warmups per day",
          "150gb of Storage space",
          "+30 normal inboxes warmed up for free",
          "Your own sender and inbox software per user that works like any mass mailer and web mail",
          "Our complete knowledge base to maintain and build your email reputation as a company",
          "For any Heavy Duty eCom Email sending"
        ],
        button: {
          url: "#",
          text: "Get Started"
        }
      }
    ];
    
    return (
    <>
    <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
    {/* <Banner2 data={ hero2Data}/> */}
    <BannerSection
title="Trivont Email Solutions"
backgroundColor="#007bff"
color="#fff"
height="400px"
backgroundImage={backgrounBanner}
/>
   
        
  
  <SliderHeroSection backgroundImage='#fffff
  '  title="" subtitle=" Unmatched Security, Deliverability, and Scalability for Your Organization" 
   description="
   Trivont delivers top-tier, secure, and reliable enterprise email solutions designed for world-class organizations. Whether you need onsite or cloud-based email services, we ensure your emails meet the highest standards of security and privacy. Our solutions guarantee seamless mail flow, reaching your customers' and partners' primary inboxes with exceptional deliverability and monitoring. Experience the best in email service with Trivont.
   "
   
   textColor='#242424' isDefault={true} featureTitle='Unmatched Security, Deliverability, and Scalability for Your Organization'
     featureSubtitle="Trivont provides high-quality, secure, and dependable enterprise email solutions tailored for top-tier organizations worldwide. Whether your requirement is for onsite or cloud-based email services, we guarantee that your emails adhere to the utmost standards of security and privacy. " >
      
    </SliderHeroSection>
   
    
    
  
     <IconBox title='Email Services for Optimal Deliverability and Protection' items={ services}/> 
  
      <ResponsiveTable title='Superior Email Service Features: Outperforming the Competition' data={data}/>
      
      <Price data={{ items: ecomServers, title: "Premium SMTP Deliverability for eCommerce Solutions", desc: "Choose the best plan that suits your needs", bg_pattern: { url: "pattern.png", alt: "Background Pattern" } }} />

  
  
      <Footer data={footer} />
    
    </>
  )
}

export default EmailService