import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'

import CustomFeature from '../components/customComponents/customBoxes';
import './PagesCss.css'
import BSservices from '../components/BSservices';



function Hbase() {

    const context = useContext(DataContext);
    const { HbaseUseCases,footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;

    


      const featureData = {
        title: 'Features',
        desc: 'Key Features of Trivonts HBase Database Services',
        items: [
            {
              "title": "Distributed Architecture",
              "desc": "Harness the distributed architecture of HBase to store and manage massive datasets across a cluster of commodity hardware, ensuring high availability and fault tolerance."
            },
            {
              "title": "Linear Scalability",
              "desc": "Scale your database horizontally by adding new nodes to the cluster seamlessly, allowing you to accommodate growing data volumes and user loads without compromising performance."
            },
            {
              "title": "Columnar Store",
              "desc":"Benefit from HBase's columnar storage format, which allows for efficient retrieval of specific columns within a row, enabling fast read and write operations for both random and sequential access patterns."
            },
             {
              "title": "Automatic Sharding",
              "desc":"Enjoy automatic sharding and data partitioning capabilities in HBase, which distribute data evenly across cluster nodes based on row keys, ensuring balanced data distribution and optimized query performance."
            },
            
              {
                "title": "FStrong Consistency",
                "desc":"Ensure strong consistency for read and write operations in HBase with configurable consistency levels, allowing you to choose between strict consistency and eventual consistency based on your application requirements."
            },
              
          ]
          ,
      };



    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="HBase"
  content="Welcome to Trivont's HBase database solution, where the power of scalability meets the flexibility of NoSQL. "/>       
        


        <CustomFeature data={featureData} box1Height="310px"/>

           
           
<BSservices data={HbaseUseCases} blockHeight="370px"/>

{/* 
<section style={styles.helpSection} className="blue-bg iq-help">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="iq-title-box iq-title-default text-left  mb-0 iq-title-white iq-title-box-2">
                            <div className=""></div>
                            <span className="iq-subtitle"> </span>
                            <h2 className="iq-title undefined" style={styles.title}>Let Us Help You Grow</h2>
                            <p className="iq-title-desc undefined"> </p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-right align-self-center">
                        <div className="iq-btn-container d-inline-block mr-4">
                            <a style={styles.button} className="iq-button iq-hover d-inline" href="#">Start Your Free Trial</a>
                        </div>
                        <div className="iq-btn-container d-inline-block">
                            <a style={{ ...styles.button, ...styles.buttonOutline }} className="iq-button iq-btn-outline d-inline" href="#"> See All Plans</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<br/> */}



           <BSsection4s
      heading="Ready to embark on your cloud computing adventure?"
  title1="Begin Your Cloud Journey"
  title2="Claim Your $300 Cloud Credit Today!"
  title3="Seeking Guidance?"
  desc1="If you're new to Trivont, kickstart your journey with our range of cloud products, and enjoy a complimentary $300 credit."
  desc2="Our experts are here to assist you in crafting the perfect solution or connecting you with the right partner for your requirements."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>


           
            <Footer data={footer} />
        </> 
    )
}

export default Hbase
