import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { DataContext } from '../context';
import Footer from '../components/Footer';
import BannerSectionCareer from '../components/customComponents/bannerSectionCareer';
import CyberS from '../images/BANNERS/careerImage.jpg';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import './PagesCss.css'


const CareerReg = () => {
  const context = useContext(DataContext);
  const { footer } = context.allData;
  const [OpeningsData, setOpeningsData] = useState([]);
  const { handleSearch, handleOffcanvas } = context;

  const [user_Fname, setuser_Fname] = useState("");
  const [user_Lname, setuser_Lname] = useState("");
  const [user_email, setuser_email] = useState("");
  const [user_mobile, setuser_mobile] = useState("");
  const [file_resume, setfile_resume] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();




  const location = useLocation();
  const { jobId } = location.state || {};

  const applyForJob = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const data = new FormData();
      data.append('first_name', user_Fname);
      data.append('last_name', user_Lname);
      data.append('email', user_email);
      data.append('mobile', user_mobile);
      data.append('file_path', file_resume);
      data.append('opening', parseInt(jobId, 10));


      try {
        setIsSubmitting(true); // Set submitting state
        const response = await axios.post('https://trivont.com/api/career/insert/view/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

          if (response.data.status === 'success') {
            alert("form submitted successfully!")

            const job = OpeningsData.find(opening => opening.id === jobId);
            history.push('/responsePage', { jobTitle: job.title, userName: `${user_Fname} ${user_Lname}` });

          } else {
            alert("An error occurred, please try again later.")
          }
        } catch (error) {
        console.log(error);
        alert("An error occurred. Please try again.");
      } finally {
        setIsSubmitting(false); // Reset submitting state
      }
    } else {
      setErrors(errors);
    }
  };

  const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    if (file.size > 5 * 1024 * 1024) { // Check if file size exceeds 5MB
      alert("File size should not exceed 5MB.");
      e.target.value = ''; // Reset the input value to clear the selected file
    } else if (file.type !== 'application/pdf') {
      alert("Please select a PDF file.");
      e.target.value = ''; // Reset the input value to clear the selected file
    } else {
      setfile_resume(file);
    }
  }
};


  const validateForm = () => {
    const errors = {};
    if (!user_Fname.trim()) errors.user_Fname = "First name is required.";
    if (!user_Lname.trim()) errors.user_Lname = "Last name is required.";
    if (!user_email.trim()) {
      errors.user_email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(user_email)) {
      errors.user_email = "Email address is invalid.";
    }
    if (!user_mobile.trim()) {
      errors.user_mobile = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(user_mobile)) {
      errors.user_mobile = "Mobile number is invalid. It should be 10 digits.";
    }
    if (!file_resume) errors.file_resume = "Resume is required.";
    return errors;
  };

  useEffect(() => {
    fetchData();
    // console.log(role)
  }, []);

  const fetchData = () => {
    axios.get('https://trivont.com/api/openings/view/')
      .then(response => setOpeningsData(response.data))
      .catch(error => console.log(error));
  };




  return (
    <>
      <Navbar handler={{ handleSearch, handleOffcanvas }} headerTop="True" />
     {/*  <BannerSectionCareer title="Apply for Job" backgroundColor="#007bff" backgroundImage={CyberS} color="#fff" height="600px" customBackgroundSize='auto' />
*/}
      <div>
        {OpeningsData.filter(partner => partner.id === jobId).map((partner, index) => (

          <form key={index} id="User_userForm" className="CareerRegcontainerUser">

            <div className="row" style={{marginBottom:'30px'}}>
              <div className="col-md-6">
                <label className="form-label">First Name <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder='Enter Name'
                  autoComplete='off'
                  value={user_Fname}
                  required
                  style={{marginBottom:'0px'}}
                  onChange={(e) => setuser_Fname(e.target.value)}
                />
                {errors.user_Fname && <p style={{ color: 'red' }}>{errors.user_Fname}</p>}
              </div>

              <div className="col-md-6">

                <label className="form-label">Last Name <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder='Enter Name'
                  autoComplete='off'
                  value={user_Lname}
                  style={{marginBottom:'0px'}}
                  required
                  onChange={(e) => setuser_Lname(e.target.value)}
                />
                {errors.user_Lname && <p style={{ color: 'red' }}>{errors.user_Lname}</p>}
              </div>
            </div>

            <div className="row" style={{marginBottom:'30px'}}>
              <div className="col-md-6">
                <label className="form-label">Role <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  className="form-control"
                  value={partner.title}
                  // onChange={(e) => setRole(partner.title)}
                  style={{marginBottom:'0px'}}
                  readOnly
                />
              </div>

              <div className="col-md-6">
                <label className="form-label">Domain <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  className="form-control"
                  value={partner.domain}
                  style={{marginBottom:'0px'}}
                  readOnly
                />
              </div>
            </div>

            <div className="row" style={{marginBottom:'30px'}}>
              <div className="col-md-6">
                <label className="form-label">Email <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder='Enter Email'
                  autoComplete='off'
                  value={user_email}
                  style={{marginBottom:'0px'}}
                  required
                  onChange={(e) => setuser_email(e.target.value)}
                />
                {errors.user_email && <p style={{ color: 'red' }}>{errors.user_email}</p>}
              </div>

              <div className="col-md-6">
                <label className="form-label">Mobile <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder='Enter Mobile Number'
                  autoComplete='off'
                  value={user_mobile}
                  style={{marginBottom:'0px'}}
                  required
                  onChange={(e) => setuser_mobile(e.target.value)}
                />
                {errors.user_mobile && <p style={{ color: 'red' }}>{errors.user_mobile}</p>}
              </div>
            </div>

            <div className="row"style={{marginBottom:'30px'}}>
              <div className="col-md-6">
                <label className="form-label">Resume <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="file"
                  className="form-control"
                  autoComplete='off'
                  accept=".pdf"
                  style={{marginBottom:'0px'}}
                  onChange={handleFileChange}
                  required
                />
                {errors.file_resume && <p style={{ color: 'red' }}>{errors.file_resume}</p>}
              </div>
            </div>

            <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
        <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={applyForJob}
                style={{ marginTop: '50px', backgroundColor: '#006fef', borderRadius: '5px' }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>

            <br />
            <br />
          </form>
        ))}
      </div>
      <Footer data={footer} />
    </>
  );
}

export default CareerReg;
