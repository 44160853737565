import React from "react";
import { Table, Container, Row, Col,Card } from "react-bootstrap";
import './customcomponent.css';
import Provider4Image from '../../images/media/AWS-Logo.png';
import Provider2Image from '../../images/media/mailgun-300x122.jpg';
import Provider3Image from '../../images/media/sendgrid-300x122.jpg';
import Provider1Image from '../../images/bannerdark.svg';
const ResponsiveTable = ({ title,data }) => {
  return (
    
    <Container>
        <div className="row mb-4">
            <div className="col-lg-12">
                <div className="text-center iq-title-box iq-title-default iq-title-box-2">
                  
                    <h2 className="iq-title">{title} </h2>
                    
                </div>
            </div>
        </div>
      <div className="d-none d-md-block mt-4">
        <Table striped bordered hover className="material-table">
          <thead className="text-dark ">
          <tr>
  <th>E-commerce email marketers seek specific attributes in their SMTP providers</th>
  <th>
    <div className="d-flex justify-content-center align-items-center">
      <img src={Provider1Image} alt="Provider 1" width="400" />
    </div>
  </th>
  <th>
    <div className="d-flex justify-content-center align-items-center">
      <img src={Provider2Image} alt="Provider 2" />
    </div>
  </th>
  <th>
    <div className="d-flex justify-content-center align-items-center">
      <img src={Provider3Image} alt="Provider 3" />
    </div>
  </th>
  <th>
    <div className="d-flex justify-content-center align-items-center">
      <img src={Provider4Image} alt="Provider 4" height="50" />
    </div>
  </th>
</tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.feature}</td>
                <td>{item.Trivont}</td>
                <td>{item.Mailgun}</td>
                <td>{item.TwilioSendGrid}</td>
                <td>{item.AWS}</td>
                {/* <td>{item.feature}</td>
                <td style={{ color: "green" }}>{item.Trivont}</td>
<td style={{ color: item.Mailgun === "Included" ? "green" : "red" }}>{item.Mailgun}</td>
<td style={{ color: item.TwilioSendGrid === "Included" ? "green" : "red" }}>{item.TwilioSendGrid}</td>
<td style={{ color: item.AWS === "Included" ? "green" : "red" }}>{item.AWS}</td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="d-block d-md-none ResponsiveTablerow">
        {data.map((item, index) => (
          <Card key={index} className="mb-3">
            <Card.Body>
              <Card.Title>{item.feature}</Card.Title>
              <Card.Text>
                <strong>Trivont: <span className="text-success">{item.Trivont}</span></strong> 
              </Card.Text>
              <Card.Text>
                <strong>Mailgun: {item.Mailgun} </strong> 
              </Card.Text>
              <Card.Text>
                <strong>Twilio SendGrid: {item.TwilioSendGrid}</strong> 
              </Card.Text>
              <Card.Text>
                <strong>AWS: {item.AWS}</strong> 
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </Container>
  );
};

export default ResponsiveTable;
