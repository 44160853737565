import React, { useContext } from 'react';
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import AnimatedCardGrid from '../components/customComponents/animatedGrid';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import dataimage from '../images/icons/icons8-ansible-480.png';
import dataimage2 from '../images/icons/icons8-terraform-250.png';
import banner from '../images/BANNERS/devops.jpg'
const ITAutoOrcH = () => {
    const context = useContext(DataContext);
    const {footer,VMservice } = context.allData
  
    const { handleSearch, handleOffcanvas } = context;
    const services = [
     
      {
        title: "Taniyangi: Automation Platform",
        subtitle: "Streamlined IT Operations",
        description: "Taniyangi offers powerful features for efficient IT automation. Seamlessly manage configurations and orchestrate complex deployments. Ensure audit compliance and security with ease. Automate tasks across on-premise, cloud, or hybrid environments. Leverage AI-driven decision-making for optimized workflows. Enjoy minimal resource footprint and extensive system compatibility. Integrate with ITSM tools for Change Request automation. Enable self-service resource provisioning for end-users. Experience Taniyangi: Your gateway to transformative automation."
      }
      
    ];
    
    
    const taniyangiInfo = {
      title: "Taniyangi: Empowering Seamless Automation for IT Operations",
      subtitle: "Your Ultimate Configuration Management and Orchestration Solution",
      description: "Welcome to the world of Taniyangi, where cutting-edge automation converges with unparalleled efficiency. Taniyangi serves as your all-in-one solution for configuration management and orchestration, revolutionizing IT operations with its advanced capabilities. Say goodbye to manual tasks and hello to streamlined automation that transcends traditional boundaries.\n\nWith Taniyangi, you gain access to a suite of key features designed to elevate your IT infrastructure. From audit compliance and security to cloud automation and AI-driven decision-making, Taniyangi sets the standard for modern IT management. Enjoy the versatility of deployment across on-premise, cloud, or hybrid environments, all while benefiting from its minimal footprint and extensive system compatibility.\n\nExperience the future of IT automation with Taniyangi, where intelligent event-based decision-making and integration-friendly functionalities converge to redefine efficiency and productivity. Embrace a self-service approach to resource provisioning, seamlessly integrated with your existing IT ecosystem.\n\nDiscover Taniyangi: Your gateway to efficient, auditable, and transformative automation."
    };
      
      
  return (
   <>
  
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="false"  />
  {/* <Banner2 data={ hero2Data}/> */}
  
  
<BannerSection
title="IT  Automation & Orchestration"
backgroundColor="#007bff"
color="#fff"
height="400px"
backgroundImage={banner}
/>
      

<SliderHeroSection backgroundImage='#efefef'  title="Automation & AI" subtitle="Simplify your IT operations" 
  textColor='#242424' isDefault={true}  featureTitle="Optimize Your IT Operations with Trivont Cloud Automation Solutions"
  featureSubtitle="Transform your IT landscape with Trivont  cutting-edge automation solutions. Seamlessly integrate and automate your IT processes to streamline workflows, optimize resource allocation, and drive efficiency across your organization. Our tailored IT automation and orchestration services empower you to boost productivity and achieve your business goals effortlessly.">
  </SliderHeroSection>
 
 

   <IconBox items={services}/> 

    



    <Footer data={footer} />
  
   </>
  )
}

export default ITAutoOrcH