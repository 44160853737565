import React, { useContext } from 'react';
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import AnimatedCardGrid from '../components/customComponents/animatedGrid';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import dataimage from '../images/icons/video-card_8631133.png';
import dataimage2 from '../images/icons/hub_2729174.png';
import dataimage3 from '../images/icons/gpuservices.png';
import banner from '../images/BANNERS/hpc.jpg'
const HPC = () => {
    const context = useContext(DataContext);
    const {footer,VMservice } = context.allData
  
    const { handleSearch, handleOffcanvas } = context;
    const services = [
      {
        title: "Harness Advanced Computing Power with Trivont Cloud",
        subtitle:'GPU Clusters',
        image: dataimage,
        description: "Experience unparalleled computational capabilities with Trivont Cloud's GPU clusters. Our cutting-edge computing systems leverage the parallel processing prowess of GPUs to accelerate complex calculations and simulations. From AI-driven insights to scientific breakthroughs and industrial applications, our GPU clusters empower organizations to achieve groundbreaking results and drive innovation."
      },
      {
        title: "Low Latency ",
        subtitle:'InfiniBand',
        image: dataimage2,
        description: "Maximize performance and port density with Trivont Cloud's InfiniBand switch systems. Our advanced systems feature innovative capabilities like Scalable Hierarchical Aggregation and Reduction Protocol, along with advanced management features such as self-healing network capabilities, quality of service, and enhanced virtual lane mapping. Experience a significant performance boost for industrial, AI, and scientific applications with Trivont Cloud's InfiniBand solutions."
      },
      // {
      //   title: "Unlock High-Performance Computing with Trivont Cloud's GPU as a Service (GPUaaS)",
      //   image: dataimage3,
      //   description: "Leverage high-performance computing with Trivont Cloud's GPU as a Service. Tailored for machine learning, deep learning, and data-intensive tasks, our GPUaaS solution harnesses the power of GPUs without the need for costly hardware or infrastructure management. Focus on innovation while we handle the rest, ensuring seamless GPU-accelerated computing for your organization's success."
      // }
      {
        title: "Next-Generation NVIDIA Solutions for Advanced Computing",
       image:dataimage3,
        subtitle: "Elevate Your Infrastructure with Cutting-Edge NVIDIA Technologies",
        description: "Harness the power of NVIDIA's innovative solutions to drive unparalleled performance and efficiency across your infrastructure. From GPU Compute Nodes and GPU Clusters to Data Processing Units (DPUs) and NVMe RAMs, our comprehensive range of NVIDIA services ensures optimized performance for AI, ML, and Deep Learning workloads."
      }
    ];
    
      
      
  return (
   <>
  
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="false"  />
  {/* <Banner2 data={ hero2Data}/> */}
  
  <BannerSection
        title="High Performance Computing"
        backgroundColor="#007bff"
        color="#fff"
        height="400px"
        backgroundImage={banner}
       
      />
      

<SliderHeroSection backgroundImage='#efefef'  title="Automation & AI" subtitle="Simplify your IT operations" 
  textColor='#242424' isDefault={true}  featureTitle= "Unlock Your Enterprise Potential with GPU Acceleration and Low-Latency Solutions"
  featureSubtitle="Experience unmatched performance and reliability across fintech, gaming, and R&D sectors with Trivont Cloud's 
  low-latency solutions and NVMe storage. Elevate your operations with our cutting-edge technologies.Supercharge your computational capabilities and drive breakthrough innovations with Trivont Cloud's 
  GPU-accelerated supercomputing solutions. 
  .">
  </SliderHeroSection>
 
  <MigrationJumbotron title="Unlock Your Business Potential with Trivont Software Solutions"
  description="Contact us today to discuss your project requirements and get started on building custom software solutions tailored to your business needs." />  
       

   <IconBox items={services}/> 

    



    <Footer data={footer} />
  
   </>
  )
}

export default HPC