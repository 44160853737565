import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';


import CustomFeature from '../components/customComponents/customBoxes';
import Footer from '../components/Footer';
import FAQList from '../components/customComponents/faq';
import BSsection4s from '../components/BSsection4';
import FourBlocks from '../components/blocksFour';
import imgData from '../components/banner/FireWall.png';


const CloudFireWall = () => {
  const context = useContext(DataContext);
  const {  footer,CFbenefits } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  const featureData = {
    title: 'Enhance Security with Cloud Firewalls',
    desc: 'Explore the Features of Virtual Firewall (L3 & L7)',
    items: [
        {
          "title": "Comprehensive Security",
          "desc": "Protect your network at both the network and application levels with our Virtual Firewall solutions. Our firewall offers comprehensive security features designed to safeguard your infrastructure against a wide range of threats and vulnerabilities."
         },
        {
          "title": "SLayer 3 Security",
          "desc": "Ensure the integrity of your network traffic with Layer 3 security controls. Our Virtual Firewall solution provides robust network-level security measures, including access control lists (ACLs), routing policies, and virtual private networks (VPNs), to protect your network infrastructure."
          },
        {
          "title": "Layer 7 Security",
          "desc":"Secure your applications and services with Layer 7 application-level security. Our Virtual Firewall solution offers deep packet inspection (DPI), application-aware filtering, and content filtering capabilities to detect and mitigate advanced threats targeting your applications."
         },
         {
          "title": "Granular Control",
          "desc":"Exercise granular control over your network traffic with customizable rules and policies. Our Virtual Firewall solution enables you to define access controls, traffic filtering rules, and application-specific policies to protect your assets and enforce compliance requirements."
          },
        
        // Add more features as needed
      ]
      ,
  };
  const faq = [
    {
      title: 'What is a Cloud Firewall?',
      desc: 'A cloud firewall acts as a barrier between incoming traffic and cloud infrastructure. It safeguards web applications and data from external threats and suspicious traffic. It enables setting up filtering rules and provides granular control over network traffic.'
    },
    {
      title: 'How does a Cloud Firewall differ from a traditional firewall?',
      desc: 'Cloud-based firewalls are hosted within the cloud, providing flexibility and scalability for cloud infrastructure users. They are managed through a central console, making management easier compared to traditional firewalls. Next-generation firewalls offer advanced security features.'
    },
    {
      title: 'What are the main benefits of using a firewall?',
      desc: 'Firewalls enhance security for cloud-hosted applications and databases by blocking hacks and suspicious traffic. They integrate easily with existing cloud infrastructure and can handle increased web traffic.'
    },
    {
      title: 'What are the key features of a Cloud Firewall?',
      desc: 'A cloud firewall should be simple to set up and deploy, enabling users to define various firewall rules. It should provide visibility into application traffic and detect and block potentially malicious traffic.'
    },
    {
      title: 'How do I get started with a Cloud Firewall?',
      desc: 'Cloud firewalls can be set up through CLI, API, or a cloud control panel. Creating rules to define traffic access is simple and straightforward, providing an efficient way to manage and maintain security.'
    },
    {
      title: 'Virtual Firewall (L3 & L7)',
      desc: 'Our advanced Virtual Firewall solutions offer both Layer 3 (network) and Layer 7 (application) security. They ensure secure communication between applications, providing granular control over network traffic and maximizing performance and efficiency.'
    },
    {
      title: 'What other security measures should I consider to protect my applications?',
      desc: 'In addition to a cloud firewall, consider implementing access management and control systems, SSH keys, load balancers, DDoS attack prevention solutions, and advanced threat mitigation techniques. Regularly backing up data using a cloud backup solution is also recommended.'
    }
  ];
  
  
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  
  <CustomSection header="Cloud Firewall"
  subtitle=""
  content="Discover how our Cloud Firewall solutions empower businesses with comprehensive security measures to safeguard their digital assets and data."
  isRow={true}
  img={imgData}
  />
  
    <CustomFeature data={featureData} box1Height="370px"/>
   

   <FourBlocks data={CFbenefits} blockHeight="220px"/>
 
    <FAQList faqData={faq}/>

    <BSsection4s
  heading="Secure Your Cloud Infrastructure with Cloud Firewall"
  title1="Start Protecting Your Cloud"
  title2="Enhance Your Security"
  title3="Need Expert Guidance?"
  desc1="Explore our Cloud Firewall solutions to safeguard your cloud infrastructure. Whether you're securing applications or data, begin your journey with our robust solutions and enjoy a complimentary $300 credit."
  desc2="Our team of cybersecurity experts is dedicated to helping you implement the perfect Cloud Firewall solution or connecting you with the right partners to fortify your cloud security."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>


   <Footer data={footer}/>
   
  
  </>
  )
}

export default CloudFireWall