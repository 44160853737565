import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';
import BSservices from '../components/BSservices';

import Box from '../components/customComponents/box';
import { Container } from 'react-bootstrap';
import compute from '../components/banner/ComputeSvg.png'

const VirtualMachine = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData

  const { handleSearch, handleOffcanvas } = context;
  
  const virtualMachineCards = [
    {
      title: "Flexibility and Performance",
      subtitle: "Elevate your computing experience with our Virtual Machines, providing the perfect blend of flexibility and performance. Tailored for various workloads, our VMs adapt to your needs, offering seamless deployment and scalable resources."
    },
    {
      title: "Ideal for Workloads",
      subtitle: "Whether you're developing environments or hosting web applications, our Virtual Machines are designed for diverse workloads. Experience optimal performance across a range of applications, ensuring efficiency in your virtualized environment."
    },
    {
      title: "Scalable Resources",
      subtitle: "Enjoy the freedom of scalable resources with our Virtual Machines. Tailor the computing power to match your requirements, ensuring that your applications receive the right amount of resources for reliable and efficient performance."
    },
    {
      title: "Robust Security",
      subtitle: "Security is paramount, and our Virtual Machines prioritize it. Benefit from robust security measures that safeguard your applications and data. Trust in a virtualized environment where your computing experience is not just flexible but also secure."
    }
  ];
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
  {/* <Banner2 data={ hero2Data}/> */}
  <CustomSection header="Virtual Machine"
  subtitle="Compute"
  content="Empower your operations with our Virtual Machines delivering adaptable resources and secure performance for your diverse computing demands."
  img={compute}
  isRow={true}
  />
 
 
    <Box data={virtualMachineCards} title=" Features That Redefine Excellence"  boxHeight="410px"/>
  

    <BSservices data={VMservice} blockHeight="470px" />

    <BSsection4s
  heading="Ready to explore virtual machines and computing options?"
  title1="Begin Your Virtual Machine Journey"
  title2="Optimize Your Computing Resources"
  title3="Need Assistance with Compute Solutions?"
  desc1="If you're new to virtual machines, start your journey with our range of compute products and experience the flexibility of cloud computing."
  desc2="Our experts are available to help you optimize your computing resources or connect you with the right partner for your compute requirements."
  buttonText1="Start Exploring VMs"
  buttonText2="Connect with Our Compute Experts"
/>

    <Footer data={footer} />
  
  </>
  )
}

export default VirtualMachine