import React, { useContext } from 'react'
import { DataContext } from '../context'
import PageTitle from '../components/PageTitle'
import Solution from '../components/Solution'
import Team from '../components/Team';
import Testimonial from '../components/Testimonial';
import Footer from '../components/Footer';
import Counter from '../components/Counter';
import LogoCarousel from '../components/LogoCarousel';
import Navbar from '../components/Navbar';
import TwoColumnBlock from '../components/TwoBlockColumn';

function About() {

    const context = useContext(DataContext);
    const { counter, companyLogo, solution, team, testimonial, footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;
    const teamData = {
        title: 'Our Core Values',
        desc: 'We consider and adapt these values for reaching our vision and mission during this business journey.',
        items: [
            {
                subtitle: 'Professionalism',
                img: {
                    url: '', // Provide the URL of the image
                    alt: 'Team member 1' // Provide the alt text for the image
                }
            },
            {
                subtitle: 'Team member 2',
                img: {
                    url: 'image-url-2.jpg',
                    alt: 'Team member 2'
                }
            },
            // Add more team members as needed
        ]
    };
    return ( 
        <>
            <Navbar handler={{ handleSearch, handleOffcanvas }} headerTop='true'/>
            <PageTitle pageName="About Us" />
            <TwoColumnBlock
      heading=""
  title1="Who We are"
  content1="We strive to be recognized as the go-to IT system integration and consulting partner. We offer cutting-edge Cloud and Infrastructure management services that drive our valued clients' efficiency, security, and scalability. With our customer-centric approach and passion for excellence, we aim to elevate businesses to new heights in the digital era."
  title2="Get started quickly"
  title3="Keep costs low"
  title31="Creative Ideas"
  title21="Scale Up with Confidence"
  desc1="Start projects swiftly with TRIVONT. Tailored solutions for developers and startups. Support at every step"
  desc2="Ensure cost-effectiveness with TRIVONT's transparent pricing. Competitive options without sacrificing quality."
  desc11="Explore innovation with TRIVONT. Dedicated team for unique solutions. Bringing ideas to life."
  desc21="Scale your operations confidently with TRIVONT Technologies. Our commitment to reliability means you can trust us to deliver a 99.99% uptime."
  buttonText1="Read More"
  showButton={true}
 
/>

            <Team data ={team} classText="section-bg pt-120 pb-140" />
            
            <Footer data={footer} /> 
        </>
    )
}

export default About