import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import OSservices from '../components/OSservice';
import IBox from '../components/KafkaB1';
import './PagesCss.css'
import kafka from '../components/banner/Kafka.png'


function Kafka() {

    const context = useContext(DataContext);
    const {KafkaBox,price, featuresKafka, footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;

  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
            
            <CustomSection header="KAFKA" content="Seamless Kafka Integration for Streamlined Data Management"
            img={kafka}
            isRow={true}  />


           <IBox data={KafkaBox} columns={3} boxHeight="240px"/>
           
           <OSservices data={featuresKafka} blockHeight="380px"/>
           
         
            <Footer data={footer} />
        </> 
    )
}

export default Kafka
