import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'
import MDB01 from '../components/mongoDbCard';
import OSservices from '../components/OSservice';
import './PagesCss.css'
import mongo from '../components/banner/mongodb.png';


function MongoDB() {

    const context = useContext(DataContext);
    const {MongoDBCards, MDBservice, footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;

  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="MongoDB"
  content="Power your apps with our MongoDB cloud service. We handle all the technicalities, so you can focus on building the best experiences."
  isRow={true}
  img={mongo}/>       
        


           <MDB01 data={MongoDBCards} blockHeight="470px"/>


           
           <OSservices data={MDBservice} blockHeight="400px"/>

           
           <BSsection4s
  heading="Ready to Elevate Your Data Management?"
  title1="Start Your MongoDB Journey"
  title2="Scalable and Flexible Databases"
  title3="Need Expert Guidance?"
  desc1="Unlock the potential of MongoDB with Trivont. Begin your journey with our robust database solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of MongoDB experts is here to help you design the perfect database architecture or connect you with the right partner to meet your data management needs."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>


           
            <Footer data={footer} />
        </> 
    )
}
export default MongoDB
