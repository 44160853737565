import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'
import MDB01 from '../components/mongoDbCard';
import OSservices from '../components/OSservice';
import './PagesCss.css'



function GpuCloud() {

    const context = useContext(DataContext);
    const {MDBservice, footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;

  
    const GPU = {
        title: "Simplify your MongoDB management effortlessly",
        desc: "Our Services",
        items: [
            {
                img: {
                    url: "images/icons/database.png",
                    alt: "Service Icon"
                },
                title: "AI Research",
                desc: "Accelerate your AI research by tapping into our high-performance GPUs for complex modeling and simulations."
            },
            {
                img: {
                    url: "images/icons/production.png",
                    alt: "Service Icon"
                },
                title: "Deep Learning",
                desc: "Train deep neural networks with speed and efficiency, paving the way for breakthroughs in computer vision, natural language processing, and more."
            },
            {
                img: {
                    url: "images/icons/scalability.png",
                    alt: "Service Icon"
                },
                title: "Data Analytics",
                desc: "Perform rapid data analysis and gain insights from massive datasets with the computational muscle of our GPUs."
            },
            {
                img: {
                    url: "images/icons/scalability.png",
                    alt: "Service Icon"
                },
                title: "AI-Powered Applications",
                desc: "Build intelligent applications and services that leverage AI and ML to enhance user experiences and drive business growth."
            }
        ]
    };
    

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="Super Compute Cloud"
  content="Harness the immense power of Graphics Processing Units (GPUs) with our GPU Cloud platform. Whether you're diving into AI research, building intelligent applications, or training deep neural networks, our GPU infrastructure is designed to accelerate your journey."
  isRow={false}
 />       
        


           <MDB01 data={GPU} blockHeight="470px"/>


           
           <OSservices data={MDBservice} blockHeight="400px"/>

           
           <BSsection4s
  heading="Ready to Leverage GPU Power in the Cloud?"
  title1="Start Your GPU Cloud Journey"
  title2="High-Performance Computing Solutions"
  title3="Need Expert Guidance?"
  desc1="Explore the capabilities of Trivont's GPU cloud services. Begin your journey with our high-performance computing solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of cloud experts is here to help you deploy and optimize GPU instances or connect you with the right partner to meet your specific computational requirements."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>



           
            <Footer data={footer} />
        </> 
    )
}
export default GpuCloud
