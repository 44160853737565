import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';

import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import AnimatedCardGrid from '../components/customComponents/animatedGrid';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import disimageone from '../images/icons/disaster-recovery.png'
import disimagetwo from '../images/icons/noun-synchronize-6376832.png'
import disimagethree from '../images/icons/noun-virtualization-6680846.png'
import disimagefour from '../images/icons/noun-backup-1461026.png'
import disbanner from '../images/BANNERS/dbr.png'
const DisasterRecovery = () => {
    const context = useContext(DataContext);
    const {footer,VMservice } = context.allData
  
    const { handleSearch, handleOffcanvas } = context;
    
    const VirtualizationData = [
      {
        title: "Centralized Backup and Recovery Solutions",
        subtitle: "Simplify Your Backup Strategy with a Single Vendor",
       image:disimagetwo,
        description: "Opt for a single vendor for your backup and recovery needs to streamline processes and ensure compatibility. Simplify your backup strategy and save time and resources."
      },
        {
          title: "Simplified Backup Process ",
          subtitle: "Effortless Data Backup Solutions Await",
          image:disimagefour,
          description: "Experience streamlined backup processes by generating image backups of computers, data, and software through virtualization. Benefit from comprehensive solutions tailored to your business needs."
        },
        {
          title: "Cost-Efficient Disaster Recovery with Virtualization",
          subtitle: "Streamlined Disaster Recovery Made Affordable",
          image:disimageone,
          description: "Virtualization eliminates the need for server rebuilds, significantly reducing costs associated with backup and disaster recovery. Implement disaster recovery solutions with ease and affordability."
        },
        
        ,
      ];
      
    
    
    
    return (
    <>
    <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
    
    
    <BannerSection
          title="Disaster Recovery & Backup"
          backgroundColor="#007bff"
          color="#fff"
          height="400px"
          backgroundImage={disbanner}
        />
        
  
  <SliderHeroSection backgroundImage='#efefef'  title="Automation & AI" subtitle="Simplify your IT operations" 
    textColor='#242424' isDefault={true} featureTitle='Trivont Cloud Redefining Backup and Disaster Recovery'
     featureSubtitle="Experience a paradigm shift in disaster recovery strategies with Trivont Cloud. Say goodbye to outdated practices like manual server purchases and system reinstallations. Embrace virtualization for seamless data backup and recovery, ensuring comprehensive protection without the hassle of traditional methods" >
      
    </SliderHeroSection>
   
    <MigrationJumbotron title='Fortify Your Business Against Data Disasters with Trivont Cloud' 
    description="Safeguard your business's continuity with our robust disaster recovery solutions" />  
    
  
     <IconBox items={ VirtualizationData}/> 
  
      
  
  
  
      <Footer data={footer} />
    
    </>
  )
}

export default DisasterRecovery