import React from 'react';
import { Container } from 'react-bootstrap';

const Columns2s = ({ data }) => {


 

  if (!data || !data.columns || data.columns.length !== 2) {
    return null; 
  }


    
    let paragraphMargin = {};

   
    if (window.innerWidth <= 768) {
    
      paragraphMargin = { marginTop: '20px' };
    }

    

  return (
    
      <Container style={{marginTop:'50px', marginBottom:'20px'}}>
   
      <div className="row2">
        <div className="small-column">
          <h2>{data.columns[0].title}</h2>
          
        </div>
        <div className="divider"></div>
        <div className="big-column">
         
          <p style={{...paragraphMargin }}>{data.columns[1].desc}</p>
        </div>
      </div>
   
    </Container>
    
  );
};



export default Columns2s;
