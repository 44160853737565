import React from 'react'
import Loading from './Loading'
import Title from './Title'
import { Container, Row, Col } from 'react-bootstrap';

function Price({ data, classText, styleTwo, styleThree, styleFour }) {

    let publicUrl = process.env.PUBLIC_URL + '/'
    if (!data) {
        return <Loading />
    }
    return (
        <section className={`${classText} pricing`}>
          
            <Container>
                <Row>
                    <Col>
                        {/* Section Title */}
                        <Title title={data.title} subtitle={data.desc}  />
                        {/* End Section Title */}
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    {
                        data.items.map((item, index) => {
                            let active = index === 1 ? "active" : '';
                            return (
                                <div key={index} className="col-lg-4 col-md-6">
                                    {/* Single Price  */}
                                    <div className={`${active} single-price ${styleTwo ? 'style--two' : styleThree ? 'style--three' : ''}`}>
                                        {item.ribbon ? <h4 className="ribbon">{item.ribbon}</h4> : ''}
                                        <div className="price-head">
                                            <h4>{item.title}</h4>
                                            <div className="price">
                                                <span className="value ">{item.price.split(' ')[0]}</span>
                                                <span className="duration mr-2">{item.price.split(' ')[1]}</span>
                                                <span className="duration">{item.price.split(' ').slice(2).join(' ')}</span>
                                            </div>
                                        </div>
                                        <div className="price-body">
                                            <ul>
                                                {item.features.map((feature, idx) => (
                                                    <li key={idx}>{feature}</li>
                                                ))}
                                            </ul>
                                           
                                        </div>
                                    </div>
                                    {/* End Single Price  */}
                                </div>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}

export default Price

Price.defaultProps = {
    classText: 'gradient-bg pt-120 pb-90',
    styleTwo: false,
    styleThree: false
}
