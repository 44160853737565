

import React, { useEffect, useState } from "react";

import axios from "axios";
import API_ROUTES from "../routes/apiRoutes";



const     PricingTableComponent = React.forwardRef(({ title, description, PriceTitle }, ref) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [PriceTitle]);
  
  const fetchData = async () => {
    try {
      // http://127.0.0.1:8000/django/price/${PriceTitle}/
      const trimmedTitle = PriceTitle.trim(); // Remove leading and trailing whitespace
    const response = await axios.get(API_ROUTES.apiUrl + '/django/price/' + trimmedTitle +'/');
 
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
    
  };
  return (
    <div ref={ref} className="Pricing__content">
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <table>
      <thead>
        <tr>
          <th>vCPUs</th>
          <th>Memory</th>
          <th>Bandwidth</th>
          <th>Storage</th>
          <th>Monthly Price</th>
          <th>Hourly Price</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.master}>
            <td>{item.vCPUs}</td>
            <td>{item.memory} GB</td>
            <td>{item.bandwidth}</td>
            <td>{item.storage} TB</td>
            <td>$ {item.monthly_price}</td>
            <td>{item.hourly_price} $</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
});

export default PricingTableComponent;
