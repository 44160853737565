import React,{useContext, useState,useEffect} from 'react'
import Navbar from '../components/Navbar'
import { Container, Jumbotron  } from 'react-bootstrap'
import axios from 'axios'
import CustomAlert from '../components/customComponents/alert'
import { DataContext } from '../context'
import Footer from '../components/Footer'
import { Form, Button,Row, Col } from 'react-bootstrap';
import PageTitle from '../components/PageTitle'
import countries from '../data/countries.json'
import { useHistory } from 'react-router-dom';


const ContactUs = () => {
    const [formData, setFormData] = useState({
      first_name: '', 
      last_name: '',
      email: '',
      telephone: '', 
      companyName: '', 
      jobRole: '', 
      companyType: '', 
      city: '',
      state: '',
      zip: '',
      country: '',
      inquiryNature: '', 
      additionalDetails: '', 
      updates: false, 
      interest: '' 
    });

  
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      const newValue = type === 'checkbox' ? checked : value;
      setFormData({ ...formData, [name]: newValue });
    };
  
   
    const [isSubmitting, setIsSubmitting] = useState(false);  
    const context = useContext(DataContext);
    const { footer} = context.allData
    const history = useHistory();


    const contactCreate = async (e) => {
      e.preventDefault();
      console.log(formData)
        try {
	  setIsSubmitting(true);
          const response = await axios.post('https://trivont.com/api/contact/', formData);
  
            if (response.data.status === 'success') {
              alert("Message Received!")
              history.push('/responseEmailPage',{ first_name: formData.first_name , last_name: formData.last_name, email: formData.email, telephone: formData.telephone, companyName:formData.companyName, companyType:formData.companyType });

  
  
            } else {
              alert("An error occurred, please try again later.")
		setIsSubmitting(false);
            }
          } catch (error) {
          console.log(error);
          alert("An error occurred. Please try again.");
	setIsSubmitting(false);
        }
      
    };


  return (
   <>
   
   <Navbar headerTop="True"/>
   <PageTitle pageName="Contact Us" />
  
    <Container fluid>
   
    <Row className="justify-content-center align-items-center mt-4 mb-4">
        <Col lg={7}>
          <div className="contact-form-wrap">
            <Form onSubmit={contactCreate}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="first_name">
                    <Form.Label>First Name:</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="first_name" 
                      onChange={handleChange} 
                      required 
                      pattern="[A-Za-z\s]+" 
                      title="Please enter Characters only" 
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="last_name">
                    <Form.Label>Last Name:</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="last_name" 
                      onChange={handleChange} 
                      required 
                      pattern="[A-Za-z\s]+" 
                      title="Please enter Characters only" 
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
              <Col md={6}>
              <Form.Group className="mb-3" controlId="telephone">
                <Form.Label>Telephone:</Form.Label>
                <Form.Control 
                  type="text" 
                  name="telephone" 
                  onChange={handleChange} 
                  required 
                  title="Please enter a valid phone number with numbers, plus sign (+), or hyphen (-) only" 
                />
              </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email:</Form.Label>
                <Form.Control 
                  type="text" 
                  name="email" 
                  onChange={handleChange} 
                  required 
                  title="Please enter email only" 
                />
              </Form.Group>
              </Col>
              </Row>
              <Form.Group className="mb-3" controlId="companyName">
                <Form.Label>company Name:</Form.Label>
                <Form.Control 
                  type="text" 
                  name="companyName" 
                  onChange={handleChange} 
                  required 
                  title="Please enter a company name" 
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="jobRole">
                <Form.Label>Job Role:</Form.Label>
                <Form.Control 
                  as="select" 
                  name="jobRole" 
                  onChange={handleChange} 
                  required
                >
                  <option value="">Select</option>
                  <option value="Engineer">Engineer</option>
                  <option value="Developer">Developer</option>
                  <option value="Entrepreneur">Entrepreneur</option>
                  <option value="IT Executive">IT Executive</option>
                  <option value="Press / Media Analyst">Press / Media Analyst</option>
                  <option value="Sales / Marketing">Sales / Marketing</option>
                  <option value="System Administrator">System Administrator</option>
                  <option value="Advisor / Consultant">Advisor / Consultant</option>
                </Form.Control>
              </Form.Group>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="companyType">
                    <Form.Label>Company Type:</Form.Label>
                    <Form.Control 
                      as="select" 
                      name="companyType" 
                      onChange={handleChange} 
                      required
                    >
                      <option value="">Select</option>
                      <option value="Start-up">Startup</option>
                      <option value="Enterprise">Enterprise</option>
                      <option value="Small Business">Small Business</option>
                      <option value="Medium Business">Medium Business</option>
                      <option value="Self Employed">Self-employed</option>
                      <option value="Education">Education</option>
                      <option value="Government">Government</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="city">
                    <Form.Label>City:</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="city" 
                      onChange={handleChange} 
                      required 
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="state">
                    <Form.Label>State:</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="state" 
                      onChange={handleChange} 
                      required 
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="zip">
                    <Form.Label>Zip:</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="zip" 
                      onChange={handleChange} 
                      required 
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="country">
          <Form.Label>Country</Form.Label>
          <Form.Control 
            as="select"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          >
            <option value="">Select a country</option>
            {countries.map((country, index) => (
              <option key={index} value={country.code}>
                {country.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

              <Form.Group className="mb-3" controlId="inquiryNature">
                <Form.Label>Nature of Inquiry:</Form.Label>
                <Form.Control 
                  as="select" 
                  name="inquiryNature" 
                  onChange={handleChange} 
                  required
                >
                  <option value="">Select</option>
                  <option value="billing">Billing / Account Support</option>
                  <option value="compliance">Compliance Inquiry</option>
                  <option value="sales">Sales Support</option>
                  <option value="technical">Technical Support</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="additionalDetails">
                <Form.Label>Questions/Additional Details (optional):</Form.Label>
                <Form.Control 
                  as="textarea" 
                  name="additionalDetails" 
                  rows={5} 
                  onChange={handleChange} 
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>I am completing this form in connection with my:</Form.Label>
                <div>
                  <Form.Check 
                    type="radio" 
                    id="businessInterests" 
                    name="interest" 
                    value="business" 
                    label="Business interests" 
                    onChange={handleChange} 
                  />
                  <Form.Check 
                    type="radio" 
                    id="personalInterests" 
                    name="interest" 
                    value="personal" 
                    label="Personal interests" 
                    onChange={handleChange} 
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="Checkbox">
                <Form.Check 
                  type="checkbox" 
                  name="updates" 
                  label="I consent to receive updates and information about the latest content and offerings from Trivont by email." 
                  onChange={handleChange} 
                />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={isSubmitting}  style={{backgroundColor:'#0d1e67'}}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    
    </Container
    >
    <Footer data={footer}/>
   </>
  )
}

export default ContactUs
