import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4';
import Box from '../components/customComponents/box';
import Columns2s from '../components/TwoColumn';
import staticWebsiteHosting from '../components/banner/staticWebsiteHosting.png'

import './PagesCss.css'



function StaticWebsiteHosting() {

    const context = useContext(DataContext);
    const { footer,Column6} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const SWFeatures = [
        {
          "title": "Efficient Dloyment Process",
          subtitle: "Swift and hassle-free deployment of static websites, ensuring a seamless and quick setup process."
        },
        {
          "title": "Scalable Infrastructure",
          subtitle:"Easily scale your infrastructure based on the growing needs of your static website, ensuring optimal performance."
        },
        {
          "title": "Robust Security Measures",
          subtitle: "Implement robust security measures to protect your static website from potential threats and unauthorized access."
        },
        {
          "title": "High Availability",
          subtitle: "Ensure high availability of your static website, minimizing downtime and providing a reliable online presence."
        },
        {
          "title": "Global Content Delivery",
          subtitle: "Efficiently deliver content to a global audience with a content delivery network (CDN) for improved speed and accessibility."
        },
        {
            "title": "HCost-Effective Solutions",
            subtitle: "Cost-effective hosting solutions tailored for static websites, optimizing resource utilization and minimizing expenses."
          },
          {
            "title": "Automatic Backups" ,
            subtitle:"Automatically backup your static website data at regular intervals, providing data recovery options in case of unexpected issues."
          },
          {
            "title": "Custom Domain Support" ,
            subtitle: "Support for custom domain names, allowing you to personalize the web address of your static website."
          },
          {
            "title": "User-Friendly Control",
            subtitle: "An intuitive and user-friendly control panel for easy management and configuration of your static website hosting settings."
          },
          {
            "title": "24/7 Techinical Support",
            subtitle: "Access round-the-clock technical support to assist with any issues or inquiries related to your static website hosting."
          }
        
      ]


  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}}  headerTop="True"  />
            <CustomSection header="Website Hosting"
  content="Efficient, Scalable, and Secure Solutions for Your Static Websites"
  img={staticWebsiteHosting}
  isRow={true}/>       

<Columns2s data={Column6}/>

<Box data={SWFeatures} boxHeight="260px"/>


     
       
<BSsection4s
  heading="Ready to Launch Your Website with Ease?"
  title1="Start Your Static Web Hosting Journey"
  title2="Fast and Secure Hosting Solutions"
  title3="Need Expert Assistance?"
  desc1="Experience the simplicity of Trivont's static web hosting. Kickstart your journey with our reliable hosting solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of hosting experts is here to help you set up the perfect static website or connect you with the right partner to ensure your site's success."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>



            <Footer data={footer} />
        </> 
    )
}

export default StaticWebsiteHosting
