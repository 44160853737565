import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4';
import Box from '../components/customComponents/box';
import Columns2s from '../components/TwoColumn';
import corporateEmail from '../components/banner/corporateEmail.png'

import './PagesCss.css'



function CorporateCommunication() {

    const context = useContext(DataContext);
    const { footer,Column8} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const CCFeatures = [
        {
          "title": "Robust Collaboration",
          subtitle: "Facilitate seamless collaboration within your organization with advanced features that enhance teamwork and communication."
        },
        {
          "title": "Advanced Security Measures",
          subtitle:"Ensure the safety of your corporate communication with state-of-the-art security measures to protect sensitive information."
        },
        {
          "title": "Scalability Options",
          subtitle: "Adapt to the evolving needs of your business with scalable corporate email solutions that grow with your organization."
        },
        {
          "title": "Uninterrupted Access",
          subtitle: "Experience uninterrupted access to your emails, calendars, and contacts, fostering productivity in and out of the office."
        },
        {
          "title": "Efficient Team Productivity",
          subtitle: "Support your team's productivity with a Corporate Email service designed to streamline communication and enhance efficiency."
        }
        
      ]


  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
            <CustomSection header="Corporate Communication"
            subtitle=''
  content=" Empower Your Small Business with Corporate Email Services"
  img={corporateEmail}
  isRow={true}/>       

<Columns2s data={Column8}/>
<Box data={CCFeatures}  boxHeight="280px"/>

       
        <BSsection4s
      heading="Ready to embark on your cloud computing adventure?"
  title1="Begin Your Cloud Journey"
  title2="Claim Your $300 Cloud Credit Today!"
  title3="Seeking Guidance?"
  desc1="If you're new to Trivont, kickstart your journey with our range of cloud products, and enjoy a complimentary $300 credit."
  desc2="Our experts are here to assist you in crafting the perfect solution or connecting you with the right partner for your requirements."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>


            <Footer data={footer} />
        </> 
    )
}

export default CorporateCommunication
