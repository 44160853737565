import React,{useState,useEffect} from 'react'
import Loading from '../Loading'
import Title from '../Title'
import { Container, Row, Col } from 'react-bootstrap';

function CustomFeature({data, classText, box1Height}) {

//   const [boxHeight1, setBlockHeight] = useState(box1Height); 

//     useEffect(() => {
//       const updateBlockHeight = () => {
//         if (window.innerWidth <= 767) { 
//           setBlockHeight(parseInt(box1Height) - 0 + 'px'); 
//         } else {
//           setBlockHeight(box1Height); 
//         }
//       };
   
//       updateBlockHeight();
//       window.addEventListener('resize', updateBlockHeight);
   
//       return () => {
//         window.removeEventListener('resize', updateBlockHeight);
//       };
//     }, [box1Height]); 
   


    
    if(!data) {
        return <Loading />
    }
    return (
        <section className={`${classText} feature`}>
            <Container>
                <Row>
                    <Col>
                        <Title title={data.title} subtitle={data.desc} />
                    </Col>
                </Row>
                <Row className="justify-content-center single-feature-wrap">
                    {
                        data.items.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 col-md-6">
                                    <div className="single-feature" style={{height: box1Height, boxShadow:'none', border: " 1px solid #2b77e8", padding: "35px 45px 35px" }}>
                                       
                                        <div className="feature-content">
                                            <h3>{item.title}</h3>
                                            <p>{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}

export default CustomFeature

CustomFeature.defaultProps = {
    classText: 'pt-120 pb-90'
}
