import React, { useContext } from 'react';
import Navbar from '../components/Navbar';
import { DataContext } from '../context';
import Footer from '../components/Footer';
import CyberS from '../images/BANNERS/careerImage.jpg';
import BannerSectionCareer from '../components/customComponents/bannerSectionCareer';
import { useLocation } from 'react-router-dom';

const Responsepage = () => {
  const context = useContext(DataContext);
  const { footer } = context.allData;
  const { handleSearch, handleOffcanvas } = context;

  const location = useLocation();
  const { jobTitle, userName } = location.state || {};

  return (
    <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
 
 
  
  <BannerSectionCareer title={`Hello, ${userName}`} backgroundColor="#007bff" backgroundImage={CyberS} color="#fff" height="600px" customBackgroundSize='auto'/>



  <div className="container mt-5" style={{marginTop:'50px',marginBottom:'30px'}}>
        <h2 className="text-center">Thank You for Your Application</h2>
        <p className="text-center mt-3">
          Thank you for applying to Trivont. We have successfully received your application for the {jobTitle} position.
        </p>
        <p className="text-center mt-3">
          Our hiring team is currently reviewing all applications, and we will get back to you within the next seven business days regarding the next steps.
        </p>
        <p className="text-center mt-3">
          If you do not hear from us within this period, please consider that we are not proceeding with your application at this time. However, we will keep your resume on file for future opportunities that may better match your profile.
        </p>
        <p className="text-center mt-3">
          Thank you once again for your interest in joining Trivont. We wish you all the best in your job search.
        </p>


      </div>

      <div style={{display:'flex',justifyContent:'center'}}>
        <a href='/' style={{marginBottom:'50px', color:'blue', cursor:'pointer'}}>
      Back --{'>'} 
      
      </a></div>

  




    <Footer data={footer} />
  
  </>
  )
}

export default Responsepage