import React, { useContext } from 'react'
import { DataContext } from '../context'

import Feature from '../components/Feature';
import Solution from '../components/Solution';
import Service from '../components/Service';
   
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import FourBlocks from '../components/blocksFour';

import  { useState, useEffect } from "react";
import CheroSection from '../components/customComponents/carouselHerosection';





function Home() {

    const context = useContext(DataContext);
    const {  solution, service, footer, WhyTrivont } = context.allData
    const { handleSearch, handleOffcanvas } = context;
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
  
    useEffect(() => {
      // Simulate some async operation (e.g., fetching data)
      const interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 1 : 100
        );
      }, 15); // Update progress every second
  
      setTimeout(() => {
        setLoading(false);
        clearInterval(interval); // Stop updating progress
      }, 3000); // Simulating a 5-second delay
  
      return () => clearInterval(interval);
    }, []);
    const data = {
      title: 'Explore Our Products',
      desc: 'Your description',
      items: [
        { title: "Virtual Machine", url: "/virtualMachine", desc: "Compute" },
        { title: "Optimized Virtual Machine", url: "/optimizedVirtualMachine", desc: "Compute" },
        { title: "GPU Compute Nodes", url: "/GPUcomputeNode", desc: "Compute" },
        { title: "GPU Compute Cluster", url: "/GPUcomputeCluster", desc: "Compute" },
        { title: "Auto Scale Compute Nodes", url: "/autoScaleComputeNodes", desc: "Compute" },
        { title: "Dedicated Private Cloud Services", url: "/dedicatedPrivateCloud", desc: "Private Cloud" },
        { title: "Object Storage", url: "/objectStorage", desc: "Storage" },
        { title: "Block Storage", url: "/blockStorage", desc: "Storage" },
        { title: "File Storage", url: "/fileStorage", desc: "Storage" },
        { title: "Secure Drop", url: "/secureDrop", desc: "Storage" },
        { title: "Private FTPES Storage", url: "/ftpesStorage", desc: "Storage" }
        // Add more items as needed
      ],
    };
    const featureData = {
      "title": "Our Unique Advantages ",
      "desc": "",
      "items": [
          {
              "img": {
                  "url": "images/icons/invite.png",
                  "alt": "User-Friendly Cloud Management Console"
              },
              "title": "User-Friendly Cloud Management Console",
              "desc": "At Trivont, our cloud management console is the epitome of simplicity and efficiency. It's designed for quick and easy deployment of instances, network, and storage, transforming complex cloud operations into user-friendly experiences."
          },
          {
              "img": {
                  "url": "images/icons/solution.png",
                  "alt": "Instance Based Solutions with Advanced Tools"
              },
              "title": "Instance Based Solutions",
              "desc": "We offer budget-friendly solutions crucial for your business, which includes top-tier enterprise-class video-conferencing and sophisticated code repository like GitHub. These tools ensuring you get maximum value without overstretching your budget."
          },
          {
            "img": {
                "url": "images/icons/erp.png",
                "alt": "Customizable ERP Systems for Your Business"
            },
            "title": "Customizable ERP Systems for Your Business",
            "desc": "Our ERP solutions are more than just software; they're a canvas for your creativity. Fully customizable, they adapt to your unique business needs, providing a flexible and robust foundation for your operations."
        },
        {
            "img": {
                "url": "images/icons/configuration.png",
                "alt": "AI and Automated Application Development"
            },
            "title": "AI and Automated Application Development",
            "desc": "Trivont is at the forefront of technological innovation, integrating AI and automation into application development. Our platform doesn't just host your applications; it enhances them, unlocking new efficiencies and capabilities."
        },
        {
            "img": {
                "url": "images/icons/hybrid.png",
                "alt": "Public and Private Cloud Options"
            },
            "title": "Public and Private Cloud Options",
            "desc": "Trivont offers both public and private cloud solutions, providing the flexibility to choose the right environment for your business. Whether you need the scalability of a public cloud or the exclusivity of a private cloud, Trivont has you covered."
        },
        {
            "img": {
                "url": "images/icons/gpu.png",
                "alt": "Superior Performance with NVMe Storage"
            },
            "title": "Superior Performance with NVMe Storage",
            "desc": "Distinguishing ourselves from other vendors, Trivont exclusively uses NVMe storage. This advanced technology guarantees faster data access and superior performance, reducing operational costs and enhancing reliability."
        },
        {
            "img": {
                "url": "images/icons/performance.png",
                "alt": "Performance-Oriented Infrastructure"
            },
            "title": "Performance-Oriented Infrastructure",
            "desc": "Our infrastructure is meticulously designed for peak performance. By optimizing every aspect of our hosting environment, we help reduce your operational costs, translating into direct revenue benefits for your business."
        },
        {
            "img": {
                "url": "images/icons/reliability.png",
                "alt": "Committed Support for Your Cloud Journey"
            },
            "title": "Committed Support for Your Cloud Journey",
            "desc": "At Trivont, we are more than just a service provider; we are your partner in the cloud journey. Our dedicated support team is here to ensure your experience with our cloud services is seamless, efficient, and enjoyable."
        },
        {
            "img": {
                "url": "images/icons/start-up.png",
                "alt": "Special Offers for Startups"
            },
            "title": "Special Offers for Startups",
            "desc": "Understanding the challenges faced by startups, Trivont provides specially tailored offers to help budding businesses thrive. These offers are designed to reduce initial costs, preventing unnecessary financial strain during those crucial early days."
        },
        {
            "img": {
                "url": "images/icons/work.png",
                "alt": "Flexible and Contract-Free Services"
            },
            "title": "Flexible and Contract-Free Services",
            "desc": "We believe in the power of choice. With Trivont, you enjoy the freedom of no long-term contracts, allowing you to adapt and scale your cloud services as your business evolves."
        }
          // Add other items here
      ]
  };
    return (
        <>
       
          <Navbar handler={{handleSearch, handleOffcanvas}}   headerTop="True"    />
            {/* <Banner data={hero1} /> */}
            <CheroSection/>
            <Feature data={featureData} blockHeight="450px"/>
            <Solution data={solution} />
            <Service data={service}  classText="pt-120 mb-lg-0 mb-n4 pb-0 pb-lg-4" styleTwo="true" />
        
            
            <FourBlocks data={WhyTrivont} blockHeight="170px"/>
            <Footer data={footer} />
       
        </>
    )
}

export default Home
