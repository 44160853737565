import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'
import CustomFeature from '../components/customComponents/customBoxes';
import MDB01 from '../components/mongoDbCard';
import './PagesCss.css'
import cassandra from '../components/banner/cassandra.png';


function Cassandra() {

    const context = useContext(DataContext);
    const { footer, CassandraCards } = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const featureData = {
        title: 'Features',
        desc: 'Key Features of Trivonts Cassandra Database Services',
        items: [
            {
              "title": "Distributed Architecture",
              "desc": "Leverage Cassandra's distributed architecture to distribute data across multiple nodes, ensuring high availability and fault tolerance in the face of node failures and network partitions."  },
            {
              "title": "Linear Scalability",
              "desc": "Scale your database infrastructure horizontally to accommodate growing data volumes and user loads, allowing you to add new nodes to the cluster effortlessly without downtime or data migration."  },
            {
              "title": "High Performance",
              "desc":"Experience blazing-fast read and write operations with Cassandra's high-performance architecture, optimized for low-latency data access and real-time analytics."  },
             {
              "title": "Tunable Consistency",
              "desc":"Fine-tune consistency levels to balance data consistency and performance requirements, allowing you to achieve the desired trade-offs between data consistency and system responsiveness."  },
            
              {
                "title": "Flexible Data Model ",
                "desc":"Benefit from Cassandra's flexible data model, supporting structured, semi-structured, and unstructured data formats, making it ideal for a wide range of use cases, including time-series data, IoT applications, and real-time analytics."  },
              
          ]
          ,
      };


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header=" Cassandra"
  content=" Explore the capabilities of Apache Cassandra, a robust open-source distributed database system designed to handle vast amounts of data with ease."
  img={cassandra}
  isRow={true} />       
        


           <CustomFeature data={featureData} box1Height="310px"/>

           
           
            <MDB01 data={CassandraCards} blockHeight="430px"/>



            <BSsection4s
  heading="Harness the Power of Cassandra for Your Data Needs"
  title1="Scale Your Data Infrastructure"
  title2="High Availability Solutions"
  title3="Need Expert Guidance?"
  desc1="Explore our Cassandra solutions to scale your data infrastructure seamlessly. Whether you're managing large datasets or requiring high availability solutions, begin your journey with our robust solutions and enjoy a complimentary $300 credit."
  desc2="Our team of data experts is dedicated to helping you implement the perfect Cassandra setup or connecting you with the right partners to optimize your data management."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>




            <Footer data={footer} />
        </> 
    )
}

export default Cassandra
