import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';
import Columns2s from '../components/TwoColumn';

import Box from '../components/customComponents/box';
import CustomFeature from '../components/customComponents/customBoxes';

import imgData from '../components/banner/dns.png';

const DNS = () => {
  const context = useContext(DataContext);
  const {  footer,DNScolumn } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  
  
 
  const featureData = {
    title: 'Features',
    desc: 'Discover the Features of Our DNS-53 Service',
    items: [
        {
            title: 'Fast Domain Resolution',
            desc: 'Experience lightning-fast domain resolution with our DNS-53 service, ensuring that users are directed to the correct resources with minimal latency.',
          },
          {
            title: 'Reliable Performance',
            desc: 'Enjoy reliable performance and uptime with our robust DNS infrastructure, providing uninterrupted access to your applications and websites for users worldwide.',
          },
          {
            title: 'Secure DNS Infrastructure',
            desc: 'Our DNS-53 service offers robust security features, protecting against DNS-related attacks and ensuring the integrity of your domain name system.',
          },
          {
            title: 'Customizable DNS Policies',
            desc: 'Customize DNS policies to meet your specific requirements, including routing policies, TTL (Time to Live) settings, and DNSSEC (Domain Name System Security Extensions) configurations, ensuring optimal performance and security for your domain.'
             }
        // Add more features as needed
      ]
      ,
  };
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />

  <CustomSection header="DNS-53"
  subtitle=""
  content="Navigate with Confidence, Powered by DNS-53 Fast, Reliable, Secure Your Path to Seamless Domain Resolution"
  isRow={true}
  img={imgData}
  />
  
  <Columns2s data={DNScolumn}/>
 
   <CustomFeature data={featureData} box1Height="360px"/>
   
   

   
   <Footer data={footer}/>
   
  
  </>
  )
}

export default DNS