import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'
import MDB01 from '../components/mongoDbCard';
import BSservices from '../components/BSservices';
import OSservices from '../components/OSservice';
import Box from '../components/customComponents/box';
import { Container } from 'react-bootstrap';
import './PagesCss.css'



function TiDB() {

    const context = useContext(DataContext);
    const {MongoDBCards, MDBservice, footer, TiFeatures } = context.allData
    const { handleSearch, handleOffcanvas } = context;


     
  const TiDbUseCases = [
    {
      title: "Financial Services",
      subtitle: "Manage financial transactions, fraud detection, and risk analytics with TiDB's support for real-time data processing and high availability, ensuring secure and reliable banking and financial services." },
    {
      title: "E-Commerce Platforms",
      subtitle: "Power e-commerce platforms, online marketplaces, and digital storefronts with TiDB's scalable and distributed database solution, enabling seamless transaction processing and personalized customer experiences." },
    {
      title: "Analytics and Business Intelligence",
      subtitle: "Analyze large volumes of data, generate insights, and derive actionable intelligence with TiDB's support for complex analytical queries and real-time data processing, enabling data-driven decision-making and strategy formulation." },
    {
      title: "Content Management Systems",
      subtitle: "TiDB provides a high-performance database solution for managing digital content, multimedia files, and repositories, ensuring efficient content delivery and retrieval for various platforms." }
  ];
  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="TiDB"
  content="Explore how HBase, a distributed, scalable, and consistent NoSQL database, empowers businesses to handle large volumes of sparse data sets with ease and efficiency."/>       
        

        <BSservices data={TiFeatures} blockHeight="340px"/>

           
        
    <Box data={TiDbUseCases} title="Ideal for Various Business Applications"  boxHeight="410px"/>
    



    <BSsection4s
  heading="Ready to Revolutionize Your Database with TiDB?"
  title1="Start Your TiDB Journey"
  title2="Scalable Distributed SQL Solutions"
  title3="Need Expert Guidance?"
  desc1="Discover the power of Trivont's TiDB services. Begin your journey with our scalable distributed SQL solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of database experts is here to help you deploy and optimize TiDB or connect you with the right partner to meet your database requirements."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>



           
            <Footer data={footer} />
        </> 
    )
}

export default TiDB
