import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import Psqlfeatures from '../components/PostgreSqlB1';
import IBox from '../components/KafkaB1';
import BSservices from '../components/BSservices';
import '../pages/PagesCss.css'
import mysql from '../components/banner/mysql.png';


function MySql() {

    const context = useContext(DataContext);
    const { SqlBox, footer, SQLbenefits} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    // const styles = {
    //     helpSection: {
    //       padding: '60px 0px',
    //       backgroundColor: 'blue',
    //       marginBottom:'20px',
    //       marginTop:'50px'
    //     },
    //     blueBg: {
    //       background: '#0d1e67',

    //     },
    //     title: {
    //       color: 'black',
    //       fontWeight: 'bold',
    //       fontSize: '2.441em',
    //       fontFamily: 'TeXGyreAdventor-Bold',
    //       marginBottom:'10px'
    //     },
    //     titleBox: {
    //       display: 'inline-block',
    //       marginRight: '10px',
    //     },
    //     button: {
    //       display: 'inline-block',
    //       padding: '10px 20px',
    //       border: '2px solid #007bfc',
    //       borderRadius: '5px',
    //       fontWeight: 'bold',
    //       textDecoration: 'none',
    //       textTransform: 'uppercase',
    //       color: 'black',
    //       transition: 'all 0.3s ease',
    //     },
    //     buttonHover: {
    //       backgroundColor: '#007bfc',
    //       color: 'black',
    //     },
    //     buttonOutline: {
    //       backgroundColor: 'transparent',
    //     },
    //     '@media screen and (max-width: 768px)': {
    //       buttonBox: {
    //         display: 'block',
    //         marginBottom: '10px',
    //       },
    //       buttonFullWidth: {
    //         display: 'block',
    //         width: '100%',
    //       },
    //       titleBox2: {
    //         marginBottom: '20px',
    //       },
    //       titleBox2H2: {
    //         marginBottom: '20px',
    //       },
    //     },
    //   };
      

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="MySQL"
  content="Streamline Your Data Managemnet Effortlessly"
  isRow={true}
  img={mysql}/>       
  <br/>
        


        <IBox data={SqlBox} columns={4} alignment="top left" boxHeight="450px" />




<BSservices data={SQLbenefits} blockHeight="310px"/>

{/* 
        <section style={styles.helpSection} className="blue-bg iq-help">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="iq-title-box iq-title-default text-left  mb-0 iq-title-white iq-title-box-2">
                            <div className=""></div>
                            <span className="iq-subtitle"> </span>
                            <h2 className="iq-title undefined" style={styles.title}>Let Us Help You Grow</h2>
                            <p className="iq-title-desc undefined"> </p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-right align-self-center">
                        <div className="iq-btn-container d-inline-block mr-4">
                            <a style={styles.button} className="iq-button iq-hover d-inline" href="#">Start Your Free Trial</a>
                        </div>
                        <div className="iq-btn-container d-inline-block">
                            <a style={{ ...styles.button, ...styles.buttonOutline }} className="iq-button iq-btn-outline d-inline" href="#"> See All Plans</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<br/> */}
           

            <Psqlfeatures/> 


           
            <Footer data={footer} />
        </> 
    )
}

export default MySql
