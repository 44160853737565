import React,  { useState, useContext, useEffect }  from 'react';
// import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import axios from 'axios';
// import moment from 'moment';
import {  FaEye, FaEdit, FaTrashAlt, FaFlag, FaTimes, FaLeaf , FaChevronDown } from 'react-icons/fa';
// import { TRUE } from 'node-sass';
import { useHistory } from 'react-router-dom';
import './PagesCss.css'
import { Col, Container, Form, Button, Row, Navbar } from 'react-bootstrap';
import { FiHome } from 'react-icons/fi';
import logo from "../images/banner_dark.svg";




const AdminHome = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData
  const [OpeningsData, setOpeningsData] = useState([])
  const [CareerData, setCareerData] = useState([])
  const [partnerData , setpartnerData] = useState(null)

  const history = useHistory();


  const [editMode, seteditMode] = useState(false)

  const { handleSearch, handleOffcanvas } = context;

//   const today = moment().startOf('day'); ;

//   console.log(today)
useEffect(() => {
  const LoggedIn = sessionStorage.getItem('isLoggedIn');

      if (!LoggedIn) {
        history.push('/login');
      }
    }, [history]);


  // const containerStyle = {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   marginLeft:'150px',
  //   marginRight:'150px',
  //   marginTop:'50px',
  //   marginBottom:'50px',
  //    justifyContent:'center'

  // };

  // const blockStyle = {
  //   backgroundColor: 'white',
  //   width: 'calc(25% - 20px)',
  //   height: 'auto',
  //   margin: '10px',
  //   padding: '20px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'space-between',
  //   alignItems: 'flex-start',
  //   color: 'black',
  //   boxSizing: 'border-box',
  //   borderRadius: '10px',
  //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  //   border: '1px solid #e0e0e0',
  //   // cursor: 'pointer',
  //   position: 'relative' // Added position: relative
  // };

  // const deleteButtonStyle = {
  //   position: 'absolute',
  //   top: '75px',
  //   right: '15px',
  //   margin: 0,
  //   color: 'red',
  //   cursor: 'pointer',
  //   width:'16px',
  //   height:'16px'
  // };

  // const viewButtonStyle = {
  //   position: 'absolute',
  //   top: '20px',
  //   right: '15px',
  //   margin: 0,
  //   color: 'green',
  //   cursor: 'pointer',
  //    width:'18px',
  //    height:'18px'
  // };

  // const EditButtonStyle = {
  //   position: 'absolute',
  //   top: '45px',
  //   right: '13px',
  //   margin: 0,
  //   color: 'green',
  //   cursor: 'pointer',
  //   width:'18px',
  //   height:'18px'
  // };


  useEffect(() => {
    fetchData();
  }, []);

const fetchData= () => {

    axios.get('https://trivont.com/api/openings/view/')
      .then(response => setOpeningsData(response.data))
      .catch(error => console.log(error));


    axios.get('https://trivont.com/api/career/view/')
    .then(response => setCareerData(response.data))
    .catch(error => console.log(error));



  };

  const refreshData =() => {
    fetchData()
  }

  const handleAdminView = (id) =>{


    const data = {

      is_Active: false,
      is_Visible: false,

  };

    axios.put(`https://trivont.com/api/openings/edit/view/${id}/`, data)
        .then(response => {
            // console.log(response);
            if(response.data.status === 'success') {
                alert("Data Successfully Modified")
                refreshData()

            } else {
                alert("An error occurred, please try again later.");
            }
        })
        .catch(error => { 
          console.log(error);
           alert("An error occurred, please try again later.")
          });




  }


  const summaryStyle = {
    paddingLeft: '20px',
    paddingRight:'300px',
    marginTop:'30px',
    marginBottom: '50px',
    // border: '1px solid #e0e0e0',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    paddingTop:'10px',
    paddingBottom:'10px'
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };


  const handleAdminViewEdit = (id) => {
    const selectedOpening = OpeningsData.find(opening => opening.id === id);
    history.push({
      pathname: '/openings',
      state: { selectedOpening } // Pass data as state
    });
  };


  const handelUserDelete = (id) =>{


    const data = {

      is_Active: false,


  };

    axios.put(`https://trivont.com/api/career/edit/active/${id}/`, data)
        .then(response => {
            // console.log(response);
            if(response.data.status === 'success') {
                alert("Data Successfully Deleted!")
                refreshData();

            } else {
                alert("An error occurred, please try again later.");
            }
        })
        .catch(error => { 
          console.log(error); 
          alert("An error occurred, please try again later.")
        });

  }


  const handelSceen = (id) => {



    axios.put(`https://trivont.com/api/career/edit/sceen/${id}/`)
        .then(response => {
            // console.log(response);
            if (response.data.status === 'success') {
                // alert("Data Successfully Modified");
                refreshData()
            } else {
                alert("An error occurred, please try again later.");
            }
        })
        .catch(error => { 
          console.log(error);
           alert("An error occurred, please try again later.")
          });
}



  const handleCareerView = (partner) => {
    seteditMode(true)
    setpartnerData(partner)
  }

  const allOpenings = () => {
    seteditMode(false)
    setpartnerData("")
  }

  const newOpening = () => {
    history.push('/openings')
  }
  return (
  <>
<div className="page-container">
<Navbar className="bg-body-tertiary mt-3">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={logo}
              width="200"
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a href="/">
                <FiHome style={{ fontSize: '24px' }} /> Back to Home
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

<div className="content">
 { !editMode &&
 <>
 <br/>
 <div className="d-flex justify-content-end" style={{marginRight:'30px'}}>
 <button onClick={newOpening} style={{ border:'1px solid black', borderRadius:'5px', padding:'5px'}}>New Opening</button>
  </div>

  <div className="career-container">
    {OpeningsData.filter(partner => partner.is_Visible === true).map((partner, index) => (

       <div key={index} className="career-block-admin" >

<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <h3 style={{ margin: '0 0 15px 0px', display: 'inline-block' }}>{partner.domain}</h3>
    </div>


    <FaEye onClick={() => handleCareerView(partner)}  className='viewButtonStyle'/>
    {/* <button onClick={() => handleAdminView(partner.id)} style={deleteButtonStyle} >Remove</button> */}
    <FaEdit onClick={() => handleAdminViewEdit(partner.id)}  className='EditButtonStyle'/>

    <FaTrashAlt onClick={() => handleAdminView(partner.id)}  className='deleteButtonStyle'/>


       <h4 style={{ margin: '0 0 10px 0', fontWeight: 'bold' }}>{partner.title.split('-')[0].trim()}</h4>


       <p style={{ margin: '0 0 10px 0' }}>Experience : {partner.experience}</p>
       <p style={{ margin: '0 0 10px 0' }}>Location : {partner.location}</p>
       <p style={{ margin: '0 0 10px 0' }}>Number of Applications : {CareerData.filter(partnerCareer => partnerCareer.opening_id === parseInt(partner.id, 10)).length}</p>


       <br/>
{/*
       {lastDateMoment.isBefore(today) && (
  <h5 onClick={() => handleAdminView(partner.id)} style={{ margin: 0, color: 'blue' }}>delete</h5>
)} */}
{/*
{lastDateMoment.isSame(today, 'day') && (
  <h5 onClick={() => handleAdminView(partner.id)} style={{ margin: 0, color: 'green' }}>delete</h5>
)} */}

{/* {lastDateMoment.isAfter(today) && ( */}
{/* )} */}


         </div>
    ))}
  </div>
  <br/>
  </>
}

{ editMode &&
 <>
<br/>
<div className="d-flex justify-content-end" style={{marginRight:'30px'}}>
      <button onClick={allOpenings} style={{ border:'1px solid black', borderRadius:'5px', padding:'5px'}}>View All Openings</button>
      </div>
      <br/>

      <div style={summaryStyle}>
        <div style={{display:'flex', justifyContent:'space-between'}}>
            <p><strong style={{color:'black'}}>Role :</strong> {partnerData.title}</p>
            <p><strong style={{color:'black'}}>Domain :</strong> {partnerData.domain}</p>
            <p><strong style={{color:'black'}}>Experience :</strong> {partnerData.experience}</p>
            </div>
            <p><strong style={{color:'black'}}>Skills : </strong>{partnerData.skills}</p>
          </div>
{/* <div style={{marginBottom:'20px', marginLeft:'20px'}}>
  <h2>Applications</h2>
</div> */}
<table className="table table-striped table-bordered" id='userTaniyangi' style={{borderRight:'none', borderLeft:'none'}}>
                    <thead className="thead-dark" >
                      <tr>

                      <th style={{backgroundColor:'white', color:'black', borderRight:'none', borderLeft:'none'}}>Id</th>
                        <th style={{backgroundColor:'white', color:'black', borderRight:'none', borderLeft:'none'}}>Name</th>
                        <th style={{backgroundColor:'white', color:'black', borderRight:'none', borderLeft:'none'}}>Email</th>
                        <th style={{backgroundColor:'white', color:'black', borderLeft:'none', borderRight:'none'}}>Mobile</th>
                        <th style={{backgroundColor:'white', color:'black', borderLeft:'none', borderRight:'none'}}>File</th>



                      </tr>
                    </thead>
                    <tbody>
                      {CareerData.filter(partner => partner.opening_id === partnerData.id && partner.is_Active === true ).length > 0 ? (
                      CareerData.filter(partner => partner.opening_id === partnerData.id && partner.is_Active === true).map((partner, index) => (
                        <tr key={index}>
                          <td style={{borderRight:'none', borderLeft:'none'}}>{index + 1}</td>
                          <td style={{borderRight:'none', borderLeft:'none'}}>{partner.first_name}</td>
                          <td style={{borderRight:'none', borderLeft:'none'}}>{partner.email}</td>
                          <td style={{borderRight:'none', borderLeft:'none'}}>{partner.mobile}</td>
                          <td style={{ borderRight: 'none', borderLeft: 'none' }}>

                    {partner.file_path && partner.file_path.endsWith('.pdf') ? (
                      <a href={`https://trivont.com/api/media/${partner.file_path}`} target="_blank" rel="noopener noreferrer">View PDF</a>
                    ) : partner.file_path && partner.file_path.match(/\.(jpg|jpeg|png)$/) ? (
                      <img src={`https://trivont.com/api/media/${partner.file_path}`} alt="file" style={{ width: '100px', height: 'auto' }} />
                    ) : (
                      <a href={`https://trivont.com/api/media/${partner.file_path}`} target="_blank" rel="noopener noreferrer">View File</a>
                    //   <a href={`https://trivont.com/media/${partner.file_path}`} download>View File</a>

                    )}

                  </td>

                  <td
  style={{borderRight:'none', borderLeft:'none', width:'30px', cursor:'pointer'}}
  onClick={() => handelSceen(partner.id)}>
  {partner.is_sceen === true ? '🚩' : '⚑' }
</td>

                          <td style={{borderRight:'none', borderLeft:'none', width:'30px'}}><FaTrashAlt onClick={(e) => handelUserDelete(partner.id)} style={{cursor:'pointer'}}/></td>

                        </tr>
                      ))
                    ) : (
                      <p style={{marginLeft:'20px'}}>No Application Recived yet for this role!</p>
                    )}
                    </tbody>
</table>
<br/>
 </>
}
   </div>






    <Footer data={footer} className="footer_career" />
  </div>
  </>
  )
}

export default AdminHome
