import React from 'react'

const IconBox = ({items,title}) => {
  return (
    <section className="wow fadeInUp pt-0 iq-pb-70" data-wow-duration="0.6s">
    <div className="container-fluid p-4">
        <div className="row mb-4">
            <div className="col-lg-12">
                <div className="text-center iq-title-box iq-title-default iq-title-box-2">
                  
                    <h2 className="iq-title">{title} </h2>
                    
                </div>
            </div>
        </div>
        <div className="row p-4 justify-content-center">
            {
                items.map((item, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                        <div className="iq-icon-box iq-icon-box-style-1">
                            <div className="icon-box-img">
                                <img src={item.image} className="img-fluid" alt="TCLOUD" />
                            </div>
                            <div className="icon-box-content">
                                <h3 className="icon-box-title"> {item.title}</h3>
                                <h5 className="icon-box-title">{item.subtitle}</h5>
                                <p style={{
                                    fontSize:'18px'
                                }} className="icon-box-desc">{item.description}</p>
                            </div>
                        </div>
                    </div>

                ))
            }

        </div>
    </div>
</section>

  )
}

export default IconBox