import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import 'bootstrap/dist/css/bootstrap.min.css';
import './customcomponent.css';
import stickyLogo from "../../images/Banner_Trivont_Light.svg";
import backgroundImage from '../../images/BANNERS/icloud.jpeg'
import backgroundImage2 from '../../images/BANNERS/trivontV.jpeg'
const SplashScreen = () => {
  const [virtualizerText, setVirtualizerText] = useState('');
  const [icloudText, setIcloudText] = useState('');
  const virtualizerFullText = 'Trivont Virtualizer';
  const icloudFullText = 'Trivont iCloud';

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setVirtualizerText(virtualizerFullText.substring(0, index + 1));
      index++;
      if (index === virtualizerFullText.length) {
        clearInterval(interval);
      }
    }, 150); // Adjust typing speed here
    return () => clearInterval(interval);
  }, [virtualizerFullText]);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setIcloudText(icloudFullText.substring(0, index + 1));
      index++;
      if (index === icloudFullText.length) {
        clearInterval(interval);
      }
    }, 150); // Adjust typing speed here
    return () => clearInterval(interval);
  }, [icloudFullText]);

  const fadeInHeader = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 10, // Delay the animation for 500 milliseconds to create a sequential effect
    config: { duration: 1000 },
    color:'#fff'
  });

  return (
    <>
    <Navbar  className="bg-body-tertiary SplashScreenNavbar">
    <Container fluid>
      <Navbar.Brand href="/">
        <img
          alt=""
          src={stickyLogo}
          width="400"
          height="50"
          className=" SplashScreenNavbarImg"
        />
      </Navbar.Brand>
      <Navbar.Toggle />
     
    </Container>
  </Navbar>
    <Container fluid>
   
  <Row>
    <Col xs={12} md={6} style={leftStyle}>
    <div style={overlayStyle}></div>
      <div style={contentStyle}>
        <animated.h2 style={fadeInHeader}>{virtualizerText}</animated.h2>
        <p>Looking for a VMware/Hyper-V alternative?</p>
        <a style={{
          backgroundColor:'#2424248c'
        }} href="/tvirtual" className="btn btn-primary">
          Learn More
        </a>
      </div>
    </Col>
    <Col xs={12} md={6} style={rightStyle}>
    <div style={overlayStyle}></div>
      <div style={contentStyle}>
        <animated.h2 style={fadeInHeader}>{icloudText}</animated.h2>
        <p>The Ideal Solution for Cloud Service Providers.</p>
        <a  style={{
          backgroundColor:'#2424248c'
        }} href="/trivonticloud" className="btn btn-primary">
          Learn More
        </a>
      </div>
    </Col>
  </Row>
</Container>
<footer className=" text-dark py-3 mt-auto SplashScreenFooter">
      <Container className='p-0'>
        <Row className='justify-content-center'>
          <Col className='d-flex align-items-center justify-content-center text-center' xs={12} md={6}>
            
          <p style={{
            color:'#fff'
          }}>
        © {new Date().getFullYear()} Trivont. All rights reserved.
        <br />
    
        
      </p>
            
          </Col>
          
        </Row>
      </Container>
    </footer>
</>
  );
};

const leftStyle = {
  backgroundColor: '#186bfa',
  height: '100vh', 
  color:'#fff',
  backgroundImage: `url(${ backgroundImage2})`,
  position: 'relative', // Ensure the text is above the overlay
  zIndex: 2,
  backgroundSize: 'cover', // Ensure the image covers the whole area
  backgroundPosition: 'center', // Center the image
  backgroundRepeat: 'no-repeat' // Prevent the image from repeating
};
const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
  zIndex: 1, // Ensure the overlay is on top of the background image
  
};
const rightStyle = {
  backgroundColor: '#186bfaf7', 
  height: '100vh', 
  color: '#fff',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover', // Ensure the image covers the whole area
  backgroundPosition: 'center', // Center the image
  backgroundRepeat: 'no-repeat' // Prevent the image from repeating
};

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%', // Full height of the parent container
  padding: '20px',
  position: 'relative', // Ensure the text is above the overlay
  zIndex: 2,

  
};

export default SplashScreen;
