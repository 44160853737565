import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

const TabsComponent = ({ title, subtitle, defaultActiveKey, tabs }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  const handleTabClick = (id) => {
    setActiveKey(id);
  };

  return (
    <section style={{ marginTop: '50px', marginBottom: '20px' }}>
      <Container>
        <div>
          <div>
            <h2 className='text-center' style={{ marginBottom: '12px' }}>{title}</h2>
            <p className='text-center' style={{ marginBottom: '53px' }}>{subtitle}</p>
          </div>
          <Tab.Container className='mb-2' id="list-group-tabs-example" activeKey={activeKey} onSelect={setActiveKey}>
            <Row>
              <Col sm={4}>
                <div className="side-navigation-card">
                  <ListGroup>
                    {tabs.map((tab) => (
                      <ListGroup.Item key={tab.id} onClick={() => handleTabClick(tab.id)} active={activeKey === tab.id}>
                        {tab.label}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {tabs.map((tab) => (
                    <Tab.Pane key={tab.id} eventKey={tab.id}>
                      <p>{tab.content}</p>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </section>
  );
};

export default TabsComponent;
