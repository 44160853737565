import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import dimageone from '../images/icons/dba1.png' ;
import dimagetwo from '../images/icons/mongo.png';
import dimage3 from '../images/icons/orcale.png';
import dimaget4 from '../images/icons/data-strategy.png';
import dimaget5 from '../images/icons/database_2383158.png';
import dimaget6 from '../images/icons/opti.png';
import db from '../images/BANNERS/datasaservice.jpg';

const Dbass = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData

  const { handleSearch, handleOffcanvas } = context;
  
  const databaseManagementData = [
    {
      title: "Optimization and Fine-Tuning Expertise",
      subtitle: "Enhance Efficiency with Experienced Database Administration",
     image:dimaget6,
      description: "Leverage our extensive database administration knowledge to optimize and fine-tune your databases, boosting efficiencies and maximizing performance."
    },
    {
      title: "Skilled DBA Resources for Enhanced Productivity",
      subtitle: "Boost Efficiency with Competent Database Management",
     image:dimageone,
      description: "Ensure optimal database management with skilled DBA resources. Rather than overstaffing, rely on experienced professionals to enhance productivity and cut costs."
    },
    {
      title: "Strategic Data Planning and Deployment",
      subtitle: "Efficient Management Through Strategic Deployment",
      image:dimaget4,
      description: "Experience the benefits of effective database planning and deployment with specialized technologies. Our strategic approach leads to more efficient management, reduced efforts, and cost savings."
    },
    {
      title: "Efficient Database Administration with Oracle DB",
      subtitle: "Streamline Operations with Onsite and Offsite Solutions",
      image: dimaget5,
      description: "Simplify your company's system maintenance with our Oracle DB onsite and offshore database administration services. Reduce personnel requirements and lower expenses while ensuring smooth operations."
      },
    {
      title: "MongoDB Cluster Management for Scalable Solutions",
      subtitle: "Seamless Deployment and Management of MongoDB Clusters",
      image:dimagetwo,
      description: "Experience scalable database solutions with our MongoDB cluster management services. Ensure seamless deployment and efficient management for your MongoDB clusters, enabling flexibility and reliability."
    },
    {
      title: "Oracle GoldenGate Data Replication",
      subtitle: "Efficiently Transfer and Transform Data Across Databases",
      image: dimage3,
      description: " Our expertly crafted solution ensures efficient transfer and transformation of data, enabling your organization to maintain data integrity and consistency across databases."
    }
    
  ];
  
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
  {/* <Banner2 data={ hero2Data}/> */}
  
  <BannerSection
        title="Database As A Service"
        backgroundColor="#007bff"
        color="#FFF5EE"
        height="50vh"
        backgroundImage={db}
        customBackgroundPosition='center'
        customBackgroundSize='150ch'
      />
      

<SliderHeroSection backgroundImage='#efefef'  title="Automation & AI" subtitle="Simplify your IT operations" 
  textColor='#242424' isDefault={true} featureTitle='Optimize Database Management with Trivont Cloud'
   featureSubtitle='We offer dedicated primary and secondary DBAs for both onsite and remote database administration. Our remote DBA service operates round-the-clock, ensuring rapid response times wherever and whenever you require assistance. ' >
    
  </SliderHeroSection>
 
  <MigrationJumbotron title='Comprehensive Database Support for Business Continuity' description='Efficient Disaster Recovery and Database Management Solutions' />  
  

   <IconBox items={databaseManagementData}/> 

    
<BSsection4s
heading="Ready to take advantage of our DBaaS offerings?"
title1="Embark on Your DBaaS Journey"
title2="Unlock the Potential"
title3="In Need of Guidance?"
desc1="If you're new to DBaaS, begin your journey with our range of managed database solutions, and enjoy a complimentary $300 credit."
desc2="Our experts are available to help you tailor the perfect DBaaS solution or connect you with the ideal partner for your needs."
buttonText1="Start Exploring"
buttonText2="Get in Touch with Our Experts"
/>


    <Footer data={footer} />
  
  </>
  )
}

export default Dbass