import React, { useState, useEffect } from "react";
import Loading from "./Loading";
import Title from "./Title";
import { Container, Row, Col } from "react-bootstrap";

function FourBlocks({ data, classText, styleTwo, styleThree, blockHeight }) {
  
  // Use hooks unconditionally at the top level of the component
  const [blockHeight1, setBlockHeight] = useState(blockHeight); // Default block height

  useEffect(() => {
    const updateBlockHeight = () => {
      if (window.innerWidth <= 767) { 
        setBlockHeight(blockHeight + '100px'); // Adjusted block height for mobile devices
      } else {
        setBlockHeight(blockHeight); // Default block height for larger screens
      }
    };

    updateBlockHeight();
    window.addEventListener('resize', updateBlockHeight);

    return () => {
      window.removeEventListener('resize', updateBlockHeight);
    };
  }, [blockHeight]); // Add blockHeight as a dependency to useEffect



  
  if (!data) {
    return <Loading />;
  }

  return (
    <section className={`${classText} BSservice`}>
      <Container>
        <Row>
          <Col>
            <Title
              subtitle={data.desc}
              title={data.title}
              classText={`${
                styleTwo
                  ? "title-shape title-shape-sky-blue text-center"
                  : styleThree
                  ? "title-shape title-shape-c2 title-shape-style-three text-center"
                  : "title-shape text-center"
              }`}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          {data.items.map((item, index) => {
            let active = index === 1 ? "active" : "";
            return (
              <Col key={index} lg={6} md={6} className="mb-4">
                <div
                  className={`single-services  ${
                    styleTwo
                      ? "style--two"
                      : styleThree
                      ? `style--three ${active}`
                      : ""
                  }`}
                  onMouseOver={(e) => {
                    e.currentTarget.querySelector("h3").style.color = "black";
                    e.currentTarget.querySelector("p").style.color = "black";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.querySelector("h3").style.color = ""; 
                    e.currentTarget.querySelector("p").style.color = ""; 
                  }}
                  style={{ 
                     height: blockHeight1,
                     width: "100%" ,  
                     border: "1px solid #ccc", 
                      backgroundColor: "#f0f8ff",
                       padding: "30px",
                       boxShadow: "none",
                       borderRadius:'10px' ,
                       textAlign:'center',
                       marginBottom:'30px',
                }}
                >
                  <div className="service-contents">
                    <h3 style={{marginBottom:'15px'}}>{item.title}</h3>
                    <p>{item.desc}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default FourBlocks;

FourBlocks.defaultProps = {
  classText: "pt-120 pb-90",
};
