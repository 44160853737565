import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Title from '../components/Title';
import './customComponents/customcomponent.css'

const TwoColumnBlock = ({ heading, subHeading, title1, title2, title3, desc1, desc2, buttonText1, title21, title31, desc11, desc21, content1 ,showButton}) => {
  return (
    <section className="TwoColumns__section"> {/* Apply section class */}
      <Container>
        <Row>
          <Col>
            <Title title={heading} subtitle={subHeading} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="mb-4 mb-md-0">
            <div className="TwoColumns__column-content"> {/* Apply column-content class */}
              <h1>{title1}</h1>
              <p>{content1}</p>
              <br />
              {showButton ?  null: <Button>{buttonText1}</Button>}
            </div>
          </Col>
          <Col xs={12} md={3} className="mb-4 mb-md-0 d-flex flex-column justify-content-center align-items-center">
            <div className="TwoColumns__column-card"> {/* Apply column-card class */}
              <h3>{title2}</h3>
              <p>{desc1}</p>
            </div>
            <div className="TwoColumns__column-card"> {/* Apply column-card class */}
              <h3>{title21}</h3>
              <p>{desc11}</p>
            </div>
          </Col>
          <Col xs={12} md={3} className="mb-4 mb-md-0 d-flex flex-column justify-content-center align-items-center    ">
            <div className="TwoColumns__column-card"> {/* Apply column-card class */}
              <h3>{title3}</h3>
              <p>{desc2}</p>
            </div>
            <div className="TwoColumns__column-card"> {/* Apply column-card class */}
              <h3>{title31}</h3>
              <p>{desc21}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TwoColumnBlock;
