import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Title from '../Title';

const ResponsiveColumns = ({title = 'Ready to embark on your cloud computing adventure?'}) => {
  return (
     <Container style={{
        marginBottom:'100px'
     }}>
          <Row>
                    <Col>
                        <Title title="" subtitle="" />
                        
                    </Col>
                </Row>
      <Row>
        <Col xs={12} md={4} className="mb-4 mb-md-0">
          {/* Content for Column 1 */}
          <div className="column-content">
           <h1>
           Begin Your Cloud Journey
           </h1>
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-4 mb-md-0">
          {/* Content for Column 2 */}
          <div className="column-content">
            <h3>{title}</h3>
            <p>If you're new to Trivont, kickstart your journey with our range of cloud products, and enjoy a complimentary $300 credit.</p>
            <button className="normal-button">
               Start For Free
            </button>
            
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-4 mb-md-0">
          {/* Content for Column 3 */}
          <div className="column-content">
            <h3>Seeking Guidance?</h3>
            <p>Our experts are here to assist you in crafting the perfect solution or connecting you with the right partner for your requirements.</p>
            <button className="outlined-button">
              Contact Our Sales Team
             </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResponsiveColumns;
