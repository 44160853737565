import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';
import cloudVPN from '../components/banner/cloudVPN.png'
import Box from '../components/customComponents/box';
import CustomFeature from '../components/customComponents/customBoxes';
import FAQList from '../components/customComponents/faq';
import BSsection4s from '../components/BSsection4';



const CloudVPN = () => {
  const context = useContext(DataContext);
  const {  footer } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  
  
 
  const featureData = {
    title: 'What you get when you run our VPN',
    desc: '',
    items: [
        { title: 'Total Privacy', desc: 'prevent third parties from being able to log your traffic ' },
        { title: 'Faster Speeds', desc: "Accelerate your connection speed by using a CPU exclusively available for you" },
        { title: 'Affordable Pricving', desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry' }
      
      ]
      ,
  };
  const faqList = [
    {
      title: "What is Cloud VPN?",
      desc: "Cloud VPN, or Virtual Private Network, is a secure connection that allows users to access and communicate with resources within a cloud environment over the internet, ensuring data privacy and security."
    },
    {
      title: "How does Cloud VPN work?",
      desc: "Cloud VPN works by creating a secure tunnel between the user's device and the cloud resources. This tunnel encrypts the data, providing a private and secure communication channel over the public internet."
    },
    {
      title: "Why is Cloud VPN important?",
      desc: "Cloud VPN is crucial for ensuring a secure connection between users and cloud resources. It helps protect sensitive data from unauthorized access, providing a safe environment for data transmission and communication."
    },
    {
      title: "What types of security features does Cloud VPN provide?",
      desc: "Cloud VPN typically offers encryption, authentication, and authorization features. These ensure that data is transmitted securely, and only authorized users have access to the cloud resources."
    },
    {
      title: "Is Cloud VPN suitable for businesses of all sizes?",
      desc: "Yes, Cloud VPN is scalable and can be adapted to the needs of businesses of all sizes. It provides a flexible and secure solution for remote access to cloud resources, making it suitable for various organizations."
    }
  ];
  

  const CloudVPNusecases = [
    {
      "title": "IoT and Edge Computing",
      "subtitle": "Secure IoT devices, edge computing nodes, and distributed endpoints with VPN connectivity, protecting data in transit and enabling secure communication in decentralized environments."
    },
    {
      "title": "Partner Collaboration",
      "subtitle":"Foster secure collaboration and data sharing with external partners, suppliers, and vendors by establishing VPN tunnels for secure communication and information exchange."
    },
    {
      "title": "Cloud Migration",
      "subtitle": "Facilitate seamless migration to the cloud by establishing secure VPN connections between on-premises data centers and cloud environments, ensuring smooth transition and data transfer."
    },
    {
      "title": "Remote Workforce",
      "subtitle": "Enable secure remote access for telecommuters, remote workers, and mobile employees, allowing them to connect to corporate resources and applications from any location securely."
    }
    
  ]

  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  <CustomSection header="Cloud VPN"
  subtitle=""
  content="Gain command over your privacy with a swift, dependable and user-friendly VPN setup in less than two minutes."
  img={cloudVPN}
  isRow={true}/>   
  
 
   <CustomFeature data={featureData} box1Height="200px"/>
   
   <Box data={CloudVPNusecases} title=" Use Cases" subtitle="Ideal for Various Business Scenarios" boxHeight="350px"/>

   <FAQList faqData={faqList}/>

   <BSsection4s
  heading="Secure Your Network with Cloud VPN"
  title1="Start Your VPN Journey"
  title2="Enhance Your Security"
  title3="Need Expert Guidance?"
  desc1="Discover the power of Cloud VPN to secure your network connections. Whether you're setting up a remote workforce or safeguarding critical data, start your VPN journey with our robust solutions and enjoy a complimentary $300 credit."
  desc2="Our team of cybersecurity experts is dedicated to helping you implement the perfect VPN solution or connecting you with the right partners to enhance your network security."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>

   <Footer data={footer}/>
   
  
  </>
  )
}

export default CloudVPN