
import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';

import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import AnimatedCardGrid from '../components/customComponents/animatedGrid';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import sevr from '../images/BANNERS/sd.jpg';
import sof1 from '../images/icons/solution.png';
import sof2 from '../images/icons/maintenance_8176853.png';

import sof4 from '../images/icons/coding.png';
import sof5 from '../images/icons/mobile-development.png'; 

import sof7 from '../images/icons/cloud-application.png';
const SoftwareDevelopment = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData

  const { handleSearch, handleOffcanvas } = context;
  
  const enterpriseManagementData = [
    {
      "title": "Software Consultation",
      "subtitle": "Expert Guidance for Optimal IT Solutions",
      "image": sof1,
      "description": "Get technology and business consultation from our tech experts to make informed decisions and maximize the value of your IT investments."
    },
      {
        title: "Reliable Application Maintenance and Support",
        subtitle: "Keep Your Systems Running Smoothly",
       image:sof2,
        description: "Ensure the uninterrupted operation of your applications with our comprehensive maintenance and support services. From bug fixes to performance optimizations, our expert team is here to provide timely assistance and keep your systems running smoothly."
        },
      
       
{
  title: " Web Development and Hosting Solutions",
  subtitle: "Crafting Your Online Presence with Excellence",
  image: sof4,
  description: "From designing and developing your website to providing reliable hosting solutions, our comprehensive web development and hosting services empower you to establish a strong online presence. We tailor our solutions to your unique needs, ensuring that your website delivers a seamless experience to your audience."
  },
  {
    "title": "Mobile Development",
    "subtitle": "Innovative Mobile Solutions for Maximum Engagement",
    "image": sof5,
    "description": "Connect with your audience wherever they are with custom mobile applications for iOS and Android. Our mobile development services enable you to engage users on their smartphones and tablets."
  },
      
      
{
  title: "Accelerate Your Business with Application Development",
  subtitle: "Custom Solutions Tailored to Your Needs",
  image: sof7,
  description: "Drive innovation and growth with our custom application development services. We specialize in crafting scalable and efficient solutions to meet your unique business requirements, helping you stay ahead in today's competitive landscape."
  }
  ];
  
  
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
  {/* <Banner2 data={ hero2Data}/> */}
  
  <BannerSection
        title="Software Development"
        backgroundColor="#007bff"
        color="#fff"
        height="50vh"
        backgroundImage={sevr}
      />
      

<SliderHeroSection backgroundImage='#efefef'  title="Automation & AI" subtitle="Simplify your IT operations" 
  textColor='#242424' isDefault={true}  featureTitle="Empower Your Business with Custom Software Solutions"
  featureSubtitle="Stay ahead of the competition with custom software solutions designed to meet the specific needs and goals of your business."   > 
  </SliderHeroSection>
 
  <MigrationJumbotron title="Unlock Your Business Potential with Tailored Software Solutions"
  description="Contact us today to discuss your project requirements and get started on building custom software solutions tailored to your business needs." />  
  

   <IconBox items={ enterpriseManagementData}/> 

    



    <Footer data={footer} />
  
  </>
  )
}

export default SoftwareDevelopment