import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/customComponents/customSection';
import BSsection4s from '../components/BSsection4';
import TabsComponent from '../components/sideNavigation';

// import BSservices from '../components/BSservices';
import Columns2s from '../components/TwoColumn';
// import Box from '../components/customComponents/box';
// import marketing from '../components/banner/marketing.png'

import update from '../images/update.png'
import trivontcloud from '../components/banner/trivontCloud.png'

import './PagesCss.css'
import { Container } from 'react-bootstrap';



function TrivontCloud() {

    const context = useContext(DataContext);
    const { footer,ColumnCloud} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    
    // const DMbenefits = [
    //     {
    //       "title": "Increased Brand Visibility",
    //       "subtitle": "Expand your brand's online footprint and reach new audiences with targeted digital marketing campaigns that resonate with your target market and amplify your brand message across multiple channels."
    //     },
    //     {
    //       "title": "Higher Conversion Rates",
    //       "subtitle":"Drive qualified leads and conversions with strategic digital marketing tactics that leverage data-driven insights, audience segmentation, and personalized messaging to guide prospects through the buyer's journey and inspire action."
    //     },
    //     {
    //       "title": "Improved Customer Engagement",
    //       "subtitle": "Foster meaningful relationships and brand loyalty with engaging content, interactive experiences, and community-building initiatives that invite audience participation, feedback, and advocacy across digital touchpoints."
    //     },
    //     {
    //       "title": "Measurable Results",
    //       "subtitle": "Gain valuable insights and analytics into your digital marketing performance with comprehensive reporting and analytics dashboards that track key metrics, campaign performance, and ROI, enabling data-driven decision-making and continuous optimization."
    //     },
    //     {
    //       "title": "Competitive Advantage",
    //       "subtitle": "Stay ahead of the competition and adapt to evolving market trends with agile digital marketing strategies that leverage emerging technologies, consumer insights, and industry best practices to drive innovation and differentiation in the marketplace."
    //     },
    //     {
    //       "title": "Real-Time Engagement",
    //       "subtitle": "Engage with your audience in real-time through digital marketing channels such as social media, live chat, and interactive content. Respond promptly to inquiries, comments, and feedback, building trust and rapport with your audience and fostering stronger customer relationships."
    //     },
    //     {
    //         "title":"Data-Driven Decision Making",
    //         "subtitle":"Utilize data and analytics for enhanced decision-making in your digital marketing endeavors. Keep an eye on KPIs, trends, and customer behavior to fine-tune and optimize your marketing strategies continuously."
    //     }
        
    //   ]

  
    // const tabsData = [
    //     { id: 'link1', label: 'Configuration Management and Orchestration:', content: 'Taniyangi comes packed with the capabilities to perform configuration management similar to Ansible and orchestration similar to Terraform. This means you can not only manage your system configurations efficiently but also automate complex deployment processes with ease.' },
    //     { id: 'link2', label: 'Audit Compliance & Security:', content: 'We understand the importance of compliance and security in your operations. With Taniyangi, you can rest assured that all your automation tasks are audit compliant, giving you peace of mind and ensuring your operations meet industry standards.' },
    //     { id: 'link3', label: 'Versatility in Deployment:', content: 'Whether you have an on-premise, cloud, or hybrid environment, Taniyangi seamlessly adapts to your setup. Embrace the flexibility to manage your systems regardless of the infrastructure.' },
    //     { id: 'link4', label: 'Cloud Automation:', content: 'Leveraging the power of Taniyangi, you can effortlessly automate cloud-related tasks on leading platforms such as AWS, Azure, and Oracle Cloud. Say goodbye to manual cloud management and embrace the efficiency of automation.'},
    //     { id: 'link5', label: 'Built-in AI Functionality:', content: 'Taniyangi incorporates advanced AI capabilities that empower you to create automation tasks in a matter of seconds. This powerful feature not only saves time but also enhances productivity in your IT operations.'},
    //     { id: 'link6', label: 'Intelligent Event-Based Decision Making:', content: 'One of the standout features of Taniyangi is its powerful AI capabilities that enable event-based decision making during automation processes. Taniyangi can analyze real-time data and detect known issues or potential bottlenecks that might arise during the automation workflow.'},
    //     { id: 'link6', label: 'Minimal Footprint:', content: 'We understand the importance of resource efficiency. Taniyangi has been optimized to require a minimal footprint, ensuring it doesnt burden your systems while delivering top-notch performance.'},
    //     { id: 'link6', label: 'Extensive System Compatibility:', content: 'Taniyangi is designed to work seamlessly with a wide range of systems, including RHEL, Ubuntu, Suse, Solaris, IBM AIX, VMware, Kubernetes, and Windows 2012 and above versions. Whatever your environment, Taniyangi has got you covered.'},
    //     { id: 'link6', label: 'Integration Friendly - Change Request Automation:', content: 'Taniyangi takes integration to the next level by offering Change Request automation. With this feature, you can seamlessly generate Change Requests within your integrated IT Service Management (ITSM) tools like BMC Remedy and ServiceNow directly from the Taniyangi platform.'},
    //     { id: 'link6', label: ' Self-Service Portal Integration for Self-Provisioning:', content: 'Taniyangi opens up new possibilities for customers by offering seamless integration with their Self-Service Portals. With this powerful feature, end-users can initiate self-provisioning of resources and services with just a few clicks, without requiring direct involvement from IT personnel.' }

    // ];
     

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="Trivont Cloud"
  content="TRIVONT Cloud computing is the on-demand availability of computer system resources, especially data storage and computing power, without direct active management by the user"
 cImage={trivontcloud}
  />       


  <div>
    <Container>
    <h2>Cloud Operations Management</h2>
    <br/><br/>
    <p>Organizations investing in cloud-based infrastructure and services face a significant challenge in managing and maintaining systems and applications based in one or more seemingly managed data centers, often with restricted physical access and distributed IT services. <br/><br/>

We collaborate with leading cloud technology providers to integrate their solutions with common monitoring and operations tools such as Microsoft Operations Centre and ServiceNow. Furthermore, our Cloud Operations solution leverages our expertise in ITIL-based operations management to bring the full capabilities of IT Service Management to your cloud-based services. <br/><br/>

We can assist in monitoring and maintaining your organization's cloud-based IT services using the latest in systems monitoring and management frameworks, runbook automation, and service management metrics and utilization reporting. Organizations that proactively manage their internal and cloud-based services within an integrated service management solution report reduced downtime and end-user problems, decreased IT costs and support overhead, and faster response times to business initiatives and change requests.<br/><br/>

We have played a crucial role in conceptualizing and implementing integrated applications and environmental operations. With significant experience in managing operations for global organizations through the right combination of offshore, nearshore, and onsite delivery models, we are today a dominant force in the Remote Infrastructure industry and are recognized as a clear leader due to the maturity of our services and strengths.<br/><br/>

We understand the challenge and criticality of managing business applications and infrastructure within a cloud environment and how companies can maximize the benefits of high-quality SLAs, secure real-time operations, and minimal disruptions. We have made substantial investments in building intellectual capital in this area and have a robust and powerful service delivery platform.<br/><br/> </p>
  
</Container>
  </div>

<br/>


<Columns2s data={ColumnCloud}/>






            <Footer data={footer} />
        </> 
    )
}

export default TrivontCloud
