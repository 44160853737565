import React, { useContext } from 'react';
import Navbar from '../components/Navbar';
import { DataContext } from '../context';
import Footer from '../components/Footer';

import BannerSectionCareer from '../components/customComponents/bannerSectionCareer';
import { useLocation } from 'react-router-dom';
import ContactUs from '../images/BANNERS/contact.webp';

const ResponseEmailpage = () => {
  const context = useContext(DataContext);
  const { footer } = context.allData;
  const { handleSearch, handleOffcanvas } = context;

  const location = useLocation();
  const { first_name, last_name, email, telephone, companyName, companyType } = location.state || {};


  return (
    <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
 
 
  
  <BannerSectionCareer title={`Hello, ${first_name} ${last_name}`} backgroundColor="#007bff" backgroundImage={ContactUs} color="#fff" height="600px" customBackgroundSize='cover' />



  <div className="container mt-5" style={{marginTop:'50px',marginBottom:'30px'}}>
        <h2 className="text-center">Thank You for Your inquiry!</h2>
        <p className="text-center mt-3">
        We appreciate your inquiry and will get back to you shortly.        </p>
        <p className="text-center mt-3">
        Here are the details you provided:        </p>
        
        <div className="text-center mt-4">
          <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '10px' }}>
            <div><strong>Company Name:</strong> {companyName}</div>
            <div><strong>Company Type:</strong> {companyType}</div>
            <div><strong>Email:</strong> {email}</div>
            <div><strong>Telephone:</strong> {telephone}</div>
          </div>
        </div>

        <p className="text-center mt-4">
        Thank you for reaching out to us. We look forward to connecting with you!        </p>


      </div>

      <div style={{display:'flex',justifyContent:'center'}}>
        <a href='/' style={{marginBottom:'50px', color:'blue', cursor:'pointer'}}>
      Back --{'>'} 
      
      </a></div>

  




    <Footer data={footer} />
  
  </>
  )
}

export default ResponseEmailpage