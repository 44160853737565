import React, { useContext } from 'react';
import { DataContext } from './context';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-modal-video/css/modal-video.min.css';
import './App.css';
import {Switch, Route} from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import ComingSoon from './pages/ComingSoon';
import Services from './pages/Services';
import SingleService from './pages/SingleService';
import Blog from './pages/Blog';
import SingleBlog from './pages/SingleBlog';
import Contact from './pages/Contact';
import Error from './pages/Error';
import Search from './components/Search';
import Offcanvas from './components/Offcanvas';
import BackToTop from './components/BackToTop';
import VirtualMachine from './pages/virtualmachine';
import OptimizedVirtualMachine from './pages/optimizedVirtualMachine';
import GPUcomputeNode from './pages/gpucomputenodes';
import GPUcomputeCluster from './pages/gpucomputeCluster';
import VirtualPrivate from './pages/vpc';
import CloudFireWall from './pages/cloudFirewall';
import LoadBalancer from './pages/loadBalancer';
import DNS from './pages/dns';
import DDOS from './pages/ddos';
import CloudVPN from './pages/cloudVPN';
import CloudMigration from './pages/cloudmigration';
import CloudDR from './pages/cloudDR';
import CloudDprivateCloud from './pages/clouddpc';


import ObjectStorage from './pages/objectStorage';
import BlockStorage from './pages/blockStorage';
import FileStorage from './pages/fileStorage';
import SecureDrop from './pages/secureDrop';
import FtpesStorage from './pages/ftpesStorage';
import MongoDB from './pages/mongoDB';
import Kafka from './pages/Kafka';
import MySql from './pages/mySql';
import PostgreSQL from './pages/PostgreSql';
import ApplicationHosting from './pages/applicationHosting';
import UpTimeApp from './pages/upTimeApplication';
import WooCommerce from './pages/wooCommerce';
import StaticWebsiteHosting from './pages/staticWesiteHosting';
import SmallBusinessEmail from './pages/smallBusinessEmail';
import CorporateCommunication from './pages/corporateCommunication';
import MessagingService from './pages/messagingService';
import AutoScaleCompute from './pages/autoScaleComputeNodes';
import Cassandra from './pages/cassandra';
import HBase from './pages/hbase';
import Cockroach from './pages/cockroachDB';
import TiDB from './pages/tiDB';
import Pricing from './pages/pricing';
import AutomationAI from './pages/Automation AI';
import TrivontCloud from './pages/trivontCloud';

import WebsiteHosting from './pages/websiteHosting';
import Kubernate from './pages/kubernates';
import ContactUs from './pages/contactUs';
import Dbass from './pages/dbaas';
import GpuCloud from './pages/gpuCloud';
import CyberSecurity from './pages/cyberSecurity';
import CloudServices from './pages/cloudServices';
import unixServer from './pages/unixServer';
import UnixServer from './pages/unixServer';
import IaaS from './pages/iaas';
import DisasterRecovery from './pages/diasterbackup';
import SoftwareDevelopment from './pages/softwaredev';
import DataAI from './pages/dataAI';
import HPC from './pages/hpc';
import ITAutoOrcH from './pages/itautomation';
import EmailService from './pages/emailservice';

import TrivontIcloud from './pages/trivontIcloud';
import TrivontVirtualizer from './pages/trivontVirtualizer';
import SplashScreenPage from './pages/splashScreen';

import Login from './pages/login';
import career from './pages/career'
import CareerReg from './pages/careerReg';
import Openings from './pages/openings';
import AdminHome from './pages/adminJobs';
import ResponsePage from './pages/responsePage';
import ResponseEmailpage from './pages/responseEmailPage';


function App() {

  const context = useContext( DataContext );
  const { search, offcanvas, handleSearch, handleOffcanvas } = context;
  const offcanvasData = context.allData.offcanvas;

  return (
    <>
      {search ? <Search search={handleSearch} /> : ''}
      {offcanvas ? <Offcanvas data={offcanvasData} offcanvas={handleOffcanvas} /> : ''}

      <BackToTop />

      <Switch>
        <Route exact path="/" component={Home} />
       
        <Route path="/about" component={About} />
        <Route path="/coming-soon" component={ComingSoon} />
        <Route path="/service" component={Services} />
        <Route path="/single-service" component={SingleService} />
        <Route path="/blog" component={Blog} />
        <Route path="/single-blog" component={SingleBlog} />
        <Route path="/contact" component={Contact} />
        <Route path="/virtualMachine" component ={VirtualMachine} />
        <Route path="/optimizedVirtualMachine" component={OptimizedVirtualMachine}/>
        <Route path="/GPUcomputeNode" component={GPUcomputeNode}/>
        <Route path="/GPUcomputeCluster" component={GPUcomputeCluster}/>
        <Route path="/vpc" component={VirtualPrivate}/>
        <Route path="/VirtualFirewalls" component={CloudFireWall}/>
        <Route path="/loadBalancer" component={LoadBalancer}/>
        <Route path ="/DNS" component={DNS}/>
        <Route path="/DDOS" component={DDOS}/>
        <Route path="/cloudVPN" component={CloudVPN}/>
        <Route path="/cloudMigration" component={CloudMigration}/>
        <Route path="/cloudDR" component={CloudDR}/>
        <Route path="/dedicatedPrivateCloud" component={CloudDprivateCloud}/>
    
        <Route path="/objectStorage" component={ObjectStorage}/>
        <Route path="/blockStorage" component={BlockStorage}/>
        <Route path="/fileStorage" component={FileStorage}/>
        <Route path="/secureDrop" component={SecureDrop}/>
        <Route path="/ftpesStorage" component={FtpesStorage}/>
        <Route path="/mongoDB" component={MongoDB}/>
        <Route path="/Kafka" component={Kafka}/>
        <Route path="/MySql" component={MySql}/>
        <Route path="/PostgreSql" component={PostgreSQL}/>
        <Route path="/applicationHosting" component={ApplicationHosting}/>
        <Route path="/upTimeApplication" component={UpTimeApp}/>
        <Route path="/wooCommerce" component={WooCommerce}/>
        <Route path="/staticWesiteHosting" component={StaticWebsiteHosting}/>
        <Route path="/smallBusinessEmail" component={SmallBusinessEmail}/>
        <Route path="/corporateCommunication" component={CorporateCommunication}/>
        <Route path="/messagingService" component={MessagingService}/>
       
      
       
        <Route path="/autoScaleComputeNodes" component={AutoScaleCompute}/>
        <Route path="/cassandra" component={Cassandra}/>
        <Route path="/hbase" component={HBase}/>
        <Route path="/cockroachDB" component={Cockroach}/>
        <Route path="/tiDB" component={TiDB}/>
        
        <Route path="/Pricing" component={Pricing}/>
        <Route path="/Automation" component={AutomationAI}/>
        <Route path="/TrivontCloud" component={TrivontCloud}/>

        
        <Route path="/websiteHosting" component={WebsiteHosting}/>
       
        <Route path="/kubernates" component={Kubernate}/>
        <Route path ="/contactus" component={ContactUs}/>
        <Route path ='/dbaas' component={Dbass}/>
        <Route path = '/gpuCloud' component={GpuCloud} />
        <Route path = '/cybersecurity' component ={CyberSecurity} />
        <Route path = '/cloudServices' component ={CloudServices}/>
        <Route path ='/unix' component={UnixServer}/>
        <Route path = '/iass' component={IaaS}/>
        <Route path = '/disaster&backup' component= {DisasterRecovery}/>
        <Route path = '/softwareDevelopment' component = {SoftwareDevelopment}/>
        <Route path = '/dataai' component ={DataAI} />
        <Route path = '/hpc'component={HPC}/>
        <Route path = '/itautomation' component={ITAutoOrcH}/>
        <Route path = '/emailService' component = {EmailService}/>
        <Route path = '/tvirtual' component = {TrivontVirtualizer}/>
        <Route path = '/trivonticloud' component = {TrivontIcloud}/>
        <Route path = '/splashScreen'  component = {SplashScreenPage}/>

        <Route path="/Login" component={Login}/>
        <Route path = '/career'  component = {career}/>
        <Route path = '/careerReg' component ={CareerReg} />
        <Route path = '/openings' component ={Openings} />
        <Route path='/adminjobs' component={AdminHome}/>
        <Route path='/responsepage' component={ResponsePage}/>
        <Route path='/responseEmailPage' component={ResponseEmailpage}/>

        


        <Route component={Error} />
      </Switch>
    </>
  );
}

export default App;