import React from 'react';
import './banner.css';

import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
const CustomSection  = ({header,subtitle,content, img, isRow }) => {
  return (
    <section>
      <Jumbotron className='customSection__jumbotron'>
        <Container>
          {isRow ? (
              <Row className='align-items-center'>
              <Col xs={12} md={6}>
               <div className='customSection__Conatiner'> 
               <h1 className='customSection__h1'>{header}</h1>
                <h2 className='customSection__h2'>{subtitle}</h2>
                <p className='customSection__p'>{content}</p>
                <a href='/contactus' className='customSection__button'>Get Started</a>
               </div>
              </Col>
              <Col  xs={12} md={6}>
              <img  height='50' src={img} alt="Description of the image" className="img-fluid customImage_section" />
        
              </Col>
            </Row>
          ) : (
            <Col xs={12} md={12}>
              <div className='customSection__Conatiner'>
                <h1 className='customSection__h1'>{header}</h1>
                <h2 className='customSection__h2'>{subtitle}</h2>
                <p className='customSection__p'>{content}</p>
                <a href='/contactus' className='customSection__button'>Get Started</a>
              </div>
            </Col>
          )}
        </Container>
      </Jumbotron>
    </section>
  )
}

export default CustomSection;
