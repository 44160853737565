import React, { useState } from 'react';
// import logo from "../images/DARK.png";
import { FiHome } from 'react-icons/fi';
import axios from 'axios';
import AlertTwo from '../components/customComponents/alert2';
import { useHistory } from 'react-router-dom';
import logo from "../images/banner_dark.svg";
import { Col, Container, Form, Button, Row, Navbar } from 'react-bootstrap';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alerttwoMessage, setAlerttwoMessage] = useState('');
  const history = useHistory();

  const handleLogin = () => {
    axios.post('https://trivont.com/api/login/', { email, password }, { headers: { 'Content-Type': 'application/json' } }) // Ensure port 8000 for Django server
        .then(response => {
            if(response.data.status === 'success') {
                console.log("Login successful");
                sessionStorage.setItem('isLoggedIn', true);
                history.push("/adminjobs");
            } else if(response.data.status === 'unsuccessful') {
                alert("Invalid username and password!");
            }
        })
        .catch(error => {
            console.log("Error:", error);
            alert("An error occurred, please try again later");
        });
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Navbar className="bg-body-tertiary mt-3">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={logo}
              width="200"
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a href="/">
                <FiHome style={{ fontSize: '24px' }} /> Back to Home
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className='signup__formConatiner'>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              {showAlert && (
                <AlertTwo
                  type={alertType}
                  message={alerttwoMessage}
                  onClose={handleCloseAlert}
                />
              )}
              <h3 className="text-center mb-4">Sign in</h3>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <Button onClick={handleLogin} className="customSection2__button w-100">
                  Login
                </Button>
              </Form>
              <div className="mt-3 text-dark text-center"></div>
            </Col>
          </Row>
        </Container>
      </div>
      <footer style={{ position: 'absolute', bottom: '0', width: '100%' }} className="text-dark py-3 mt-auto">
        <Container>
          <Row className='justify-content-center'>
            <Col className='d-flex align-items-center justify-content-center text-center' xs={12} md={6}>
              <p>
                © {new Date().getFullYear()} Trivont. All rights reserved.
                <br />
                <a href="/terms" style={{ color: '#242424', textDecoration: 'none' }}>Terms of Service</a> |{' '}
                <a href="/privacy" style={{ color: '#242424', textDecoration: 'none' }}>Privacy Policy</a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
