import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import { Jumbotron } from 'react-bootstrap';

const MigrationJumbotron = ({ title, description }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5 // Adjust the threshold as needed
  });

  const animation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateX(0)' : 'translateX(50%)',
    config: {
      tension: 70,
      friction: 20
    }
  });

  return (
    <animated.div ref={ref} style={animation}>
      <Jumbotron style={{ background: '#0d1e67', color: '#fff', marginTop: '50px', borderRadius:'0'}}>
        <h1 className='display-md-1' style={{ color: '#ffff' }}>{title}</h1>
        <p>{description}</p>
        <a href="/contactus">
          <button variant="primary" style={{ backgroundColor: '#efefef', color: '#242424', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
            Reach Out and Connect
          </button>
        </a>
      </Jumbotron>
    </animated.div>
  );
};

export default MigrationJumbotron;
