import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import IconBox from '../components/iconbox';
import BannerSection from '../components/customComponents/bannerSection';
import backgrounBanner from '../images/BANNERS/abstract-science-fiction-futuristic-background-with-blue-neon-lights.jpg';
import serviceboxOne from '../images/icons/migration.png';
import serviceboxTwo from '../images/icons/icons8-kubernetes-250.png';
import serviceboxThree from '../images/icons/digitalization.png';
import serviceboxFour from '../images/icons/24-7.png';
import serviceboxFive from '../images/icons/mig2.png';
import serviceboxSix from '../images/icons/noun-backup-1461026.png';
import serviceboxSeven from '../images/icons/disaster-recovery.png';
import serviceboxEight from '../images/icons/user.png';
import serviceboxNine from '../images/icons/digital-certificate_4505724.png';
import serviceboxTen from '../images/icons/global.png';
import banner from '../images/BANNERS/trivoint_virtualizer (1).png'

import BSsection4s from '../components/BSsection4';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
const TrivontVirtualizer = () => {
    const context = useContext(DataContext);
    const {footer,VMservice } = context.allData
  
    const { handleSearch, handleOffcanvas } = context;
    
    const virtualizationFeatures = [
      {
        title: "Effortless Migration from VMware and Hyper-V",
        subtitle: "Seamless transition with minimal downtime",
        image:serviceboxOne,
        description: "Transitioning from VMware or Microsoft Hyper-V to Trivont Virtualizer is seamless with our streamlined migration services. Experience minimal downtime and disruption as you move to a more robust and cost-effective virtualization solution."
      },
      {
        title: "Direct Kubernetes Deployment",
        subtitle: "Deploy Kubernetes clusters effortlessly",
        image:serviceboxTwo,
        description: "Deploy Kubernetes clusters on Trivont Virtualizer effortlessly, without worrying about the underlying infrastructure. Host your Kubernetes clusters to ensure consistent and predictable application behavior, irrespective of the virtualization platform. Trivont Virtualizer provides Kubernetes as a Service (KaaS) offerings, simplifying your work and integration natively."
      },
      {
        title: "Advanced Networking Capabilities",
        subtitle: "Flexible and secure network architectures",
        image:serviceboxThree,
        description: "Our virtualization platform supports advanced networking models natively, including load balancers, Layer 2 (L2) and Layer 3 (L3) firewalls, and Site-to-Site VPN. These features provide you with the flexibility and security needed for complex network architectures."
      },
      {
        title: "High Availability & Fault Tolerance",
        subtitle: "End-to-end high availability and fault tolerance",
        image:serviceboxFour,
        description: "Trivont Virtualizer ensures end-to-end high availability (HA) for your instances and network. Our built-in fault tolerance mechanisms protect your workloads, keeping your business running smoothly even during unexpected failures."
      },
      {
        title: "Live VM/Instance Migration",
        subtitle: "Zero downtime with live migration",
        image:serviceboxFive,
        description: "Achieve zero downtime with live VM/instance migration. Seamlessly move your workloads between hosts without any interruption to your services."
      },
      {
        title: "Comprehensive Backup Solutions",
        subtitle: "Reliable and efficient data protection",
        image:serviceboxSix,
        description: "Protect your data with our native backup support and traditional snapshot-based backups. Ensure business continuity with our reliable and efficient data protection strategies."
      },
      {
        title: "VM Replication for Disaster Recovery",
        subtitle: "Robust disaster recovery capabilities",
        image:serviceboxSeven,
        description: "Ensure your business is prepared for any disaster with VM replication to multiple sites. Trivont Virtualizer provides robust disaster recovery capabilities, allowing you to replicate virtual machines across different locations to safeguard your critical data and applications."
      },
      {
        title: "User-Friendly Interface",
        subtitle: "Intuitive management experience",
        image:serviceboxEight,
        description: "Trivont Virtualizer is designed to be intuitive and easy to use, so professionals familiar with VMware, Hyper-V, AWS, Azure, or OCI can manage it without any additional training. Enjoy a smooth transition and seamless management experience."
      },
      {
        title: "Cost-Effective Licensing",
        subtitle: "Affordable virtualization solution",
        image:serviceboxNine,
        description: "Trivont Virtualizer offers a cost-effective licensing model based on support subscriptions. Our subscription cost is as low as $999 per annum, regardless of the number of cores or sockets, making it an affordable choice for businesses of all sizes."
      },
      {
        title: "All-in-One Solution",
        subtitle: "Unified platform for all your needs",
        image:serviceboxTen,
        description: "Trivont Virtualizer is your one-stop solution for virtualization, backup, networking, and disaster recovery. Eliminate the need for multiple vendor solutions and enjoy simplified management with our unified platform."
      }
    ];
    
    
   
    
    return (
    <>
    <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
   
    <BannerSection
title="TRIVONT VIRUTALIZER"
backgroundColor="#007bff"
color="#fff"
height="400px"
backgroundImage={backgrounBanner}
/>
   
        
  
  <SliderHeroSection backgroundImage='#fffff
  '  title="" subtitle=" Unmatched Security, Deliverability, and Scalability for Your Organization" 
   description="
   Trivont delivers top-tier, secure, and reliable enterprise email solutions designed for world-class organizations. Whether you need onsite or cloud-based email services, we ensure your emails meet the highest standards of security and privacy. Our solutions guarantee seamless mail flow, reaching your customers' and partners' primary inboxes with exceptional deliverability and monitoring. Experience the best in email service with Trivont.
   "
   
   textColor='#242424' isDefault={true} featureTitle='Looking for a VMware/Hyper-V alternative? Trivont Virtualizer offers robust solution for users seeking to migrate from Hyper-V/VMware
   '
     featureSubtitle="At Trivont, we are revolutionizing virtualization technology with our innovative product, Trivont Virtualizer. As a powerful alternative to market leaders like VMware and Microsoft Hyper-V, Trivont Virtualizer provides a comprehensive suite of features tailored to meet the diverse needs of modern businesses."  imageUrl={banner}  showImage={true} >
    '
   
    </SliderHeroSection>
   
    <MigrationJumbotron title='Seamless Transition with Zero Downtime: Effortlessly Migrate from VMware to Trivont Virtualizer'
     description='Trivont Virtualizer provides seamless migration solutions for cloud providers seeking to transition from VMware environments. With deep integration capabilities and minimal downtime, Trivont Cloud ensures a smooth and uninterrupted
      transition process for managing VMware workloads.' />  
      
    
  
     <IconBox title='Why Choose Trivont Virtualizer?' items={  virtualizationFeatures}/> 
  
     

  
     <BSsection4s
  heading="Experience the Future of Virtualization with Trivont"
  title1="Join the Trivont Revolution"
  title2="Upgrade to Trivont Virtualizer"
  title3="Need Assistance with Virtualization Solutions?"
  desc1="With Trivont Virtualizer, you gain access to a cutting-edge virtualization platform that not only meets but exceeds industry standards. Our robust, reliable, and flexible solution is designed to help your business achieve greater efficiency, resilience, and scalability."
  desc2="Upgrade to Trivont Virtualizer today and transform your IT infrastructure with our innovative virtualization technology. Contact us to learn more about how Trivont Virtualizer can benefit your business and schedule a demo to see our product in action."
  buttonText1="Start Your Trivont Journey"
  buttonText2="Contact Our Virtualization Experts"
/>

      <Footer data={footer} />
    
    </>
  )
}

export default TrivontVirtualizer