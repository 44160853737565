import React, { useState, useContext, useEffect } from 'react';
// import Navbar from '../components/Navbar';
import { DataContext } from '../context';
import Footer from '../components/Footer';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import './PagesCss.css'
import { Col, Container, Form, Button, Row, Navbar } from 'react-bootstrap';
import { FiHome } from 'react-icons/fi';
import logo from "../images/banner_dark.svg";

const Openings = () => {
  const context = useContext(DataContext);
  const { footer } = context.allData;
  const { handleSearch, handleOffcanvas } = context;
  const history = useHistory();
  const location = useLocation();

  // Extract data from location state
  const [selectedOpening, setSelectedOpening] = useState(location.state?.selectedOpening || {});

  const [title, setTitle] = useState(selectedOpening.title || "");
  const [domain, setDomain] = useState(selectedOpening.domain || "");
  const [jobLocation, setJobLocation] = useState(selectedOpening.location || "");
  const [summary, setSummary] = useState(selectedOpening.summery || "");
  const [experience, setExperience] = useState(selectedOpening.experience || "");
  const [noOfOpenings, setNoOfOpenings] = useState(selectedOpening.number_of_openings || "");
  const [lastDate, setLastDate] = useState(selectedOpening.last_date || "");
  const [skills, setSkills] = useState(selectedOpening.skills || "");
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(!!selectedOpening.id); // Check if in edit mode


  useEffect(() => {
    const LoggedIn = sessionStorage.getItem('isLoggedIn');
    if (!LoggedIn) {
      history.push('/login');
    }
  }, [history]);

  const allOpenings = () => {
    history.push('/adminJobs');
  };

  const jobOpenings = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const data = {
        title,
        skills,
        location: jobLocation,
        experience,
        summery: summary,
        domain,
        number_of_openings: noOfOpenings,
        last_date: lastDate,
      };

      const url = isEdit
        ? `https://trivont.com/api/openings/update/${selectedOpening.id}/`  // PUT URL
        : 'https://trivont.com/api/openings/insert/view/'; // POST URL

      const method = isEdit ? 'put' : 'post';


      axios({ method, url, data })
        .then(response => {
          if (response.data.status === 'success') {
            alert("Data Successfully Saved");

            setTitle("")
            setDomain("")
            setJobLocation("")
            setSkills("")
            setSummary("")
            setExperience("")
            setNoOfOpenings("")
            setLastDate("")

            history.push('/adminJobs');

          } else {
            alert("An error occurred, please try again later.")
          }

        })
        .catch(error => { 
          console.log(error); 
          alert("An error occurred, please try again later.")
        });
    } else {
      setErrors(errors);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!title.trim()) errors.title = "Title is required.";
    if (!domain.trim()) errors.domain = "Domain is required.";
    if (!jobLocation.trim()) errors.jobLocation = "Job location is required.";
    if (!summary.trim()) errors.summary = "Summary is required.";
    if (!experience.trim()) errors.experience = "Experience is required.";
    if (!noOfOpenings) errors.noOfOpenings = "Number of openings is required.";
    if (!lastDate.trim()) errors.lastDate = "Last date is required.";
    if (!skills.trim()) errors.skills = "Skills are required.";
    return errors;
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  };

  return (
    <>
<Navbar className="bg-body-tertiary mt-3">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={logo}
              width="200"
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a href="/">
                <FiHome style={{ fontSize: '24px' }} /> Back to Home
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br/>
      <div className="d-flex justify-content-end" style={{marginRight:'30px'}}>
        <button onClick={allOpenings} style={{ border:'1px solid black', borderRadius:'5px', padding:'5px'}}>View All Openings</button>
      </div>
      <div>
        <form id="User_userForm" className="OpeningcontainerUser">
          <div className="row" style={{marginBottom:'30px'}}>
            <div className="col-md-6">
              <label className="form-label">Title <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder='Enter Title'
                autoComplete='off'
                value={title}
                style={{marginBottom:'0px'}}
                onChange={(e) => setTitle(e.target.value)}
              />
              {errors.title && <p style={{ color: 'red' }}>{errors.title}</p>}
            </div>

            <div className="col-md-6">
              <label className="form-label">Domain <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder='Enter Domain'
                autoComplete='off'
                style={{marginBottom:'0px'}}
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
              {errors.domain && <p style={{ color: 'red' }}>{errors.domain}</p>}
            </div>
          </div>

          <div className="row" style={{marginBottom:'30px'}}>
            <div className="col-md-6">
              <label className="form-label">Skills <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder='Enter Skills'
                autoComplete='off'
                style={{marginBottom:'0px'}}
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
              />
              {errors.skills && <p style={{ color: 'red' }}>{errors.skills}</p>}
            </div>

            <div className="col-md-6">
              <label className="form-label">Experience <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder='Enter Experience'
                autoComplete='off'
                style={{marginBottom:'0px'}}
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
              />
              {errors.experience && <p style={{ color: 'red' }}>{errors.experience}</p>}
            </div>
          </div>

          <div className="row" style={{marginBottom:'30px'}}>
            <div className="col-md-6">
              <label className="form-label">Last Date <span style={{ color: 'red' }}>*</span></label>
              <input
                type='date'
                className="form-control"
                autoComplete='off'
                style={{marginBottom:'0px'}}
                value={lastDate}
                min={getTomorrowDate()}
                onChange={(e) => setLastDate(e.target.value)}
              />
              {errors.lastDate && <p style={{ color: 'red' }}>{errors.lastDate}</p>}
            </div>

            <div className="col-md-6">
              <label className="form-label">Number of Openings <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder='Enter Number of Openings'
                autoComplete='off'
                style={{marginBottom:'0px'}}
                value={noOfOpenings}
                onChange={(e) => setNoOfOpenings(e.target.value)}
              />
              {errors.noOfOpenings && <p style={{ color: 'red' }}>{errors.noOfOpenings}</p>}
            </div>
          </div>

          <div className="row" style={{marginBottom:'30px' }}>
            <div className="col-md-6">
              <label className="form-label">Location <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder='Enter Location'
                autoComplete='off'
                value={jobLocation}
                style={{marginBottom:'0px'}}
                onChange={(e) => setJobLocation(e.target.value)}
              />
              {errors.jobLocation && <p style={{ color: 'red' }}>{errors.jobLocation}</p>}
            </div>
          </div>

          <div className="row" style={{marginBottom:'30px' }}  >
            <div className="col-md-12">
              <label className="form-label">Summary <span style={{ color: 'red' }}>*</span></label>
              <textarea
                className="form-control"
                placeholder='Enter Summary'
                style={{ height: '150px', marginBottom: '0px' }}
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              ></textarea>
              {errors.summary && <p style={{ color: 'red' }}>{errors.summary}</p>}
            </div>
          </div>

          <button type="button" className="btn btn-primary" style={{ marginTop: '20px' }} onClick={jobOpenings}>Submit</button>
        </form>
      </div>
      <Footer footer={footer} />
    </>
  );
};

export default Openings;
