import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';

function IBox ({ data, columns, alignment, boxHeight })  {
  const [boxHeight1, setBlockHeight] = useState(boxHeight); // Default block height

  useEffect(() => {
    const updateBlockHeight = () => {
      if (window.innerWidth <= 767) { 
        setBlockHeight('auto'); // Adjusted block height for mobile devices
      } else {
        setBlockHeight(boxHeight); // Default block height for larger screens
      }
    };
  
    updateBlockHeight();
    window.addEventListener('resize', updateBlockHeight);
  
    return () => {
      window.removeEventListener('resize', updateBlockHeight);
    };
  }, [boxHeight]); // Add boxHeight as a dependency to useEffect

  if (!data || !data.items) {
    return <div>Loading...</div>;
  }

  let publicUrl = process.env.PUBLIC_URL + "/";

  const calculateColumnSize = (numColumns) => {
    switch (numColumns) {
      case 3:
        return 4;
      default:
        return 3;
    }
  };

  return (
    <section style={{marginTop:'50px', marginBottom:'20px'}}>
      <div>
        <Container>
          <Row className="justify-content-center">
            {data.items.map((item, index) => (
              <Col key={index} xs={12} md={calculateColumnSize(columns)} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:'20px'}}>
                <div className="Ibox-container" style={{ marginBottom: '20px', height: boxHeight1, boxShadow:'none' }}>
                  <div className={`Ibox-content ${alignment === 'top left' ? 'd-flex flex-column align-items-start' : 'd-flex '}`} >
                    {alignment === 'left beside' && (
                      <div className="Ibox-content d-flex align-items-center " >
                        <div className="Ibox-image" style={{ marginRight: '20px' }}>
                          <img
                            src={publicUrl + item.img.url}
                            alt={item.img.alt}
                            style={{ width: '50px', height: '50px', maxWidth: '50px', maxHeight: '50px', marginBottom:'5px' }}
                          />
                        </div>
                        <div>
                          <h2 className="Ibox-title" style={{marginBottom:'20px'}}>{item.title}</h2>
                          <p className="Ibox-subtitle">{item.subtitle}</p>
                        </div>
                      </div>
                    )}

                    {alignment !== 'left beside' && (
                      <div className="Ibox-image" style={{ marginBottom: "15px" }}>
                        <img src={publicUrl + item.img.url} alt={item.img.alt} style={{ width: '50px', height: '50px', maxWidth: '50px', maxHeight: '50px', marginRight:'10px',marginBottom:'5px'}} />
                      </div>
                    )}
                    <div>
                      <h2 className="Ibox-title" style={{marginBottom:'20px'}}>{item.title}</h2>
                      <p className="Ibox-subtitle">{item.subtitle}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default IBox;
