import React from 'react'
import HeroCarousel from './carousel'
import { Container } from 'react-bootstrap';
import FourBoxes from './fourboxes';
import a from '../../images/icons/html_6188015.png';
import b from '../../images/icons/ai_10485583.png';
import c from '../../images/icons/cloud-network_10485653.png';
import d from '../../images/icons/algorithm_7021282.png'

const CheroSection
 = () => {
  

  const services = [
    {
      title: "Application Development",
      imageUrl:a,
      subtitle: "TRIVONT offers in-depth experience in web apps. Begin delivering user expertise to your buyers and watch web presence transform into contemporary digital marketing, increasing the bottom line. Learn More"
    },
    {
      title: "Automation & AI",
      imageUrl:b,
      subtitle: "Designed to be an indispensable tool for IT professionals. Simplify your IT operations, improve productivity, and ensure seamless, auditable automation with Taniyangi's cutting-edge features. Learn More"
    },
    {
      title: "Trivont Cloud",
      imageUrl:c,
      subtitle: "TRIVONT Cloud computing is the on-demand availability of computer system resources, especially data storage and computing power, without direct active management by the user. Learn More"
    },
    {
      title: "Super Compute Cloud",
      imageUrl:d,
      subtitle: "At Trivont, we're committed to pushing the boundaries of innovation and enabling businesses to unlock the full potential of Artificial Intelligence (AI) and Machine Learning (ML). Learn More"
    }
  ];
  return (
    <>
    <div fluid className='hero-section'>
    
     <HeroCarousel/>
    
     <FourBoxes data={services}/>
     </div> 
     
   
    </>
  )
}

export default CheroSection
