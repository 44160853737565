import React, { useState, useEffect }  from "react";
import Loading from "./Loading";
import Title from "./Title";
import { Container, Row, Col } from "react-bootstrap";

function BSservices({ data, classText, styleTwo, styleThree , blockHeight}) {
 


   // Use hooks unconditionally at the top level of the component
 const [blockHeight1, setBlockHeight] = useState(blockHeight); // Default block height

 useEffect(() => {
   const updateBlockHeight = () => {
     if (window.innerWidth <= 767) { 
       setBlockHeight(parseInt(blockHeight) - 50 + 'px'); // Adjusted block height for mobile devices
     } else {
       setBlockHeight(blockHeight); // Default block height for larger screens
     }
   };

   updateBlockHeight();
   window.addEventListener('resize', updateBlockHeight);

   return () => {
     window.removeEventListener('resize', updateBlockHeight);
   };
 }, [blockHeight]); // Add blockHeight as a dependency to useEffect




  if (!data) {
    return <Loading />;
  }



  
  return (
    <section className={`${classText} BSservice`}>
      <Container>
        <Row>
          <Col>
            
            <Title
             subtitle={data.desc}
              title={data.title}
             
              classText={`${
                styleTwo
                  ? "title-shape title-shape-sky-blue text-center"
                  : styleThree
                  ? "title-shape title-shape-c2 title-shape-style-three text-center"
                  : "title-shape text-center"
              }`}
            />
          
          </Col>
        </Row>
        <Row className="justify-content-center">
          {data.items.map((item, index) => {
            let active = index === 1 ? "active" : "";
            return (
              <div key={index} className="col-lg-4 col-md-6">
                <div
                  className={`single-service  ${
                    styleTwo
                      ? "style--two"
                      : styleThree
                      ? `style--three ${active}`
                      : ""
                  }`}
                  onMouseOver={(e) => {
                    // Change color of h3 and p to white on mouse over
                    e.currentTarget.querySelector('h3').style.color = 'black';
                    e.currentTarget.querySelector('p').style.color = 'black';
                  }}
                  // onMouseOut={(e) => {
                  //   // Revert color of h3 and p when mouse leaves
                  //   e.currentTarget.querySelector('h3').style.color = ''; // Reverts to default color
                  //   e.currentTarget.querySelector('p').style.color = ''; // Reverts to default color
                  // }}
                  // style={{
                  //   backgroundColor:"white",
                    

                  // }}
                  style={{ height: blockHeight1}}
                >
                 
                  <div className="service-content" >
                   
                      <h3>{item.title}</h3> 
                    <p>{item.desc}</p>
                   
                   
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default BSservices;

BSservices.defaultProps = {
  classText: "pt-120 pb-90",
};
