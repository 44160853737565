import React, { Component} from "react";

import Logo from "../images/Banner_Trivont_Light.svg";
import stickyLogo from "../images/banner_dark.svg";

import { Link, NavLink } from "react-router-dom";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
//import image

import Drop from "./dropdown/drop";

class Navbar extends Component {
  _isMounted = false;

  state = {
    stickyClass: "",
    mobileNav: false,
  };
  myRef = React.createRef();

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
    // window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  
  handleScroll() {
    let lastScrollY = window.scrollY;

    if (lastScrollY >= 70 && this._isMounted) {
      this.setState((state, props) => ({
        stickyClass: "sticky fadeInDown animated fixed-top",
      }));
    } else {
      if (this._isMounted)
        this.setState((state, props) => ({
          stickyClass: "",
        }));
    }
  }

  funRef(e) {
    e.preventDefault();
    this.setState({
      mobileNav: !this.state.mobileNav,
    });
  }

  style = {
    color: "#5551EF",
  };
  constructor(props) {
    super(props);
    this.state = {
      dropVisible: false,
      anotherState: false,
      yetAnotherState: false,
      isOpen: false,
      isOpencom: true,
      isOpensol: false,
    };
  }

  handleButtonClick = () => {
    this.setState((prevState) => ({
      dropVisible: !prevState.dropVisible,
    }));
  };
  handleHover = () => {
    this.setState({ dropVisible: true, isOpen: true });
  };

  handleLeave = () => {
    this.setState({ dropVisible: false, isOpen: false });
  };
  handleAnotherAction = () => {
    this.setState((prevState) => ({
      anotherState: !prevState.anotherState,
    }));
  };
  toggleDropVisibility = () => {
    this.setState((prevState) => ({
      dropVisible: !prevState.dropVisible,
      isOpen: !prevState.isOpen,
    }));
  };
  handleYetAnotherAction = () => {
    this.setState((prevState) => ({
      yetAnotherState: !prevState.yetAnotherState,
    }));
  };
  // handleHoverAnother = () => {
  //   this.setState({ anotherState: true, isOpencom: false });
  // };

  handleHoverAnother = () => {
    this.setState(prevState => ({ isOpencom: !prevState.isOpencom }));
  };

  toggleAnotherState = () => {
    this.setState((prevState) => ({ anotherState: !prevState.anotherState }));
  };

  handleHoverYetAnother = () => {
    this.setState({ yetAnotherState: true, isOpensol: true });
  };

  handleLeaveYetAnother = () => {
    this.setState({ yetAnotherState: false, isOpensol: false });
  };

  toggleYetAnotherState = () => {
    this.setState((prevState) => ({
      yetAnotherState: !prevState.yetAnotherState,
    }));
  };

  render() {
    let headerTop = this.props.headerTop;
    const {
      dropVisible,
      anotherState,
      yetAnotherState,
      isOpen,
      isOpencom,
      isOpensol,
    } = this.state;

    const ProductSidbar = [
      {
        title: "Compute",
        description: "Virtual Machine",
        link: "/virtualMachine",
      },
      {
        title: "Kubernates",
        description: "Fully Managed Kubernates Cluster",
        link: "/kubernates",
      },
      {
        title: "Private Cloud",
        description: "Dedicated Private Cloud Services",
        link: "/dedicatedPrivateCloud",
      },
      {
        title: "Databases",
        description: "Database As a Service",
        link: "/DNS",
      },
      {
        title: "Software Development",
        description: "Containerized Applications",
        link: "/container",
      },
      {
        title: "Data and AI",
        description: "Unlock Insights and Drive Innovation with Data and AI Solutions",
        link: "/dataAndAI",
      },
      {
        title: "GPU Cloud",
        description: " Scalable Software Solutions with GPU Cloud",
        link: "/softwareAndGPU",
      },
      {
        title: "Hybrid Cloud",
        description: "Combine On-Premises Infrastructure with Cloud Services for Enhanced Flexibility",
        link: "/hybridCloud",
      }
      // { title: "Application Services", description: "Websites & Highly Scalable Applications", link: "/websiteHosting" }
    ];

    
    const ProductRows = [
      [
      {
        title: "Featured Solutions",
        links: [
          { label: "Compute", url: "/virtualMachine", desc: "Powerful virtual machine solutions" },
          { label: "DbaaS", url: "/dbaas", desc: "Database as a Service solutions" },
          { label: "GPU Cloud", url: "/gpuCloud", desc: "Scalable GPU cloud services" },
          { label: "Kubernetes", url: "/kubernates", desc: "Fully managed Kubernetes clusters" },
          { label: "Private Cloud", url: "/dedicatedPrivateCloud", desc: "Dedicated private cloud services" },
        ],
      },
      {
        title: "Applications",
        links: [
          { label: "Website Hosting", url: "/websiteHosting", desc: "Host your website on scalable and reliable infrastructure for maximum uptime" },
          { label: "Application Hosting", url: "/applicationHosting", desc: "Deploy and manage your applications with ease on our cloud platform" },
          { label: "Uptime Guaranteed", url: "/upTimeApplication", desc: "Ensure high availability and reliability for your applications with our uptime guarantee" },
        ],
      },
      {
        title: "Cloud Transition Services",
        links: [
          { label: "Cloud Migration Service", url: "/cloudMigration", desc: "Seamlessly migrate your applications and data to the cloud with expert assistance" },
          { label: "Cloud Disaster Recovery (DR) Services", url: "/cloudDR", desc: "Implement robust disaster recovery strategies to protect your business-critical data and applications" },
        ],
      },
      {
        title: "Compute",
        links: [
  
{ label: "Auto Scale Compute Nodes", url: "/autoScaleComputeNodes", desc: "Automatically scalable compute nodes for fluctuating workloads" },
 { label: "GPU Compute Cluster", url: "/GPUcomputeCluster", desc: "Scalable GPU compute clusters for high-performance computing" },          
 { label: "GPU Compute Nodes", url: "/GPUcomputeNode", desc: "Compute nodes with GPU acceleration capabilities" },
 { label: "Optimized Virtual Machine", url: "/optimizedVirtualMachine", desc: "Highly optimized virtual machine configurations" },
{ label: "Virtual Machine", url: "/virtualMachine", desc: "Efficient virtual machine solutions" },
         
         
         
        
        ],
      },
      {
        title: "Managed Databases",
        links: [
          { label: "Cassandra", url: "/cassandra", desc: "Distributed NoSQL database designed for handling large amounts of data across multiple servers" },
          { label: "Cockroach", url: "/cockroachDB", desc: "Distributed SQL database built for global scale and survivability" },
          { label: "HBase", url: "/hbase", desc: "Distributed, scalable, and consistent NoSQL database for big data applications" },
          { label: "Kafka", url: "/kafka", desc: "Distributed streaming platform for building real-time data pipelines and applications" },
          { label: "MongoDB", url: "/mongoDB", desc: "Scalable and flexible NoSQL database management" },
          { label: "MySQL", url: "/mysql", desc: "Relational database management system for structured data storage and retrieval" },
          { label: "PostgreSQL", url: "/PostgreSql", desc: "Open-source relational database management system known for its reliability and robust features" },
          { label: "TiDB", url: "/tiDB", desc: "Distributed SQL database that combines the scalability of NoSQL and the consistency of SQL" },
        ],
      },
      {
        title: "Messaging Service",
        links: [
          { label: "Corporate Email", url: "/corporateCommunication", desc: "Enterprise-grade email solutions for corporate communication" },
          { label: "Messaging Services (SMS)", url: "/messagingService", desc: "Send and receive SMS messages reliably with our messaging platform" },
          { label: "Small Business Email", url: "/smallBusinessEmail", desc: "Secure and reliable email hosting for small businesses" },
        ],
      },
      {
        title: "Networking",
        links: [
          { label: "Cloud Firewalls", url: "/VirtualFirewalls", desc: "Protect your cloud infrastructure with customizable firewall rules" },
          { label: "Cloud VPN", url: "/cloudVPN", desc: "Establish secure connections between your on-premises network and cloud resources" },
          { label: "DDoS Protection", url: "/DDOS", desc: "Mitigate distributed denial-of-service (DDoS) attacks to ensure service availability" },
          { label: "DNS", url: "/DNS", desc: "Manage your domain names and translate them into IP addresses" },
          { label: "Load Balancers", url: "/loadBalancer", desc: "Distribute incoming network traffic across multiple servers for improved performance and reliability" },
          { label: "Virtual Private Cloud", url: "/vpc", desc: "Securely connect your resources in a virtual network environment" },
        ],
      },
      {
        title: "Private Cloud",
        links: [
          { label: "Dedicated Private Cloud Services", url: "/dedicatedPrivateCloud", desc: "Secure and dedicated private cloud solutions" },
        ],
      },
      {
        title: "Storage",
        links: [
          { label: "Block Storage", url: "/blockStorage", desc: "Block-level storage solutions with high-performance capabilities" },
          { label: "File Storage", url: "/fileStorage", desc: "File-based storage solutions for easy data access" },
          { label: "Object Storage", url: "/objectStorage", desc: "Scalable object storage solutions for your data" },
          { label: "Private FTPES Storage", url: "/ftpesStorage", desc: "Private FTP over SSL storage solutions" },
          { label: "Secure Drop", url: "/secureDrop", desc: "Encrypted file storage with secure access controls" },
        ],
      },
      {
        title: "Web Hosting Solutions",
        links: [
          { label: "Static Websites Hosting", url: "/staticWesiteHosting", desc: "Fast and reliable hosting for static websites and web applications" },
          { label: "WooCommerce Hosting", url: "/wooCommerce", desc: "Optimized hosting solutions for WooCommerce-powered online stores" },
        ],
      },
      // Remaining objects follow the same structure...}
    ]
    ];
    
    const dummyRows = [
      [
        {
          title: "Featured Product",
          links: [
            {
              url: "/cloudservices",
              label: "Trivont Cloud",
              description: "Cloud services tailored to meet your business needs",
            },
          ],
        },
        
      ],
    ];
  
  
    // const topValue = 90;
    return (
      <>
        <header className={`header ${headerTop ? "" : "fixed-top"}`}>
        
          <div
            className={`${this.state.stickyClass} ${
              headerTop ? "bg-white" : ""
            } header-main`}
          >
            <Container fluid>
              <Row className="position-relative">
                <Col lg={2} xs={5} className="d-flex align-items-center">
                  {/* Logo */}
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={headerTop ? stickyLogo : Logo}
                        className="main-logo"
                        style={{
                          height: "50px",
                        }}
                        alt="Logo"
                      />
                      <img
                        src={stickyLogo}
                        className="sticky-logo"
                        style={{ height: "50px" }}
                        alt="Sticky Logo"
                      />
                    </Link>
                  </div>
                  {/* End Logo */}
                </Col>

                <Col
                  lg={10}
                  xs={7}
                  className="d-flex align-items-center justify-content-end position-static"
                >
                  {/* Nav Wrapper */}
                  <div className="nav-wrapper">
                    {/* Menu Button */}
                    <div id="menu-button" onClick={(e) => this.funRef(e)}>
                      <span></span>
                    </div>
                    {/* End Menu Button */}
                    <div
                      className={`nav-wrap-inner ${
                        this.state.mobileNav ? "active" : ""
                      }`}
                      ref={this.myRef}
                    >
                      {/* Nav */}
                      <ul className="nav flex-column flex-md-row  align-items-center justify-content-end">
                     
                      <div 
        onClick={this.handleHoverAnother}
       
        style={{ position: 'relative' }}
      >
        <NavDropdown 
          style={{ color: '#242424 !important' }} 
          title={
            <span>
              Products{' '}
              <span style={{fontSize:'12px',marginLeft:'2px'}} className={`chevron ${this.state.isOpencom ? "up" : "down"}`}>
                {isOpencom ?  <FaChevronDown/>:<FaChevronUp  />}
              </span>
            </span>
          } 
          id="basic-nav-dropdown"
          
        >
          <NavDropdown.Item href="/Automation">Trivont Automation</NavDropdown.Item>    
          {/* <NavDropdown.Item href="/cloudservices">Trivont Cloud</NavDropdown.Item> */}
          <NavDropdown.Item href="/emailService">Trivont Mail</NavDropdown.Item>
          <NavDropdown.Item href="/trivonticloud">Trivont iCloud</NavDropdown.Item>
          <NavDropdown.Item href="/tvirtual">Trivont Virtualizer</NavDropdown.Item>
        </NavDropdown>
      </div>
                        <div
                          className="dropdown"
                          onMouseEnter={this.handleHover}
                          onMouseLeave={this.handleLeave}
                        >
                          <button
                            className="dropbtn"
                            onClick={this.toggleDropVisibility}
                          >
                            Solutions
                            <span
                              className={`chevron ${isOpen ? "up" : "down"}`}
                            >
                              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                            </span>
                          </button>
                          <div
                            className="dropdown-content"
                            style={{ display: dropVisible ? "block" : "none" }}
                          >
                            <div className="dunny">
                              <Drop
                                visible={dropVisible}
                                title=""
                                sidebarContent={ProductSidbar}
                                rows={ProductRows}
                               showSidebarButtons={true}
                              />
                            </div>
                          </div>
                        </div>
                        


                        
                       
     

                      </ul>
                      {/* End Nav */}
                    </div>
                  </div>
                  {/* End Nav Wrapper */}
                </Col>
               

              </Row>
            </Container>
          </div>
        </header>
      </>
    );
  }
}

export default Navbar;
