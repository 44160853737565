import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4';
import CustomFeature from '../components/customComponents/customBoxes';
import './PagesCss.css'
import TwoColumnBlock from '../components/TwoBlockColumn';
import websiteHosting from '../components/banner/websiteHosting.png'

function WebsiteHosting() {

    const context = useContext(DataContext);
    const { footer} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const featureData = {
        title: 'Key Features',
        desc: 'Discover the powerful features that set us apart.',
        items: [
            {
                title: "Up to 10 Gbps Outbound Data Speeds",
                desc: "Experience a significant boost in outbound network speeds – five times faster than their Regular counterparts – with the introduction of the new Premium CPU-Optimized Droplets, simplifying the delivery of top-notch digital experiences."
              },
              {
                title: "Reliable and Enduring Performance",
                desc: "Harness the power of the latest Intel® Xeon® CPU generations with Premium CPU-Optimized Droplets, ensuring unwavering application performance and reliability."
              },
             
              {
                title: "Dedicated CPU for Consistent Performance",
                desc: "Unlike shared CPU Droplets, dedicated CPU Droplets guarantee uninterrupted access to the full hyperthread, ensuring a consistently high level of performance."
              }
          // Add more feature items as needed
        ],
      };
     

     
  

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}}  headerTop="True" />
            <CustomSection header="Web Hosting"
  content="Empower Your Online Presence with Unrivaled Reliability and Speed"
  img={websiteHosting}
  isRow={true}/>       
        
      
        <CustomFeature data={featureData} box1Height="350px"/>
        
       



      

<TwoColumnBlock
      heading="Resources"
  title1="Big Data Guides and Resources"
  content1="Seamlessly unlock business insights with Qloud Platform’s fully managed, proven, end-to-end data analytics products. Remove operational complexities of data analytics with a serverless approach and make important business decisions quickly and efficiently."
  title2="Get started quickly"
  title3="Keep costs low"
  title31="Creative Ideas"
  title21="Scale up with confidence"
  desc1="Start your projects swiftly, catering to developers creating personal websites and high-growth startups introducing new products"
  desc2="Ensure cost-effectiveness with affordable pricing and a transparent model, ensuring your web hosting expenses remain manageable as your application grows."
  desc11="Scale your operations confidently with a 99.99% uptime"
  desc21="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  buttonText1="Read More"
 
/>


           
            <Footer data={footer} />
        </> 
    )
}

export default WebsiteHosting
