import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { FaEnvelope, FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
    <footer className=" text-light py-4" style={{
      backgroundColor:'#0d1e67'
    }}>
      <Container fluid>
      <Row className="d-flex justify-content-center align-items-center">
      {/* Column 1 */}
      <Col md={6} xs={12} className="mb-3 mb-md-0 text-center text-md-right">
        <div style={{ maxWidth: '700px' }}>
          <div>
            <a href="#about" className="text-decoration-none text-light mx-3">About</a>
            <a href="/contactus" className="text-decoration-none text-light mx-3">Contact</a>
            <a href="/career" className="text-decoration-none text-light mx-3">Career</a>

          </div>
        </div>
      </Col>

      {/* Column 2 */}
      <Col md={6} xs={12} className="text-center text-md-left">
        <div className="social-links">
          <a href="mailto:contactus@trivont.com" className="text-decoration-none text-light">
            <FaEnvelope  />
          </a>
          <a href="https://twitter.com" className="text-decoration-none text-light">
            <FaTwitter  />
          </a>
          <a href="https://facebook.com" className="text-decoration-none text-light">
            <FaFacebook  />
          </a>
          <a href="https://linkedin.com" className="text-decoration-none text-light">
            <FaLinkedin  />
          </a>
        </div>
      </Col>
    </Row>
      </Container>
      <div className="container" >
        <p className="mb-0 small text-center mt-4" style={{ margin: '0 16px' }}>
          Copyright © {new Date().getFullYear()} Trivont. All Rights Reserved.
        </p>
      </div>
    </footer>
    
    </>
  );
};

export default Footer;
