import React, { useContext,} from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';
import BSservices from '../components/BSservices';
import Box from '../components/customComponents/box';



const AutoScaleCompute = () => {
  const context = useContext(DataContext);
  const {footer,ASCservice } = context.allData
 
  const { handleSearch, handleOffcanvas } = context;
  
  const AutoScaleCards = [
    {
      title: "Dynamic Scalability",
      subtitle: "Our Auto Scale Compute Nodes harness the power of intelligent auto-scaling technology to expand or contract computing resources in real-time, ensuring optimal performance during peak demand and cost-efficiency during lulls."
    
    },
    {
      title: "Intelligent Resource Management",
      subtitle: " Bid adieu to manual resource allocation headaches. Our service intelligently manages computing resources, optimizing performance while minimizing costs." },
    {
      title: "Focus on Core Objectives",
      subtitle: "With our Auto Scale Compute Nodes, focus your attention on driving your business forward. Let us handle the complexities of resource management while you concentrate on achieving your core objectives." },
    {
      title: "Responsive Availability",
      subtitle: "Ensure that your applications are always responsive and available, regardless of fluctuating workloads. Our auto-scaling technology keeps your systems agile and ready to meet user demands."  }
  ];
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  <CustomSection header="Auto Scale Compute Nodes"
  subtitle=""
  content="Welcome to the future of computing flexibility with our Auto Scale Compute Node service. Say farewell to the headaches of managing fluctuating workloads and embrace a seamless, responsive computing environment that adjusts dynamically to meet your demands."
  isRow={false}
  />


    <Box data={AutoScaleCards} title=" Unleash the Potential of Auto Scaling" subtitle="Auto Scaling" boxHeight='390px'/>
    
    
    <BSservices data={ASCservice} blockHeight='330px'/>

    <BSsection4s
  heading="Ready to embark on your cloud computing adventure?"
  subHeading="Join Us!"
  title1="Scale Your Compute Nodes Automatically"
  title2="Efficient Resource Management"
  title3="Need Expert Guidance?"
  desc1="Explore our Autoscale Compute Nodes service to dynamically manage your computing resources. Whether you're handling variable workloads or optimizing resource usage, begin your journey with our scalable solutions and enjoy a complimentary $300 credit."
  desc2="Our team of cloud experts is dedicated to helping you implement the perfect Autoscale Compute Nodes setup or connecting you with the right partners to optimize your computing environment."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>


    <Footer data={footer} />
  
  </>
  )
}

export default AutoScaleCompute