import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import Box from '../components/customComponents/box';
import BSsection4s from '../components/BSsection4';
import Columns2s from '../components/TwoColumn';
import './PagesCss.css'



function UpTimeApp() {

    const context = useContext(DataContext);
    const { footer,Column5} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const UpTimeFeatures = [
        {
          title: 'Automated Monitering of Critical Services',
          subtitle: 'Set up automatic uptime checks for your critical services, ensuring a quick response to issues and maintaining uninterrupted service.'
        },
        {
          title: 'Constant Endpoint Monitering on Any Cloud',
         subtitle: 'Monitor your assets 24/7 using a URL or IP address. Gain insights into performance, uptime, and discover patterns that optimize decision-making, save resources, and enhance customer satisfaction.'
        },
        {
          title: 'Global Uptime Monitoring',
          subtitle: 'Monitor uptime globally by checking up to four regions worldwide in every uptime check. Obtain the average global uptime at any time for comprehensive performance insights.'
        },
        {
          title: 'Alerts for Latency, Downtime, and SSL Expiry',
          subtitle: 'Receive timely alerts for latency issues, downtime, and SSL certificate expiry. Keep your SSL certificates active, stay protected, and be the first to know when your site experiences slowdowns or outages.'
        }
        
      ];

  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="UpTime"
  content="Dynamic Monitering for Seamless Operations"/>       
        
        <Columns2s data={Column5}/>
        <Box data={UpTimeFeatures} boxHeight="400px"/>
        <BSsection4s
  heading="Ready to Ensure Your Website's Uptime?"
  title1="Start Monitoring Your Website's Performance"
  title2="Real-time Uptime Insights"
  title3="Need Expert Support?"
  desc1="Take control with Trivont's uptime application. Begin monitoring your website's performance with our intuitive tools and enjoy a complimentary $300 credit to get started."
  desc2="Our team of uptime monitoring experts is here to help you optimize your website's reliability or connect you with the right partner to maximize uptime."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>

           
            <Footer data={footer} />
        </> 
    )
}

export default UpTimeApp
