import React, { useContext, } from 'react';
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { Container, Row, Col,Button,Card } from 'react-bootstrap';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import IconBox from '../components/iconbox';
import i from '../images/icons/infrastructure.png';
import i1 from '../images/icons/profits.png';
import i2 from '../images/icons/gear.png';
import i3 from '../images/icons/robust.png';
import i4 from '../images/icons/disaster-recovery.png';
import i5 from '../images/icons/digitalization.png';
import Box from '../components/customComponents/box';
import {  Jumbotron } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import banner from '../images/IMG.png';
import mainLogo from '../images/BANNERS/trivoint_virtualizer (1).png'
import BannerSection from '../components/customComponents/bannerSection';
import bannerImage from  '../images/BANNERS/cloudServicestwo.png';
function CloudServices() {

    const context = useContext(DataContext);
    const { footer,Column11, DMservice} = context.allData
    const { handleSearch, handleOffcanvas } = context;

  
    
  const features = [
    {
      title: "Cost-effective VMware replacement, ensuring profitability",
      description: "Eliminates the need for expensive licensing fees associated with proprietary software, significantly reducing operating costs. It offers a transparent and community-oriented development model, ensuring that there are no hidden costs or sudden price changes.",
      image: i1
    },
    {
      title: "Advanced network and security features",
      description: "It offers sophisticated network capabilities, including VPCs, load balancing, and network-as-a-service options. Integrates advanced security features, ensuring secure cloud operations and data protection.",
      image: i5
    },
    {
      title: "Robust multi-tenancy and scalability",
      description: "Facilitates the creation and management of secure, isolated multi-tenant environments essential for CSPs. Scales effectively to accommodate growing customer demands, from small deployments to large-scale distributed cloud environments.",
      image: i3
    },
    {
      title: "Simplified cloud management and operations",
      description: "The user-friendly interface and robust API simplify cloud management tasks, making it easy to deploy, manage, and scale cloud resources. Reduces the complexity of managing multi-tenant cloud environments, increasing efficiency for service providers.",
      image: i2
    },
    {
      title: "Disaster recovery and high availability",
      description: "Provides robust disaster recovery solutions, ensuring business continuity and resilience in the face of disruptions. Supports high availability configurations, minimizing downtime and ensuring reliable service delivery.",
      image: i4
    },
    {
      title: "Seamless integration with VMware infrastructure",
      description: "Trivont Cloud seamlessly integrates with existing VMware infrastructures, including vSphere and NSX, enabling a smooth transition without the need to review current systems. Maintains operational continuity and leverages existing VMware investments while expanding resources.",
      image: i
    }
];

  
  const boxfeatures = [
    {
      title: "Metering and Usage",
      subtitle: "Accurate metering and usage tracking are crucial for service providers. Trivont Cloud includes sophisticated metering tools to monitor resource usage, assisting with billing and capacity planning."
    },
    {
      title: "Multi-Zone Management",
      subtitle: "Trivont Cloud excels at multi-zone management, enhancing data center resource utilization and offering geographic redundancy for your users."
    },
    {
      title: "Advanced Networking Capabilities",
      subtitle: "Trivont Cloud offers advanced networking capabilities, including network-as-a-service options and fine-grained network control. Features like VPC networks, agnostic integration with third-party VNF (Virtual Network Function), Firewall, Load Balancer, and SDN enhance network functionality."
    },
    {
      title: "Disaster Recovery Workload Protection",
      subtitle: "Trivont Cloud provides robust multi-tenant disaster recovery solutions, safeguarding your customers' workloads and ensuring business continuity in the face of system failures or disruptions."
    },
    {
      title: "Cloud-native Deployments",
      subtitle: "Trivont Cloud supports the deployment of Kubernetes, both native and hybrid solutions with CAPC (ClusterAPI CloudStack Provider), simplifying the orchestration and management of containers."
    },
    {
      title: "Veeam Integration",
      subtitle: "Integration with Veeam, through Trivont Cloud's Backup and Recovery Framework, adds robust data protection and backup capabilities essential for maintaining data integrity and availability."
    },
    {
      title: "Application Lifecycle Management",
      subtitle: "Trivont Cloud provides comprehensive tools to manage the application lifecycle, from deployment to maintenance and scaling. This ensures smooth operations and agility during peak times as defined by your users."
    },
    {
      title: "Hypervisor Agnostic DRS",
      subtitle: "Trivont Cloud's DRS functionality is hypervisor agnostic, ensuring efficient resource allocation across different hypervisor hosts. This optimizes performance and workload distribution at the Zone or Hypervisor Cluster level."
    }
];
const [ref, inView] = useInView({
  triggerOnce: true,
  threshold: 0.5 // Adjust the threshold as needed
});

const animation = useSpring({
  opacity: inView ? 1 : 0,
  transform: inView ? 'translateX(0)' : 'translateX(50%)',
  config: {
    tension: 70,
    friction: 20
  }
});

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            
            <BannerSection
        title="Cloud Services"
       backgroundImage={bannerImage}
        color="#ffff" 
        height="400px"
      />

    <SliderHeroSection backgroundImage='#ffff
    '  title="Automation & AI" subtitle="Simplify your IT operations" 
  textColor='#242424' isDefault={true} featureTitle='Looking for a VMware alternative? Trivont Cloud offers a robust solution for users seeking to transition from VMware. '
   featureSubtitle=' With Trivont Cloud, existing VMware users can seamlessly migrate to an open-source platform, reducing vendor dependency. Additionally, Trivont Cloud provides service providers with a straightforward path to embrace a fully open-source solution, ensuring flexibility and independence from proprietary systems. ' imageUrl={mainLogo}
   showImage='true'
   
   >
    
  </SliderHeroSection>
           
            
 
  <Container style={{ backgroundColor: '#0d1e67' }} fluid className=' h-100 p-4 text-white d-flex justify-content-center align-items-center'>
  <Row className=''>
    
    <Col xs={12} md={6} className='d-flex align-items-center'>
      <Container>
      <div style={{ padding: '20px' }}>
        <h1 className="text-left mb-4">Addressing the Needs of Cloud Service Providers and MSPs</h1>
        <p className="mb-4">Trivont Cloud offers robust management capabilities, capable of handling large-scale deployments spanning multiple data centers. While being a robust IaaS management solution, Trivont Cloud remains user-friendly and can be implemented efficiently even with a small team. Supported by a vibrant open-source community, Trivont Cloud eliminates dependencies, allowing you to pursue your cloud development goals and utilize hardware and software according to your business needs.</p>
        <p className="mb-4">Whether you're looking to enhance your existing VMware infrastructure or transition to a more flexible and scalable cloud solution, Trivont Cloud emerges as the ideal option. Embrace the capabilities of Trivont Cloud for a seamless cloud experience devoid of vendor lock-in, perfectly suited to the evolving requirements of modern Cloud Service Providers.</p>
      </div>
      </Container>
    </Col>
    <Col xs={12} md={6}>
      <Container>
        <div className="embed-responsive embed-responsive-16by9 d-flex justify-content-center">
          <img src={banner} style={{ maxWidth: '70%', height: 'auto' }} alt="Your Image" className="" />
        </div>
      </Container>
    </Col>
  </Row>
</Container>


    <IconBox items={features    } title='Unlocking Long-Term Reliability and Profitability: Embrace Trivont Cloud as Your VMware Alternative'/>
    <Container fluid style={{
  padding:'0'
}}> 
      <animated.div ref={ref} style={animation}>
        <Jumbotron style={{ background: '#0d1e67', color: '#fff',borderRadius:'0' }}>
          <h1 style={{ color: '#ffff' }}>Seamless Transition with Zero Downtime: Effortlessly Migrate from VMware to Trivont Cloud</h1>
          <p>Trivont Cloud provides seamless migration solutions for cloud providers seeking to transition from VMware environments. With deep integration capabilities and minimal downtime, Trivont Cloud ensures a smooth and uninterrupted transition process for managing VMware workloads.</p>
          <a href="/contactus">
  <button variant="primary" style={{ backgroundColor: '#efefef', color: '#242424', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }} >
    
  Reach Out and Connect
  </button>
</a>
        </Jumbotron>
      </animated.div>
    </Container>
<Box data={boxfeatures} title=" Trivont Cloud: VMware Alternative Features"  boxHeight="400px" />


      
      
      <Container style={{ padding: '20px', boxShadow: '2px 10px 10px 8px rgba(0,0,0,0.1)', marginBottom:'50px' }}>
        <Row className="mb-4">
          <Col xs={12} md={6} className='d-flex  flex-column'>
            <h1 className='mb-2'>Deployment Scenarios</h1>
            <h2 className='mb-3'>Private Cloud</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className='mb-4'>
            <Card style={{border:'none'}}>
              <Card.Body>
                <Card.Title style={{color:'#242424'}} >Single-Tenant Deployment Scenario</Card.Title>
                <Card.Text>
                  Experience the pinnacle of exclusivity with our single-tenant deployment scenario. With a dedicated cloud server serving only your organization, enjoy unparalleled control, customization, and security. Benefit from enhanced confidentiality, performance, and backup, ensuring your business operations remain uninterrupted and optimized.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className='mb-4'>
            <Card style={{border:'none'}}>
              <Card.Body>
                <Card.Title style={{color:'#242424'}}>Multi-Tenant Deployment Scenario</Card.Title>
                <Card.Text>
                  Join a dynamic community of users with our multi-tenant deployment scenario. Share resources and costs while maintaining individual data isolation and customization. Leverage the power of economies of scale for cost-effectiveness and scalability, ensuring seamless operations and unlimited computing capacity for your business needs.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

            <Footer data={footer} />
        </> 
    )
}

export default CloudServices
