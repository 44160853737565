import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';
import BSservices from '../components/BSservices';

import Box from '../components/customComponents/box';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import sec from '../images/icons/security.png';
import firewall from '../images/icons/firewall.png';
import anti from '../images/icons/protect.png';
import pent from '../images/icons/penetration-testing.png';
import mob from '../images/icons/application.png';
import infra from '../images/icons/infra.png';
import CyberS from '../images/BANNERS/cyberSecurity.png'
const CyberSecurity = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData

  const { handleSearch, handleOffcanvas } = context;
  
  const securityFatures = [
    {
      title: "Product Security",
      subtitle: "Each product offers numerous security features to help safeguard your infrastructure."
    },
    {
      title: "Platform Security",
      subtitle: "We simplify security at the infrastructure layer, ensuring your peace of mind."
    },
    {
      title: "Trust and Privacy",
      subtitle: "Learn about our data management, storage, and protection practices, and explore our certification reports."
    },
    {
      title: "Security Best Practices",
      subtitle: "Access documentation and articles covering the best security practices."
    },
    {
      title: "Infrastructure Security",
      subtitle: "We adhere to the latest infrastructure security controls to ensure robust protection."
    },
    {
      title: "Shared Responsibility Model",
      subtitle: "Explore our product guides to understand how we collaborate to secure your instance."
    }
  ];
  const securityFeatures = [
    {
      title: "Secure Mobile Access",
      subtitle: "Maintain Complete Control Over Mobile Access",
      image:mob,
      description: "Achieve secure mobile access by implementing comprehensive control measures within your organization."
    },
    {
      title: "FWaaS",
      subtitle: "Robust Security for Network Traffic",
      image:firewall,
      description: "Firewall as a Service  is a cloud-based security solution that operates over the Internet and is managed by us. With FWaaS, organizations can leverage the expertise of security professionals to update and maintain their firewall infrastructure, ensuring robust protection against cyber threats."
    },
    {
      title: "Advanced Antivirus Solutions",
      subtitle: "Prevent Malware Infections Effectively",
      image:anti,
      description: "Utilize intelligent antivirus solutions to proactively prevent malware infections and safeguard your network from threats."
    },
    {
      title: "Network Intrusion Protection",
      subtitle: "Seal Your Network from Unauthorized Access",
     image:sec,
      description: "Enhance your network security with intrusion protection mechanisms, allowing only legitimate information to pass through and preventing disguised threats."
    },
    {
      "title": "Accelerate Business Growth with Enhanced Infrastructure Security",
      "subtitle": "Unlock Opportunities for Rapid Expansion",
     image:infra,
      "description": "Optimize your security infrastructure for seamless operations, increased productivity, and enhanced customer trust. Stay ahead of the competition with tailored security solutions."
    },{
      title: "Fortify Your Defenses with Penetration Testing",
      subtitle: "Safeguard Your Business from Cyber Threats",
      image:pent,
      description: "Ensure the security of your online assets by implementing regular internal and external penetration testing. By integrating web application penetration testing into your Software Development Life Cycle (SDLC)."
    }
    
  ];
  
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
  <BannerSection
        title="Cyber Security"
        backgroundColor="#007bff"
        backgroundImage={CyberS}
        color="#fff"
        height="400px"
        customBackgroundSize='auto'
      />
  <SliderHeroSection backgroundImage='#ffff'  title="Automation & AI" subtitle="Simplify your IT operations" 
  textColor='#242424' isDefault={true} featureTitle='Embrace Security Solutions for the Digital Era. Stay Ahead with Trivont.'
   featureSubtitle=" As the digital landscape evolves, prioritize security for sustained success. Embrace our proactive approach to safeguarding your assets and adapting to change " imageUrl=''>
    
  </SliderHeroSection>
        
      
   <MigrationJumbotron title='Tailored Security Solutions: Your Trusted IT Partner' description='Customized Security Services for Your Peace of Mind' />  
   
 
 
    {/* <Box data={securityFeatures} title=" Fortifying Cyber Defenses: Secure Solutions for Today's Threats"  boxHeight="210px" coltype='4'/> */}
   <IconBox items={securityFeatures} />

  

   


    <Footer data={footer} />
  
  </>
  )
}

export default CyberSecurity