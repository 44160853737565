import React, { useRef } from 'react'
import CustomSection from '../components/customComponents/customebanner'
import PricingTableComponent from '../components/customComponents/PricingCompoenet';
import Navbar from '../components/Navbar';


const Pricing
 = () => {
    const generalPurposeRef = useRef(null);
  const cpuOptimizedRef = useRef(null);
  const memoryOptimizedRef = useRef(null);
  const storageOptimizedRef = useRef(null);
  const customConfigRef = useRef(null);

  // Function to scroll into view
  const sectionRef = useRef(null);

  // Function to scroll into view within the section
  const scrollToComponent = (ref) => {
    if (sectionRef.current && ref.current) {
        const offsetTop = ref.current.offsetTop -700;
        console.log('OffsetTop:', offsetTop); // Log the offsetTop value
        sectionRef.current.scrollTo({
            top: offsetTop,
            behavior: 'smooth',
        });
    }
};
   
      
  return (
   <>
    <Navbar  headerTop='true'  />
    
    <CustomSection
  height="70vh"
  backgroundColor="#001484"
  color="#ffff"
  titleColor='#ffff'
  title="Maximize Cloud, Minimize Expenses."
  description="worldwide infrastructure at a significantly lower cost compared to major cloud service providers."
  buttonLink="#"
  buttonLabel="Contact Sales"  
  />
  <div className="Pricing__Pricecontainer">
      <div className="Pricing__sidebar-container">
        <button type="button" className="Pricing__btn-block" onClick={() => scrollToComponent(generalPurposeRef)}>
          General Purpose
        </button>
        <button type="button" className="Pricing__btn-block" onClick={() => scrollToComponent(cpuOptimizedRef)}>
          CPU-Optimized
        </button>
        <button type="button" className="Pricing__btn-block" onClick={() => scrollToComponent(memoryOptimizedRef)}>
          Memory-Optimized
        </button>
        <button type="button" className="Pricing__btn-block"onClick={() => scrollToComponent(storageOptimizedRef)}>
          Storage-Optimized
        </button>
        <button type="button" className="Pricing__btn-block" onClick={() => scrollToComponent(customConfigRef)}>
          Custom Configuration
        </button>
      
      </div>
      <div style={{width:'100%'}} className="comContainer">
        {/* Main Content Goes Here */}
      
      
        <section className="Pricing__content" ref={sectionRef}>
      {/* Render the corresponding PricingTableComponent based on the selectedType */}
      <PricingTableComponent
        ref={generalPurposeRef}
        title="General Purpose Pricing"
        description="."
        PriceTitle="General Purpose"
      />

      <PricingTableComponent
        ref={cpuOptimizedRef}
        title="CPU Optimized Pricing"
        description="Description for CPU-Optimized"
        PriceTitle="CPU-Optimized"
      />

      <PricingTableComponent
        ref={memoryOptimizedRef}
        title="Memory Optimized Pricing"
        description="Description for Memory-Optimized"
        PriceTitle="Memory-Optimized"
      />

      <PricingTableComponent
        ref={storageOptimizedRef}
        title="Storage Optimized Pricing"
        description="Description for Storage-Optimized"
        PriceTitle="Storage-Optimized"
      />

      <PricingTableComponent
        ref={customConfigRef}
        title="Custom Configuration Pricing"
        description="Description for Custom Configuration"
        PriceTitle="Custom Configuration"
      />
      
      </section>
      </div>
     
    </div>
      
   </>
  )
}

export default Pricing
