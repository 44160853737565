import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'
import Columns2s from '../components/TwoColumn';
import Box from '../components/customComponents/box';
import FourBlocks from '../components/blocksFour'
import './PagesCss.css'



function FtpesStorage() {

    const context = useContext(DataContext);
    const {Columnftpes,  footer, FtpesUseCases } = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const gpuFeatures = [
        {
          "title": "Enhanced Data Security",
          "subtitle": "Protect your sensitive data during transfers with robust encryption, ensuring privacy and integrity."
        },
        {
          "title": "OSecure File Exchanges",
          "subtitle":"Exchange files with partners, clients, and colleagues over encrypted connections for added security."
        },
        {
          "title": "User-Friendly FTPES",
          "subtitle": "Experience the simplicity and reliability of FTPES for seamless and efficient file transfers."
        },
        {
          "title": "Trusted Platform",
          "subtitle": "Create a trusted environment for your file transfers with enhanced security features."
        },
        {
          "title": "Advanced Storage Solutions",
          "subtitle": "Our Storage Solutions offer advanced features, robust security, and unparalleled reliability."
        },
        {
          "title": "Transformative Data Management",
          "subtitle": "Transform the way you store, manage, and share data with cutting-edge storage services."
        }
        
      ]


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}}  headerTop="True"  />
            <CustomSection header="Private FTPES Storage"
  content="Protect Your Files and Enhance Collaboration with Encrypted File Transfers"
  isRow={false}/>       
        


           <Columns2s data={Columnftpes}/>

           <Box data={gpuFeatures} title=" Features That Redefine Excellence" subtitle="Features" boxHeight="250px"/>

           <FourBlocks data={FtpesUseCases} blockHeight="220px"/>


           <BSsection4s
  heading="Ready to Secure Your File Transfers with FTPES?"
  title1="Start Your FTPES Journey"
  title2="Secure File Transfer Solutions"
  title3="Need Expert Guidance?"
  desc1="Explore Trivont's FTPES service to securely transfer files over FTP with enhanced TLS/SSL encryption. Begin your journey with our secure file transfer solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of experts is here to assist you in setting up and securing your FTPES connections or connecting you with the right partner to meet your specific file transfer requirements."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>



           
            <Footer data={footer} />
        </> 
    )
}

export default FtpesStorage
