import React from 'react';
import './customcomponent.css'
const CustomSection = ({ height, backgroundColor, defaultColor, titleColor, color, title, description, buttonLink, buttonLabel }) => {
  const sectionStyle = {
    height: height ? height : '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: backgroundColor ? backgroundColor : '#449efd',
    color: color ? color : '#fff',
  };

  const titleStyle = {
    color: titleColor ? titleColor : defaultColor ? defaultColor : '#242424',
  };

  return (
    <section style={sectionStyle} className="jumbotron text-center">
      <div className="container">
        <h1 className="display-4" style={titleStyle}>
          {title}
        </h1>
        <p className="lead">{description}</p>
        <a style={{  background: '#fff',
    padding: '16px',
    borderRadius: '5px',
    textDecoration: 'none', // Add this to remove underline by default
    color: '#000', }

        } href={buttonLink} role="button">
          {buttonLabel}
        </a>
      </div>
    </section>
    
  );
};

export default CustomSection;