import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';


import CustomFeature from '../components/customComponents/customBoxes';
import GetStartedFeture from '../components/customComponents/getStarted';
import Footer from '../components/Footer';
import { Container } from 'react-bootstrap';
import FAQAccordion from '../components/customComponents/faq';
import BSsection4s from '../components/BSsection4';
import Box from '../components/customComponents/box';
import vpn from '../components/banner/vpn.png';


const VirtualPrivate = () => {
  const context = useContext(DataContext);
  const {  footer } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  const featureData = {
    title: 'Step into a Secure and Isolated Network',
    desc: 'Discover the powerful features that set us apart.',
    items: [
        {
          "title": "Secure and Isolated Environments",
          "desc": "Step into a world of secure and isolated network environments with our Virtual Private Cloud (VPC) service."
        },
        {
          "title": "Tailored to Your Requirements",
          "desc": "Our VPCs are tailored to your specific requirements, providing a private, scalable, and customizable network space within the cloud."
        },
        {
          "title": "Seamless Resource Connection",
          "desc": "Seamlessly connect your resources within the VPC while ensuring data integrity and privacy."
        },
        {
          "title": "Private and Scalable Network Space",
          "desc": "Experience the freedom to create your virtual network architecture, allowing your business to thrive in a secure digital ecosystem."
        },
        {
          "title": "Customizable VPC Configurations",
          "desc": "Customize your VPC configurations to meet the unique needs of your business and applications."
        },
        {
          "title": "Data Integrity and Privacy",
          "desc": "Ensure data integrity and privacy with our VPC service, providing a secure space for your critical information."
        },
        {
          "title": "Business Thriving in a Secure Ecosystem",
          "desc": "Enable your business to thrive in a secure digital ecosystem created by our Virtual Private Cloud service."
        }
        // Add more features as needed
      ]
      ,
  };
 

const VpcUseCases =
 [
  {
    "title":"Enhanced Security",
     "subtitle":" Mitigate security risks and vulnerabilities with our secure VPC environment. Protect your sensitive data from external threats and unauthorized access, ensuring the confidentiality and integrity of your information."
  },
  {
    "title":"Scalable Infrastructure",
     "subtitle":"Scale your network infrastructure on-demand to meet the evolving needs of your business. Our VPC service offers elastic scalability, allowing you to add or remove resources as needed without disruption to your operations."
  },
  {
    "title":"Customizable Architecture",
     "subtitle":"Customize your network architecture to suit your specific requirements and preferences. With our VPC service, you have the freedom to design and deploy virtual networks, subnets, and security groups according to your business needs."
  },
  {
    "title":"Cost Optimization",
     "subtitle":"Optimize your IT spending with our cost-effective VPC service. Pay only for the resources you use, and avoid the overhead costs associated with maintaining physical infrastructure. Our service offers predictable pricing and flexible billing options to help you manage your budget effectively."
  }
  
  
  ]


  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  {/* <Banner2 data={ hero2Data}/> */}
 
  <CustomSection header="Virtual Private Cloud"
  subtitle=""
  content="Gain command over your privacy with a swift, dependable and user-friendly VPN setup in less than two minutes."
  isRow={true}
img={vpn}
  />
  

    <CustomFeature data={featureData} box1Height="250px"/>

    <Box data={VpcUseCases} title=" Features That Redefine Excellence" subtitle="Features" boxHeight="467px"/>

   
   {/* <GetStartedFeture/> */}
   <BSsection4s
  heading="Ready to Optimize Your Network with VPC?"
  title1="Start Your VPC Journey"
  title2="Secure and Scalable Networking Solutions"
  title3="Need Expert Guidance?"
  desc1="Discover the power of Trivont's Virtual Private Cloud (VPC). Begin your journey with our secure and scalable networking solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of network experts is here to help you design the perfect VPC architecture or connect you with the right partner to meet your networking requirements."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>

   <Footer data={footer}/>
  
  </>
  )
}

export default VirtualPrivate