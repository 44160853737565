import React from 'react';
import { Row, Col } from 'react-bootstrap';

const FourBoxes = ({ data }) => {
  return (
    <Row className="justify-content-around">
    {data.map((item, index) => (
      <div key={index} md={6} lg={3} className="mb-4 mx-lg-1 d-flex justify-content-center">
  
        <div className='custom_fourboxes' style={{  borderRadius: '15px', padding: '15px', textAlign: 'center' }}>
          <img src={item.imageUrl} alt={item.title} style={{ width: '100px', borderRadius: '15px 15px 0 0' }} />
          <h4 style={{ lineHeight: '25px' }}>{item.title}</h4>
          <p>{item.subtitle}</p>
        </div>
      </div>
    ))}
  </Row>
  
  );
};

export default FourBoxes;
