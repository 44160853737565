import React, {useState,useEffect} from "react";
import Loading from "./Loading";
import Title from "./Title";
import { Container, Row, Col } from "react-bootstrap";

function OSservices({ data, classText, styleTwo, styleThree, blockHeight }) {
  let publicUrl = process.env.PUBLIC_URL + "/";

   const [blockHeight1, setBlockHeight] = useState(blockHeight); 
   useEffect(() => {
     const updateBlockHeight = () => {
       if (window.innerWidth <= 767) { 
         setBlockHeight(parseInt(blockHeight) - 30 + 'px'); 
       } else {
         setBlockHeight(blockHeight); 
       }
     };
  
     updateBlockHeight();
     window.addEventListener('resize', updateBlockHeight);
  
     return () => {
       window.removeEventListener('resize', updateBlockHeight);
     };
   }, [blockHeight]); 

   

  if (!data) {
    return <Loading />;
  }

  return (
    <section className={`${classText} service`}>
      <Container>
        <Row>
          <Col>
          
            <Title
             subtitle={data.desc}
              title={data.title}
             
              classText={`${
                styleTwo
                  ? "title-shape title-shape-sky-blue text-center"
                  : styleThree
                  ? "title-shape title-shape-c2 title-shape-style-three text-center"
                  : "title-shape text-center"
              }`}
              
            />
          
          </Col>
        </Row>
        <Row className="justify-content-center">
          {data.items.map((item, index) => {
            let active = index === 1 ? "active" : "";
            return (
              <div key={index} className="col-lg-4 col-md-6">
                <div
                  className={`single-service ${
                    styleTwo
                      ? "style--two"
                      : styleThree
                      ? `style--three ${active}`
                      : ""
                  }`}
                  onMouseOver={(e) => {
                    // Change color of h3 and p to white on mouse over
                    e.currentTarget.querySelector('h3').style.color = 'black';
                    e.currentTarget.querySelectorAll('p').forEach(p => p.style.color = 'black');
                  }}
                  // onMouseOut={(e) => {
                  //   // Revert color of h3 and p when mouse leaves
                  //   e.currentTarget.querySelector('h3').style.color = ''; 
                  //   e.currentTarget.querySelector('p').style.color = ''; 
                  // }}
                  
                  style={{ height: blockHeight1 }}
                >
                  <div className="service-icon">
                    <img width="50"
                      src={publicUrl + item.img.url}
                      alt={publicUrl + item.img.alt}
                    />
                  </div>
                  <div className="service-content">
            
                      <h3>{item.title}</h3>
                   
                    <p>{item.desc}</p>
                    <p>{item.desc1}</p>
                    <p>{item.desc2}</p>
                   
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default OSservices;

OSservices.defaultProps = {
  classText: "pt-120 pb-90",
};