import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';

import FourBlocks from '../components/blocksFour';
import Box from '../components/customComponents/box';
import BSservices from '../components/BSservices';
import BSsection4s from '../components/BSsection4';



const LoadBalancer = () => {
  const context = useContext(DataContext);
  const {  footer, LBbenefits, LBusecase } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  
  
  const EnhancedList = [
    
    {
      title: 'Secure Connections ',
      link: 'Learn More',
      subtitle: 'Automatically manage SSL certificates at no cost using Let\'s Encrypt, and provide enhanced performance to your users with HTTP/3 support.'
    },
    {
      title: 'Effortless Setup Process',
      link: 'Learn More',
      subtitle: 'Instant provisioning and easy management via the Control Panel or API, taking only a few seconds for setup.'
    },
    {
      title: 'Support for Proxy Protocol',
      link: 'Learn More',
      subtitle: 'Facilitate the transfer of a client\'s IP address and port directly to your VPC.'
    },
    {
      title: 'Kubernetes Compatibility',
      link: 'Learn More',
      subtitle: 'Seamlessly integrate Load Balancers into your Kubernetes cluster.'
    },
    {
      title: 'Adaptable Scaling Options',
      link: 'Learn More',
      subtitle: 'Enjoy increased flexibility by adjusting your Load Balancer as needed, allowing your applications to expand effortlessly.'
    },
    {
        title: 'Enhanced Scalability',
        link: 'Learn More',
        subtitle: 'Load Balancers undergo continuous monitoring for both availability and irregularities. Any identified issues are automatically resolved to ensure the smooth operation of your applications.'
      }
  ];
  
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  {/* <Banner2 data={ hero2Data}/> */}
  <CustomSection header="Load Balancer"
  subtitle=""
  content="Explore how our Load Balancers optimize application performance by distributing traffic across multiple servers, ensuring reliability, and maximizing uptime."
  isRow={false}/>
  
   <Box data={EnhancedList} title="Optimize the performance and reliability of your applications with our Load Balancers" subtitle= 'Features' boxHeight="330px"/>
   
   <FourBlocks data={LBbenefits} blockHeight="200px"/>

   <BSservices data={LBusecase} blockHeight="320px"/>


   <BSsection4s
  heading="Ready to Optimize Your Application Performance with Load Balancers?"
  title1="Start Your Load Balancer Journey"
  title2="Efficient Traffic Distribution"
  title3="Need Expert Guidance?"
  desc1="Enhance your application's reliability with Trivont's load balancer services. Begin your journey with our efficient traffic distribution solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of load balancing experts is here to help you implement and fine-tune load balancers or connect you with the right partner to optimize your application performance."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>


   <Footer data={footer}/>
   
   
  
  </>
  )
}

export default LoadBalancer