import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';

import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';
import BSservices from '../components/BSservices';
import TabsComponent from '../components/sideNavigation';
import '../pages/PagesCss.css'        


const GPUcomputeCluster = () => {
  const context = useContext(DataContext);
  const {  footer, GPUCservice } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  

 

  const tabsData = [
        { id: 'link1', label: 'Genomic Sequencing', content: 'Accelerate genomic sequencing and analysis with GPU Compute Clusters, enabling faster identification of genetic mutations and disease markers.' },
        { id: 'link2', label: 'Drug Discovery', content: 'Expedite drug discovery and development processes with GPU Compute Clusters, facilitating virtual screening and molecular modeling for pharmaceutical research.' },
        { id: 'link3', label: 'Financial Modeling', content: 'D Speed up financial modeling and risk analysis with GPU Compute Clusters, enabling real-time market simulations and portfolio optimization.' },
        { id: 'link4', label: 'Image and Video Processing', content: 'Enhance image and video processing workflows with GPU Compute Clusters, enabling faster rendering, encoding, and analysis for media and entertainment industries.'},
        { id: 'link5', label: 'Autonomous Vehicles', content: 'Power autonomous vehicle development and testing with GPU Compute Clusters, enabling real-time sensor fusion, perception, and decision-making algorithms.'},
        { id: 'link6', label: 'Medical Imaging and Healthcare Analytics', content: 'Improve medical imaging and healthcare analytics with GPU Compute Clusters, facilitating faster image reconstruction, medical image analysis, and predictive modeling for disease diagnosis and treatment planning. GPU-accelerated computing enables healthcare professionals to leverage advanced algorithms and machine learning techniques to improve patient outcomes and healthcare delivery.'},
        { id: 'link7', label: 'Retail Analytics and Personalization', content: 'Transform retail analytics and personalized marketing efforts with GPU Compute Clusters by analyzing customer data, segmenting target audiences, and delivering personalized recommendations and promotions in real-time. Accelerated computing enables retailers to gain deeper insights into consumer behavior, optimize inventory management, and enhance customer engagement across various channels.'},
        { id: 'link8', label: 'Autonomous Robotics and Drone Navigation', content: 'Empower autonomous robotics and drone navigation systems with GPU Compute Clusters by processing sensor data, performing real-time object detection and recognition, and enabling autonomous decision-making. GPU-accelerated computing enables robots and drones to navigate complex environments, avoid obstacles, and execute tasks with precision and efficiency.'},
        { id: 'link9', label: 'Virtual Reality and Augmented Reality:', content: 'Enhance virtual reality (VR) and augmented reality (AR) experiences with GPU Compute Clusters by rendering high-resolution graphics, simulating realistic environments, and delivering immersive content in real-time. GPU-accelerated computing enables developers and content creators to push the boundaries of immersive storytelling, gaming, and simulation applications.'}

    ];

  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
  {/* <Banner2 data={ hero2Data}/> */}
  <CustomSection header="GPU Compute Cluster "
  subtitle=""
  content="Unleash the Power of Collective Computing Transforming Ideas into Reality ."
  isRow={false}
  />

   <BSservices data={GPUCservice} blockHeight='500px'/>
   
 





<TabsComponent
        title="Use Cases"
        subtitle="Gpu Compute cluster"
        defaultActiveKey="link1"
        tabs={tabsData}
      />



<BSsection4s
  heading="Ready to Optimize Your Computing Power with GPU Compute Clusters?"
  title1="Start Your GPU Compute Cluster Journey"
  title2="Advanced Parallel Computing Solutions"
  title3="Need Expert Guidance?"
  desc1="Harness the potential of Trivont's GPU compute clusters. Begin your journey with our advanced parallel computing solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of computing experts is here to help you deploy and optimize GPU compute clusters or connect you with the right partner to meet your specific computational requirements."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>

   
   <Footer data={footer}/>
  
  </>
  )
}

export default GPUcomputeCluster