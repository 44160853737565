import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Title from '../components/Title';

const BSsection4s = ({ heading, subHeading, title1, title2, title3, desc1, desc2, buttonText1, buttonText2 }) => {
  return (
    <section style={{ marginBottom: '20px', marginTop:'20px', marginBottom:'50px'}}>
    <Container >
      <Row>
        <Col >
          <Title title={heading} subtitle={subHeading} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} className="mb-4 mb-md-0">
          {/* Content for Column 1 */}
          <div className="column-content">
            <h1>{title1}</h1>
           
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-4 mb-md-0">
          {/* Content for Column 2 */}
          <div className="column-content">
            <h3>{title2}</h3>
            <p>{desc1}</p>
            <a href='/contactus' className="normal-button">{buttonText1}</a>
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-4 mb-md-0">
          {/* Content for Column 3 */}
          <div className="column-content">
            <h3>{title3}</h3>
            <p>{desc2}</p>
          
          </div>
        </Col>
      </Row>
    </Container>
    </section>
  );
};

export default BSsection4s;
