import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';

import Box from '../components/customComponents/box';
import CustomFeature from '../components/customComponents/customBoxes';



const CloudDR = () => {
  const context = useContext(DataContext);
  const {  footer } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  
  
 
  const CloudMigrationCards = [
        {
            title: 'Natural Disaster Preparedness',
            subtitle: ' Prepare for natural disasters, such as hurricanes, earthquakes, and floods, by implementing cloud-based disaster recovery solutions that replicate data and applications to geographically dispersed regions, ensuring resilience and availability during regional disruptions.',
          },
          {
            title: 'Cybersecurity Incident Response',
            subtitle: 'Respond to cybersecurity incidents, data breaches, and ransomware attacks with confidence, leveraging cloud-based backup and recovery capabilities to restore systems and data to a pre-compromised state, mitigate data loss, and maintain business continuity.',
          },
          {
            title: 'Infrastructure Failover and Outage Recovery',
            subtitle: ' Recover quickly from infrastructure failures, hardware malfunctions, and network outages by orchestrating failover processes that redirect traffic and workloads to redundant cloud environments, minimizing service disruptions and ensuring high availability of critical services.',
          },
          {
            title: 'Application Resilience and High Availability',
            subtitle: 'Ensure continuous availability of mission-critical applications and services by deploying redundant, highly available architectures in the cloud, with failover mechanisms that seamlessly transition workloads between active and standby instances to maintain service levels and user experiences.',
          },
        // Add more features as needed
      ]
  

  
  
  const featureData = {
    title: 'Services',
    desc: '',
    items: [
    {
      title: "Data Replication and Backup",
      desc: "Protect your data with robust replication and backup solutions that store copies of critical data and applications in secure offsite locations. Utilize automated backup schedules, versioning, and encryption to safeguard against data loss and corruption, enabling rapid recovery in the event of a disaster."  },
    {
      title: "Continuous Data Protection (CDP)",
      desc: "Implement continuous data protection mechanisms that capture and replicate changes to data in real-time, ensuring minimal data loss and maximum recovery point objectives (RPOs) for mission-critical systems and applications."   },
    {
      title: "Failover and Failback Automation",
      desc:"Streamline disaster recovery operations with failover and failback automation capabilities that orchestrate the seamless transition of workloads between primary and secondary data centers or cloud environments. Minimize downtime, reduce manual intervention, and maintain business continuity during disaster scenarios."    },
    {
      title: "Disaster Recovery as a Service (DRaaS)",
      desc: "Leverage our DRaaS offerings to offload disaster recovery responsibilities to our expert team of professionals. Benefit from fully managed disaster recovery solutions that provide comprehensive planning, testing, and execution of recovery strategies, allowing you to focus on core business activities with confidence."  },
      {
        title: "Multi-Cloud Disaster Recovery",
        desc: " Ensure redundancy and resilience with multi-cloud disaster recovery architectures that replicate data and workloads across multiple cloud providers and regions. Mitigate risks associated with cloud outages, data center failures, and regional disasters, achieving greater fault tolerance and disaster recovery readiness."  }
    ],
}

  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
 
  <CustomSection header="Cloud Disaster Recovery"
  subtitle=""
  content="Explore how our cloud-based disaster recovery services empower businesses to mitigate risks, safeguard critical data and applications, and ensure uninterrupted operations in the face of unforeseen disruptions."
  />
  
  <Box data={CloudMigrationCards} title="Use Cases"  boxHeight="515px"/>

   <CustomFeature data={featureData} box1Height="410px"/>
  


   <BSsection4s
  heading="Ensure Business Continuity with Cloud Disaster Recovery"
  title1="Secure Your Data Recovery"
  title2="Minimize Downtime"
  title3="Need Expert Guidance?"
  desc1="Protect your business with our Cloud Disaster Recovery solutions. Whether you're safeguarding critical data or preparing for unforeseen disruptions, start your journey with our robust solutions and enjoy a complimentary $300 credit."
  desc2="Our team of disaster recovery experts is dedicated to helping you implement the perfect Cloud Disaster Recovery plan or connecting you with the right partners to ensure seamless business continuity."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>


   <Footer data={footer}/>
   
  
  </>
  )
}

export default CloudDR