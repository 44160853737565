import React, { useState,useEffect } from 'react';

const AlertTwo = ({ type, message, onClose }) => {
  const [closed, setClosed] = useState(false);
  

  useEffect(() => {
    // Automatically close the alert after 5 seconds
    const timeoutId = setTimeout(() => {
      setClosed(true);
      onClose();
    }, 2000);

    // Clear the timeout when the component is unmounted or closed manually
    return () => clearTimeout(timeoutId);
  }, [onClose]);
  const getAlertClass = () => {
    switch (type) {
      case 'success':
        return 'alert alert-success';
      case 'danger':
        return 'alert alert-danger';
      case 'warning':
        return 'alert alert-warning';
      default:
        return 'alert alert-primary';
    }
  };

  return (
    <div className={`${getAlertClass()} ${closed ? 'd-none' : ''}`} role="alert">
      {message}
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={() => {
          setClosed(true);
          onClose();
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default AlertTwo;
