import React, { useContext } from 'react'
import { DataContext } from '../context'
import OSservices from '../components/OSservice';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import kubernates from '../components/banner/kubernates.png'

import './PagesCss.css'

function Kubernates() {

    const context = useContext(DataContext);
    const { KubernetesService,footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="Kubernetes Engine"
  
            content="Discover how our Kubernetes solutions empower businesses to deploy, scale, and manage containerized applications seamlessly, driving agility, reliability, and innovation in the cloud-native ecosystem." 
            img={kubernates}
            isRow={true}/>   

          <OSservices data={KubernetesService} blockHeight="540px"/>
        


        <Footer data={footer} />
        </>
    )
    }

export default Kubernates