import React from 'react';
import './customcomponent.css'
const BannerSection = ({ title, backgroundImage, color, height, customBackgroundSize, customBackgroundPosition, backgroundColor }) => {
  const sectionStyle = {
    width: '100%',
    height: height,
    backgroundImage: `url(${backgroundImage})`,
    color: color,
    backgroundSize: customBackgroundSize ? customBackgroundSize : 'cover',
    backgroundPosition: customBackgroundPosition ? customBackgroundPosition : 'center center',
    backgroundColor: backgroundColor // Add the backgroundColor property here
  };

  return (
    <section className="d-flex justify-content-center align-items-center BannerCloudServices" style={sectionStyle}>
      <div className="text-center">
        <h1 className='' style={{ color: color }}>{title}</h1>
      </div>
    </section>
  );
};

export default BannerSection;
