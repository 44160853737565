import React,  { useState, useContext, useEffect }  from 'react';
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import BannerSectionCareer from '../components/customComponents/bannerSectionCareer'
import CyberS from '../images/BANNERS/careerImage.jpg'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {FaPaperPlane } from 'react-icons/fa';

import './PagesCss.css'


const Career = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData
  const [OpeningsData, setOpeningsData] = useState([])

  const { handleSearch, handleOffcanvas } = context;

  const [selectedData, setSelectedData] = useState(null);
  const [editForm, setEditForm] = useState(false);
  const history = useHistory();

//   const careercontainerStyle = {
//     display: 'flex',
//     flexWrap: 'wrap',
//     marginLeft:'150px',
//     marginRight:'150px',
//     marginTop:'50px',
//      marginBottom:'50px',
//      justifyContent:'center'

//   };

//   const careerblockStyle = {
//     backgroundColor: 'white',
//     width: 'calc(25% - 20px)', // fixed width for 4 items per row minus margin
//     height: 'auto', // adjust height to fit content
//     margin: '10px', // spacing between blocks
//     padding: '20px', // inner padding
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     alignItems: 'flex-start',
//     color: 'black', // text color
//     boxSizing: 'border-box', // include padding and border in width and height
//     borderRadius: '10px', // curved edges
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // box shadow
//     border: '1px solid #e0e0e0',

//   };

// const careerbuttonStyle = {
//     marginTop: '20px',
//     padding: '10px 20px',
//     border: '1px solid black',
//     borderRadius: '5px',
//     backgroundColor: 'white',
//     color: 'black',
//     cursor: 'pointer',
//     textAlign: 'center',
//   };

  const handelClick = (id) => {
    history.push({
      pathname: '/careerReg',
      state : {jobId : id},
    })
  }

  useEffect(() => {
    fetchData();
  }, []);

const fetchData= () => {

    axios.get('https://trivont.com/api/openings/view/')
      .then(response => setOpeningsData(response.data))
      .catch(error => console.log(error));


  };

  const handleView = (partner) => {
    setEditForm(true)
    setSelectedData(partner)
  }

  const handelBack =() =>{
    setEditForm(false)
  }

  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />

 { !editForm && (
  <>
  <BannerSectionCareer title="Current Job Openings" backgroundColor="#007bff" backgroundImage={CyberS} color="#fff" height="600px" customBackgroundSize='auto'/>

  <div className="career-container">
  {/* {OpeningsData.filter(partner => partner.is_Active === true).length > 0 ? ( */}
  {OpeningsData.length > 0 ? (
        // OpeningsData.filter(partner => partner.is_Active === true ).map((partner, index) => (
    OpeningsData.filter(partner => partner.is_Visible === true).map((partner, index) => (
       <div key={index} className="career-block">
       <h3 style={{ margin: '0 0 10px 0' }}>{partner.domain}</h3>
       <h4 style={{ margin: '0 0 10px 0', fontWeight: 'bold' }}>{partner.title.split('-')[0].trim()}</h4>
       <p style={{ margin: '0 0 10px 0' }}>Experience: {partner.experience}</p>
       <p style={{ margin: '0 0 20px 0' }}>Location: {partner.location}</p>
       <div style={{ width: '100%', textAlign: 'center' }}>
         <button className="career-button" onClick={() => handleView(partner)}>Apply &nbsp; <FaPaperPlane style={{color:'green'}}/></button>
       </div>
     </div>
    ))
  ) : (
    <p>No new openings available</p>
  )}
  </div>
  </>
 )}

{ editForm && (
  <>
  <BannerSectionCareer title="Job Description" backgroundColor="#007bff" backgroundImage={CyberS} color="#fff" height="600px" customBackgroundSize='auto'/>



       <>

        <div className='row' style={{display:'flex', justifyContent:'right', marginRight:'30px'}}>
       <buttton className="career-button" onClick={handelBack}>All Openings</buttton>
       </div>

       <div className="career-details">
       <h2 style={{ margin: '0 0 20px 0' }}>{selectedData.title}</h2>
       <div >
       <p style={{ margin: '0 0 15px 0'}}><strong style={{color:'black'}}>Skill Set :</strong> {selectedData.skills}</p>
       </div>
       <div className='row' style={{display:'flex', justifyContent:'space-between', marginLeft:'0px'}}>

       <div >
       <p style={{ margin: '0 0 15px 0' }}><strong style={{color:'black'}}>Total Experience :</strong> {selectedData.experience}</p>

       <p style={{ margin: '0 0 15px 0'}}><strong style={{color:'black'}}>Job Postal Date :</strong> {new Date(selectedData.date_created).toLocaleDateString()}</p>
       {/* <p style={{ margin: '0 0 10px 0' }}><strong>Job Expiry Date :</strong> {selectedData.last_date}</p> */}


       </div>
       <div>
       <p style={{ margin: '0 0 15px 0' }}><strong style={{color:'black'}}>No of Openings :</strong> {selectedData.number_of_openings}</p>
       <p style={{ margin: '0 0 15px 0' }}><strong style={{color:'black'}}>Domain :</strong> {selectedData.domain}</p>

       </div>

       <div >
       <p style={{ margin: '0 0 15px 0'}}><strong style={{color:'black'}}>Location : </strong>{selectedData.location}</p>

       </div>

       </div>

       <div style={{ width: '100%', textAlign: 'left' }} className="career-button-container">
         <button  className="career-button" onClick={() => handelClick(selectedData.id)}>Apply Now</button>
       </div>
      <br/>

      <div className='row' style={{border:'1px solid rgba(0, 0, 0, 0.1)'}}>

      </div>
      <br/>
       <div>
        <h3>Job Summery </h3><br/>
        <p>{selectedData.summery}</p>
       </div>

       </div>
      </>




  </>
 )}









    <Footer data={footer} />

  </>
  )
}

export default Career
