import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';


import CustomFeature from '../components/customComponents/customBoxes';
import GetStartedFeture from '../components/customComponents/getStarted';
import Footer from '../components/Footer';
import { Container } from 'react-bootstrap';
import BSsection4s from '../components/BSsection4';
import BSservices from '../components/BSservices';
import Opcompute from '../components/banner/optimized.png'

const OptimizedVirtualMachine = () => {
  const context = useContext(DataContext);
  const {  footer,CVMservice } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  const featureData = {
    title: 'Explore Our Features',
    desc: 'Discover the powerful features that set us apart.',
    items: [
        {
            title: "Up to 10 Gbps Outbound Data Speeds",
            desc: "Experience a significant boost in outbound network speeds – five times faster than their Regular counterparts – with the introduction of the new Premium CPU-Optimized Droplets."
          },
          {
            title: "Reliable and Enduring Performance",
            desc: "Harness the power of the latest Intel® Xeon® CPU generations with Premium CPU-Optimized Virtual Machine, ensuring unwavering application performance and reliability."
          },
         
          {
            title: "Dedicated CPU for Consistent Performance",
            desc: "Unlike shared CPU Droplets, dedicated CPU Droplets guarantee uninterrupted access to the full hyperthread, ensuring a consistently high level of performance."
          }
      // Add more feature items as needed
    ],
  };
 
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  {/* <Banner2 data={ hero2Data}/> */}
  <CustomSection header="Optimized Virtual Machine"
  subtitle=""
  content="Sculpting Efficiency: Tailored VMs for Peak Performance. An unrivaled alternative to EC2, redefining your cloud experience."
  img={Opcompute}
  isRow={true}
  />
  
    <CustomFeature data={featureData} box1Height="320px"/>
   
    <BSservices data={CVMservice} blockHeight="300px"/>
    
    <BSsection4s
  heading="Ready to Optimize Your Virtual Infrastructure?"
  title1="Start Your Virtual Machine Journey"
  title2="High-Performance Virtual Machines"
  title3="Need Expert Assistance?"
  desc1="Experience the power of Trivont's optimized virtual machines. Begin your journey with our high-performance VMs and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of virtualization experts is here to help you configure the perfect virtual environment or connect you with the right partner to meet your computing needs."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>

   
   <Footer data={footer}/>
  
  </>
  )
}

export default OptimizedVirtualMachine